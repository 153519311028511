import { toCurrency } from '../Lib/Constants';
import styles from '../Styles/display.module.css';


const Display = ({ label, value, prefix, suffix, number }) => {

    console.log('Display render: ', value);

    return (
        <div className={styles.displayMainContainer}>
            <div className={styles.displayHeader}>
                <div className={styles.displayHeaderBorderBefore}></div>
                {label && 
                    <div className={styles.displayHeaderLabel}>
                        <span className={styles.displayLabel}>{label}</span>
                    </div>
                }
                <div className={styles.displayHeaderBorderAfter}></div>
            </div>
            <div className={number ? styles.displayChildrenContainerRight : styles.displayChildrenContainer}>
                {!!prefix && (prefix + ' ') }
                {number ? toCurrency(value) : value}
                {!!suffix && ('  ' + suffix)}
            </div>
        </div>
    );
}

export default Display;