import { Box, Button, Typography } from '@mui/material';

const KConfirm = (p) => {

    console.log('KConfirm render');

    
    return (
        <Box marginTop={2}>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>{p.title}</Typography>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>emin misiniz?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >

                <Button onClick={() => {p.handleClose(); p.process();}} variant="contained" >EVET</Button>
                <Button variant='contained' onClick={p.handleClose} >HAYIR</Button>
                
            </Box>
        </Box>
    );
};

export default KConfirm;