import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import AmountInput from '../Components/AmountInput';
import StringInput from '../Components/StringInput';
import { maxId, stripSeperators } from '../Lib/Constants';

const regex = /^(0|[1-9][0-9]{0,2})$/;

const CaseCollateralForm = (p) => {

    console.log('CaseLimitForm render ', p.seqNo);

    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);

    let ref0IsValid = useRef(!!p.data);     // collateral
    let ref1IsValid = useRef(!!p.data);     // score
    let ref0Value = useRef(p.data && p.data.collateral);
    let ref1Value = useRef(p.data && p.data.score);
    const handleInputChange = (x, i, v) => {
        if (x === 0) {ref0IsValid.current = i; ref0Value.current = v;}
        else if (x === 1) {ref1IsValid.current = i; ref1Value.current = v;}
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current );
    }; 

    const process = (e) => {
        e.preventDefault();
        const data = {
            id: p.data ? p.data.id : maxId(p.values),
            collateral: ref0Value.current,
            score: Number(stripSeperators(ref1Value.current)),
            isDefault: p.data ? p.data.isDefault : p.seqNo === 0 
        };
        p.process(data, p.setValues, p.values, p.seqNo);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 0 }}>
            <Box>
                <StringInput label='Teminat' id='collateral'  value={ref0Value.current} onChange={(i, v) => handleInputChange(0, i, v)} autoFocus multiline rows={3}/>
                <AmountInput label='Puan' id='score' value={ref1Value.current} onChange={(i, v) => handleInputChange(1, i, v)} suffix='puan' regex={regex} errorMsg='0-999 arası bir rakam girmelisiniz' />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    );
};

export default CaseCollateralForm;