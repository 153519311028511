import { Grid, Typography } from "@mui/material";


const Welcome = () => {

    console.log('Welcome render');

    return (
        <Grid component="main" maxWidth='md' justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' sx={{ color: '#53a57d', fontWeight: 700 }}>HOŞGELDİNİZ</Typography>
        </Grid>
    );
};

export default Welcome;