import { Box, Backdrop, Fade, Modal, Typography } from '@mui/material';


const KModal = (p) => {

    console.log('KModal render: ', p.title);

    const style = {
        position: 'absolute',
        top: p.top || '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: p.width || '400px',
        maxWidth: '100%',
        maxHeight: p.maxHeight || 'none',
        bgcolor: 'background.paper',
        border: '2px solid #53a57d',
        overflow: 'auto',        
        boxShadow: 24,
        p: 2
    };

    return (
        <Modal
            aria-labelledby={p.title}
            aria-describedby={p.title}
            open={p.open}
            onClose={p.handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}>
            <Fade in={p.open}>
                <Box sx={style}>
                    <Typography component='h5' variant='h5' sx={{ color: '#53a57d', fontWeight: 700 }}>{p.title}</Typography>

                    {p.children}

                </Box>
            </Fade>
        </Modal>
    );
};

export default KModal;