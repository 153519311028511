import { Box, Typography } from "@mui/material";
import KTable from "../Components/KTable";
import { maturityTypeToString, toCurrency } from "../Lib/Constants";

const GamePlayerCaseFollowup = (p) => {

    console.log('GamePlayerCaseFollowup render: ', p);
    const headers = [
        {
            id: 'orderNo',
            align: 'center',
            label: 'SıraNo',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'teamId',
            align: 'center',
            label: 'Komite',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'fullName',
            align: 'left',
            label: 'Adı',
            tooltip: 'userId',
            sortable: true,
            disablePadding: false
        },
        {
            id: 'decision',
            flag: 'result',
            align: 'left',
            label: 'Cevap',
            disablePadding: false,
            sortable: true,
            convert: (d, r) => r === 0 ? 'Henüz Cevaplamadı' : d ? 'Onayladı' : 'Reddetti'
        },
        {
            id: 'result',
            align: 'left',
            label: 'Sonuç',
            disablePadding: false,
            sortable: true,
            convert: (d) => d === 0 ? 'Henüz Cevaplamadı' : d === 1 ? 'Kullandırdı' : d === 2 ? 'Kullandıramadı' : d === 3 ? 'Reddetti' : '-'
        },
        {
            id: 'limit',
            align: 'right',
            label: 'Limit',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'maturity',
            flag: 'maturityType',
            align: 'right',
            label: 'Vade',
            disablePadding: true,
            sortable: true,
            convert: (d, t) => toCurrency(d) + maturityTypeToString(t)
        },
        {
            id: 'collateral',
            title: 'collateral',
            align: 'left',
            label: 'Teminat',
            disablePadding: false,
            sortable: true,
            convert: (d) => !d ? '' : d.length <= 24 ? d.replaceAll('\\n' , '') : (d.replaceAll('\\n' , ' ').slice(0, 24) + '..')
        }
    ];

    return (
        <Box sx={{ mt: 0 }}>
            <Typography variant='span' sx={{ fontSize: '24px', color: 'black', fontWeight: 700 }} >{p.title}</Typography>
            <Box marginRight='10px'>
            <KTable headers={headers} data={p.userData} rowKey='fullName' marginTop={0} initialRowsPerPage={40} noSearch noPagination isDense />
            <KTable headers={headers} data={p.teamData} rowKey='fullName' marginTop={0} initialRowsPerPage={40} noSearch noPagination isDense />
            </Box>
        </Box>
    );
};

export default GamePlayerCaseFollowup;