import { Box, Button, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

const AnswerFormApproveConfirm = (p) => {

    console.log('AnswerFormApproveConfirm render');

    
    return (
        <Box marginTop={2}>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>ONAYLAMAK istediğinize</Typography>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>emin misiniz?</Typography>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Limit:</TableCell>
                        <TableCell>{p.limit}</TableCell>
                    </TableRow>
                    
                    <TableRow>
                        <TableCell>Vade:</TableCell>
                        <TableCell>{p.maturity}</TableCell>
                    </TableRow>
                    
                    <TableRow>
                        <TableCell>Teminat:</TableCell>
                        <TableCell sx={{ whiteSpace: 'preserve-breaks' }}>{p.collateral}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button onClick={p.process} variant="contained" >ONAY</Button>
                <Button variant="contained" onClick={p.handleClose}>İPTAL</Button>
            </Box>
        </Box>
    );
};

export default AnswerFormApproveConfirm;