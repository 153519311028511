import { useRef, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { DragDropContext } from 'react-beautiful-dnd';
import UserSelectionFormFullList from "./UserSelectionFormFullList";
import UserSelectionFormSelectedList from "./UserSelectionFormSelectedList";


const copyUsers = (users) => {
    if (!users || users.length === 0 || !users.some(t => t.length > 0))
        return [[]];
    const temp = [];
    for(let i = 0; i < users.length; i++) {
        const t = []; temp.push(t);
        for(let j = 0; j < users[i].length; j++) t.push({...users[i][j]});
    }
    return temp;
}    
const findTeamStartingIndex = (users, teamId) => {
    let rtn = 0;
    for(let i = 0; i < teamId; i++) rtn += users[i].length;
    return rtn;
}

const UserSelectionForm = (p) => {

    console.log('UserSelectionForm render');

    const [ submitEnabled, setSubmitEnabled ] = useState(false);
    const [ selectedTeamId, setSelectedTeamId ] = useState(0);
    const [ userList, setUserList ] = useState(copyUsers(p.userList));
    let filtered = useRef(p.data?.fullUserList ?? []);



    const process = () => {
        const temp = [];
        for(let i = 0; i < userList.length; i++){
            const t = []; temp.push(t);
            for(let j = 0; j < userList[i].length; j++) {
                const u = {...userList[i][j]};
                u.is = (j === 0);
                t.push(u);
            }
        }
        p.setUserList(temp);
        p.handleClose();
    };

    const moveUser = (v) => {
        v.tid = selectedTeamId;
        userList[selectedTeamId].push(v);
        const temp = [...userList];
        set(temp);
    };

    const removeUser = (v) => set(v);

    const addTeam = () => {
        const t = [...userList]; 
        t.push([]);
        setUserList(t);
        setSelectedTeamId(t.length-1);
        setSubmitEnabled(true);
    }

    const removeTeam = () => {
        const t = [...userList]; 
        t.splice(selectedTeamId, 1);
        setUserList(t);
        if (selectedTeamId >= t.length) setSelectedTeamId(t.length-1);
        setSubmitEnabled(true);
    }

    const set = (finalList, flag) => { 
        for(let i = 0; i < finalList.length; i++)
            for(let j = 0; j < finalList[i].length; j++)
                finalList[i][j].is = (j === 0);
        setUserList(finalList); 
        if (!flag) setSubmitEnabled(true);
    }

    const onDragEnd = (result) => {        
        if (!result.destination) { set([...userList], false); return; }
        if (result.type === 'team' && result.source.index !== result.destination.index)
            moveDraggedTeam(result.source.index, result.destination.index);
        else if (result.type === 'user' && result.source.droppableId === 'newUser' && result.destination.droppableId.startsWith('user-'))
            addDraggedUser(result.source.index, result.destination.index, result.destination.droppableId)
        else if (result.type === 'user' && result.source.droppableId.startsWith('user-') && result.destination.droppableId.startsWith('user-'))
            moveDraggedUser(result.source.index, result.source.droppableId, result.destination.index, result.destination.droppableId)
        else if (result.type === 'user' && result.destination.droppableId === 'newUser' && result.source.droppableId.startsWith('user-'))
            removeDraggedUser(result.source.index, result.source.droppableId)
        else
            set([...userList], false);

    }   

    const moveDraggedTeam = (si, di) => {
        const t = [...userList];
        const [item] = t.splice(si, 1);
        t.splice(di, 0, item);
        set(t);
    }

    const addDraggedUser = (si, di, did) => {        
        const item = filtered.current[si];
        const teamId = Number(did.slice(5));
        const t = [...userList];
        const team = t[teamId];
        const tsi = findTeamStartingIndex(t, teamId);
        item.tid = teamId;
        team.splice(di - tsi, 0, item);
        set(t);
    }
    
    const moveDraggedUser = (si, sid, di, did) => {        
        const t = [...userList];
        let teamId = Number(sid.slice(5));
        let team = t[teamId];
        let tsi = findTeamStartingIndex(t, teamId);
        const [item] = team.splice(si - tsi, 1);
        teamId = Number(did.slice(5)); 
        team = t[teamId];
        tsi = findTeamStartingIndex(t, teamId);
        team.splice(di - tsi, 0, item);
        set(t);        
    }

    const removeDraggedUser = (si, sid  ) => {     
        const teamId = Number(sid.slice(5));
        const t = [...userList];
        const team = t[teamId];
        const tsi = findTeamStartingIndex(t, teamId);
        team.splice(si - tsi, 1);
        set(t);
    }

    return (
        <Box sx={{ mt: 0 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing='4' marginTop='0'>
                    <Grid item xs={6} paddingRight={2}>
                        <UserSelectionFormFullList 
                            companyId={p.data?.companyId} 
                            searchWord={p.data?.searchWord}
                            companyList={p.data?.companyList}
                            fullUserList={p.data?.fullUserList} 
                            selectedUserList={userList}
                            setCompanyId={p.data?.setCompanyId}
                            setSearchWord={p.data?.setSearchWord} 
                            setFiltered={(f) => filtered.current = f}
                            move={moveUser} />
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={6}>
                        <UserSelectionFormSelectedList
                            selectedTeamId={selectedTeamId}
                            selectedUserList={userList}
                            setSelectedTeamId={setSelectedTeamId}
                            setSelectedUserList={setUserList}
                            removeUser={removeUser}
                            addTeam={addTeam}
                            removeTeam={removeTeam}
                        />
                    </Grid>
                </Grid>
            </DragDropContext>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button onClick={process} variant="contained" disabled={!submitEnabled} >SAKLA</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    );
}

export default UserSelectionForm;