import { useRef, useState } from 'react';
import { Box, Button, TextField } from '@mui/material';
import AmountInput from '../Components/AmountInput';
import DualSwitch from '../Components/DualSwitch';
import PercentageInput from '../Components/PercentageInput';
import StringInput from '../Components/StringInput';
import { stripSeperators, toCurrency } from '../Lib/Constants'

const ratedValue = (v, r) => toCurrency(Math.round((Number(stripSeperators(v)) / 100 * Number(stripSeperators(r)) )));
const ratedIntValue = (v, r) => Math.round((Number(stripSeperators(v)) / 100 * Number(stripSeperators(r)) ));

const BankForm = (p) => {

    console.log('BankForm render: ', p.data);

    const [ isActive, setIsActive ] = useState(!p.data || p.data.isActive);
    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);
    const [ targetNpl, setTargetNpl ] = useState((p.data ? ratedValue(p.data.targetPlasman, p.data.targetNplRate) : '') + ' TL');

    
    let ref0IsValid = useRef(!!p.data);     // title
    let ref1IsValid = useRef(!!p.data);     // desc
    let ref2IsValid = useRef(!!p.data);     // targetPlasman
    let ref3IsValid = useRef(!!p.data);     // targetProfit
    let ref4IsValid = useRef(!!p.data);     // targetNplRate
    let ref0Value = useRef(p.data && p.data.title);
    let ref1Value = useRef(p.data && p.data.desc);
    let ref2Value = useRef(p.data ? p.data.targetPlasman : '');
    let ref3Value = useRef(p.data ? p.data.targetProfit : '');
    let ref4Value = useRef(p.data ? p.data.targetNplRate : '');

    const handleInputChange = (x, i, v) => {
        if (x === 0) {ref0IsValid.current = i; ref0Value.current = v;}
        else if (x === 1) {ref1IsValid.current = i; ref1Value.current = v;}
        else if (x === 2) {ref2IsValid.current = i; ref2Value.current = v;}
        else if (x === 3) {ref3IsValid.current = i; ref3Value.current = v;}
        else if (x === 4) {ref4IsValid.current = i; ref4Value.current = v;}
        calc();
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current && ref2IsValid.current && ref3IsValid.current && ref4IsValid.current);
    };

    const calc = () => {
        setTargetNpl(ratedValue(ref2Value.current, ref4Value.current) + ' TL');
        // document.getElementById('targetNpl').value = ratedValue(ref2Value.current, ref4Value.current) + ' TL';
    };

    const process = (e) => {
        e.preventDefault();
        const data = {
            'id': p.data && p.data.id,
            'title': ref0Value.current,
            'desc': ref1Value.current,
            'targetPlasman': stripSeperators(ref2Value.current),
            'targetProfit': stripSeperators(ref3Value.current),
            'targetNpl': ratedIntValue(ref2Value.current, ref4Value.current),
            'targetNplRate': stripSeperators(ref4Value.current),
            'isActive': isActive
        };
        p.process(data);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 1 }}>
            <Box>
                <StringInput label='Banka Adı' id='title' value={ref0Value.current} onChange={(i, v) => handleInputChange(0, i, v)} autoFocus/>
                <StringInput label='Genel Banka Verileri' id='desc' value={ref1Value.current} onChange={(i, v) => handleInputChange(1, i, v)} multiline/>
                <Box marginTop='4px'>
                    <AmountInput label='Plasman Hedefi *' id='targetPlasman' value={ref2Value.current} onChange={(i, v) => handleInputChange(2, i, v)} />
                    <AmountInput label='Kâr Hedefi *' id='targetProfit' value={ref3Value.current} onChange={(i, v) => handleInputChange(3, i, v)} />
                    <PercentageInput label='Max NPL % *' id='targetNplRate' value={ref4Value.current} onChange={(i, v) => handleInputChange(4, i, v)} />
                    <TextField label='Max NPL Tutarı' id='targetNpl' value={targetNpl} disabled variant='filled' sx={{ marginTop: '4px', width:'100%' }} inputProps={{ style: { textAlign: 'right'} }} />
                </Box>
                { p.data && <DualSwitch value={isActive} setValue={setIsActive} labelTrue='Aktif' labelFalse='Pasif' width={240} height={40} size={24}/> }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    )
    ;
}

export default BankForm;