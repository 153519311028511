import { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CycloneIcon from '@mui/icons-material/Cyclone';
import KConfirm from '../Components/KConfirm'
import KModal from "../Components/KModal";
import KTable from "../Components/KTable";
import Loading from "../Components/Loading";
import PopupMessage from "../Components/PopupMessage";
import TripleSwitch from "../Components/TripleSwitch";
import TrainingForm from "../Forms/TrainingForm";
import { apiAddTraining, apiCloneTraining, apiGetAllActiveBanks, apiGetAllActiveMarkets, apiGetAllTrainings, apiGetTraining, apiStartTimulation, apiUpdateTraining } from "../Lib/Api";
import { dateToReadableString, getBool, getMessage, isMessageKeyValid, MESSAGES } from "../Lib/Constants";
import processApi from "../Lib/ProcessApi";

const TrainingDefinition = (p) => {

    console.log('TrainingDefinition render');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const [data, setData] = useState(null);
    const [activeFilter, setActiveFilter] = useState('null');
    const [openModal, setOpenModal] = useState(false);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [refresh, setRefresh] = useState(0);


    let modalTitle = useRef(null);
    let formProcessButtonTitle = useRef(null);
    let formProcess = useRef(null);
    let formData = useRef(null);
    let markets = useRef(null);
    let banks = useRef(null);


    const onAddClick = async () => onClick('Yeni Eğitim Tanımı', 'Sakla', processAdd);
    const onUpdateClick = async (id) => onClick('Eğitim Bilgileri Güncelleme', 'Güncelle', processUpdate, id);
    const onClick = async (t, l, f, id) => {
        modalTitle.current = t;
        formProcessButtonTitle.current = l;
        formProcess.current = f;
        if (!markets.current)
            markets.current = await processApi('TrainingDefinition onUpdateClick0', p.checkApiMessage, setMessageKey, apiGetAllActiveMarkets);
        if (!markets.current) return;
        if (!banks.current)
            banks.current = await processApi('TrainingDefinition onUpdateClick1', p.checkApiMessage, setMessageKey, apiGetAllActiveBanks);
        if (!banks.current) return;
        formData.current = id ? await processApi('TrainingDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetTraining, id) : null;
        if (id && !formData.current) return;
        setOpenModal(true);
    };
    const onStartClick = async (id) => {
        const rtn = await processApi('TrainingDefinition onStartClick', p.checkApiMessage, setMessageKey, apiStartTimulation, {id: id});
        if (!rtn || rtn.messageKey == null) return;
        const msg = getMessage(rtn.messageKey);
        if (msg.severity === 'success' || msg.severity === 'warning')
            p.startSim(rtn);
    };

    const onCloneClick = (id) => {
        modalTitle.current = 'Eğitim Bilgileri Kopyalama';
        formData.current = id;
        setOpenConfirm(true);
    }

    const clone = async (id) => {
        await processApi('TrainingDefinition clone', p.checkApiMessage, setMessageKey, apiCloneTraining, id);
        setRefresh(refresh + 1);
    }

    const headers = [
        {
            id: 'title',
            align: 'left',
            label: 'Eğitimin Adı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'marketTitle',
            align: 'left',
            label: 'Piyasa',
            sortable: true,
            disablePadding: true
        },
        {
            id: 'bankTitle',
            align: 'left',
            label: 'Banka',
            sortable: true,
            disablePadding: true
        },
        {
            id: 'startDate',
            align: 'center',
            label: 'Başlama Tarihi',
            sortable: true,
            disablePadding: true,
            convert: (d) => dateToReadableString(d)
        },
        {
            id: 'endDate',
            align: 'center',
            label: 'Bitiş Tarihi',
            sortable: true,
            disablePadding: true,
            convert: (d) => dateToReadableString(d)
        },
        {
            id: 'id',
            flag: 'isActive',
            align: 'right',
            label: '',
            sortable: false,
            disablePadding: true,
            convert: (id, flag) =>
                <Box display='flex'>
                    <Tooltip title={'Kopyala'}>
                        {<IconButton onClick={async () => await onCloneClick(id)} sx={{marginLeft: 3}}><CycloneIcon fontSize='small' color='primary' /></IconButton>}
                    </Tooltip>
                    <Tooltip title={'Güncelle'}>
                        {<IconButton onClick={async () => await onUpdateClick(id)}><UpdateOutlinedIcon fontSize='small' color='primary' /></IconButton>}
                    </Tooltip>
                    {flag &&
                        <Tooltip title={'Simülasyonu Başlat'}>
                            {<IconButton onClick={async () => await onStartClick(id)}><PlayArrowIcon fontSize='small' color='primary' /></IconButton>}
                        </Tooltip>
                    }
                </Box>
        }
    ];


    const processAdd = async (d) => await process('processAdd', MESSAGES.CREATED, apiAddTraining, d);
    const processUpdate = async (d) => await process('processUpdate', MESSAGES.UPDATED, apiUpdateTraining, d);

    const process = async (t, m, f, d) => {
        const r = await processApi('TrainingDefinition ' + t, p.checkApiMessage, setMessageKey, f, d);
        if (!r) return;
        setOpenModal(false);
        setMessageKey(m);
        setRefresh(refresh + 1);
    };

    const closeModal = () => setOpenModal(false);
    const closeConfirm = () => setOpenConfirm(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const d = await apiGetAllTrainings({ activeFilter: getBool(activeFilter) });
            if (p.checkApiMessage(d)) return;
            setData(d);
            setLoading(false);
        };
        fetchData();
    }, [p, refresh, activeFilter]);

    return (
        <Grid component="main" maxWidth='md' justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' sx={{ color: '#53a57d', fontWeight: 700 }}>Eğitim Tanımları</Typography>
            <Box display='block' width={{ xs: '100%', sm: '50%' }} mx={2} my={2} justifyContent={'space-evenly'}>
                <Box width='100%' display='flex' justifyContent='center' >
                    <TripleSwitch
                        value={activeFilter}
                        setValue={setActiveFilter}
                        labels={['Aktif', 'Hepsi', 'Pasif']}
                        values={['true', 'null', 'false']}
                        width={200} height={20} size={12} />
                </Box>
            </Box>
            <KTable headers={headers} data={data} marginTop={{ xs: 0, sm: -7 }}  orderBy='title' onAddClick={onAddClick} isDense />
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
            {loading &&
                <Loading loading={loading} />}
            {openModal &&
                <KModal title={modalTitle.current} open={openModal} handleClose={closeModal} top='20%' width={{ xs: '400px', sm: '560px' }}>
                    <TrainingForm 
                        processButtonTitle={formProcessButtonTitle.current} 
                        process={formProcess.current} 
                        data={formData.current} 
                        banks={banks.current}
                        markets={markets.current}
                        checkApiMessage={p.checkApiMessage}
                        setMessageKey={setMessageKey}
                        handleClose={closeModal} />
                </KModal>
            }
            {openConfirm &&
                <KModal title={modalTitle.current} open={openConfirm} handleClose={closeConfirm} top='50%' width='300px'>
                    <KConfirm process={() => clone(formData.current)} handleClose={closeConfirm} title='Bu eğitimi kopyalamak istediğinize'/>
                </KModal>
            }
        </Grid>
    );
};

export default TrainingDefinition;