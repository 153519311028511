import { useEffect, useState } from "react";
import { Box, IconButton, List, ListItemButton, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import KSelect from "../Components/KSelect";
import ForwardIcon from '@mui/icons-material/Forward';
import { getItemStyle } from '../Lib/Constants';


const flatten = (splitedUserList) => (splitedUserList && splitedUserList.length > 0) ? splitedUserList.flat() : null;

const UserSelectionFormFullList = (p) => {

    console.log('UserSelectionFormFullList render');

    const [companyId, setCompanyId] = useState(p.companyId ?? '');
    const [searchWord, setSearchWord] = useState(p.searchWord ?? '');
    const [filtered, setFiltered] = useState(p.fullUserList ?? []);

    useEffect(() => {
        let temp = companyId !== '' ? p.fullUserList.filter((u) => u.companyId === companyId) : [...p.fullUserList];
        const upper = searchWord?.toUpperCase() ?? '';
        temp = upper !== '' ? temp.filter((u) => (u.userId + u.fullName).toUpperCase().includes(upper)) : temp;
        const flat = flatten(p.selectedUserList);
        if (flat) temp = temp.filter(u => !flat.some(f => f.userId === u.userId));
        setFiltered(temp);
        p.setFiltered(temp);
    }, [p, companyId, searchWord]);


    return (
        <Box width='100%'  >
            <KSelect label='Filtrelemek İçin Firma Seçebilirsiz' id='companyId' value={companyId} setValue={(c) => { setCompanyId(c); p.setCompanyId(c); }} data={p.companyList} marginTop='0' />
            <TextField id='searchWord' label='Filtrelemek İçin Kelime Girebilirsiniz' fullWidth value={searchWord} onChange={(e) => { setSearchWord(e.target.value); p.setSearchWord(e.target.value); }} />
            <Box backgroundColor='lightGrey' mt='8px' >
                <Typography component='h6' sx={{ fontSize: '14px', fontWeight: 700, paddingLeft: 1 }}>Tüm Kullanıcılar</Typography>
                <Paper style={{ maxHeight: '660px', overflowY: 'auto', overflowX: 'hidden', backgroundColor: 'ThreeDFace' }}>
                    <Droppable droppableId={'newUser'} type={'user'}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <List dense >
                                    {filtered && filtered.length > 0 && filtered.map((user, i) =>
                                        <Draggable key={'newUserDrag-' + i.toString()} draggableId={'newUserDrag-' + i.toString()} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    ref={provided.innerRef} >
                                                    <ListItem key={user.userId} disableGutters disablePadding dense
                                                        secondaryAction={
                                                            <Tooltip title={'Taşı - ' + user.userId}><IconButton edge='end' size='small' onClick={() => p.move(user)}><ForwardIcon color='primary' /></IconButton></Tooltip>
                                                        }>
                                                        <ListItemButton>
                                                            <Tooltip title={user.userId}><ListItemText primary={user.fullName} /></Tooltip>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                </List>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Paper>
            </Box>
        </Box>
    );
}

export default UserSelectionFormFullList;