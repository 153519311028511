import { Box, List, ListItem, ListItemIcon, Typography, Tooltip, ListItemText } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done';


const CaseOptionListDisplay = (p) => {

    console.log('CaseOptionListDisplay render: ', p.values);
    const mtx = p.marginTop || 0;

    return (
        <Box sx={{ width: '100%', mt: mtx }} bgcolor={'ThreeDFace'}>
            <Box display='flex' bgcolor={'LightGrey'}>
                {/* <Typography component='h6' variant='h6' sx={{ color: '#53a57d', fontWeight: 700, paddingLeft: 1 }}>{p.label}</Typography> */}
                <Typography component='h6' variant='h6' sx={{ color: 'black', fontWeight: 700, paddingLeft: 1 }}>{p.label}</Typography>
            </Box>
            {p.values.length > 0 &&
                <List dense >
                    {p.values.map((v, i) =>
                        <ListItem key={i} disableGutters disablePadding dense>
                            {v.isDefault &&
                                <Tooltip title='Talep Edilen'>
                                    <ListItemIcon sx={{ minWidth: '0' }}><DoneIcon color='primary' /></ListItemIcon>
                                </Tooltip>
                            }
                            <ListItemText sx={{ paddingLeft: v.isDefault ? '0' : '24px', whiteSpace: 'preserve-breaks' }} primary={p.primary(v)} secondary={v.score + ' puan'} />
                        </ListItem>
                    )}
                </List>
            }
        </Box>
    );
};

export default CaseOptionListDisplay;