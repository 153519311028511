import { useRef, useState } from 'react';
import { Box, Button, Tooltip } from "@mui/material";
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BankDisplay from './BankDisplay';
import ClassList from './ClassList';
import KModal from '../Components/KModal';
import { displayFileInNewTab } from '../Lib/Constants';
import { apiGetBank } from "../Lib/Api";
import processApi from '../Lib/ProcessApi';

const GamePlayerInitialState = (p) => {

    console.log('GamePlayerInitialState render');

    const [ openModal, setOpenModal ] = useState(false);
    let modal = useRef(null);

    const displayBank = async () => {
        const bankData = await processApi('GameManagerCaseDetails displayBank', p.checkApiMessage, p.setMessageKey, apiGetBank, p.data.bankId);
        if (!bankData) return;
        modal.current = {
            title: bankData.title,
            form: <BankDisplay data={bankData} handleClose={closeModal} />,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);

    return (
        <Box>
            <Box display='flex' justifyContent='space-evenly' margin='8 8px 8px 16px' >
                <Tooltip title='Piyasa Bilgileri'>
                    <Button 
                        variant='contained' 
                        onClick={() => displayFileInNewTab('/api/market/Piyasa Bilgileri?id=' + p.data.marketId)}
                        startIcon={<CurrencyLiraIcon />} 
                        sx={{maxWidth:'175px', my:'4px'}}>PİYASA BİLGİLERİ</Button>
                </Tooltip>
                <Tooltip title='Banka Bilgileri'> 
                    <Button variant='contained' onClick={displayBank} startIcon={<AccountBalanceIcon />} sx={{maxWidth:'175px', my:'4px'}}>BANKA BİLGİLERİ</Button>
                </Tooltip>
            </Box>
            <ClassList trainingId={p.data.trainingId} checkApiMessage={p.checkApiMessage} setMessageKey={p.setMessageKey}/>
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
}

export default GamePlayerInitialState;