import { forwardRef } from "react";
import { getMessage, MESSAGES } from "../Lib/Constants"
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const PopupMessage = (p)  => {

    console.log('PopupMessage render: ', p.messageKey);

    const message = getMessage(p.messageKey);

    const handleClose = (event, reason) => {
        p.setMessageKey(MESSAGES.NONE);
    };

    return (
        <> {message && message.txt.length > 0 &&
            <Snackbar open={message && message.txt.length > 0} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={message.severity} sx={{ width: '100%' }}>
                    {message.txt}
                </Alert>
            </Snackbar> }
        </>
    );
};

export default PopupMessage;