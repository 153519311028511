import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { displayReportInNewTab, NAV_ITEMS } from '../Lib/Constants'
import MenuItemz from '../Components/MenuItemz';


const AdminMenu = (p) => {

    console.log('AdminMenu render');


    const [ancMobile, setAncMobile] = useState(null);
    const [ancAccount, setAncAccount] = useState(null);

    const [ancParms, setAncParms] = useState(null);
    const [ancFuncs, setAncFuncs] = useState(null);
    const [ancRprts, setAncRprts] = useState(null);
  
    const originTopLeft = { vertical: 'top', horizontal: 'left' };
    const originTopRight = { vertical: 'top', horizontal: 'right' };
    const originBotLeft = { vertical: 'bottom', horizontal: 'left' };
    const originBotCenter = { vertical: 'bottom', horizontal: 'center' };
    const displayDesktop = { xs: 'none', md: 'flex' };
    const displayMobile = { xs: 'flex', md: 'none' };
    const menuButtonSx = { my: 2, mx: 3, color: 'white', display: 'block' };

    const navigate = (closeMenu, navItem) => { closeMenu(null); p.setNavItem(navItem); };
    const navigateToNewTab = (closeMenu, navItem) => { closeMenu(null); displayReportInNewTab(navItem); };

    return (
      <AppBar position="static">
        <Container maxWidth="0">
            <Toolbar disableGutters>
                {/***************************** Desktop **********************************/}
                <Box sx={{ display: displayDesktop, mr: 4, bgcolor: 'white', cursor: 'pointer' }} onClick={() => p.setNavItem(NAV_ITEMS.WELCOME)}>
                    <img src="./krediAkademi.png" alt="kredi akademi" width={48} />
                </Box>
                <Box sx={{ flexGrow: 1, display: displayDesktop }}>
                    <Button onClick={(e) => setAncParms(e.currentTarget)} sx={menuButtonSx}>PARAMETRELER</Button>
                    <Button onClick={(e) => setAncFuncs(e.currentTarget)} sx={menuButtonSx}>FONKSİYONLAR</Button>
                    <Button onClick={(e) => setAncRprts(e.currentTarget)} sx={menuButtonSx}>RAPORLAR</Button>
                </Box>

                {/***************************** Mobile ***********************************/}
                <Box sx={{ flexGrow: 1, display: displayMobile }}>
                    <IconButton size="large" aria-label="mobile menu" aria-controls="menu-appbar" aria-haspopup="true" color="inherit"
                        onClick={(e) => setAncMobile(e.currentTarget)} >
                        <MenuIcon />
                    </IconButton>
                    <Menu id="menu-appbar" keepMounted anchorOrigin={originBotLeft} transformOrigin={originTopLeft} sx={{ display: { xs: 'block', md: 'none' } }} 
                        anchorEl={ancMobile} 
                        open={Boolean(ancMobile)} 
                        onClose={() => setAncMobile(null)} >
                        <MenuItemz label='Parametreler' onClick={(e) => { setAncMobile(null); setAncParms(e.currentTarget); }}/>
                        <MenuItemz label='Fonksiyonlar' onClick={(e) => { setAncMobile(null); setAncFuncs(e.currentTarget); }}/>
                        <MenuItemz label='Raporlar' onClick={(e) => { setAncMobile(null); setAncRprts(e.currentTarget); }}/>
                    </Menu>
                </Box>
                
                <Box sx={{ display: displayMobile, mr: 1, bgcolor: 'white', cursor: 'pointer' }} onClick={() => p.setNavItem(NAV_ITEMS.WELCOME)}>
                    <img src="./krediAkademi.png" alt="kredi akademi" width={40} />
                </Box>
                <Typography noWrap variant="h5" component="h5" sx={{ mr: 2, display: displayMobile, flexGrow: 1, fontFamily: 'monospace', fontWeight: 700, letterSpacing: '.3rem', color: 'inherit', textDecoration: 'none', cursor: 'pointer' }}
                    onClick={() => p.setNavItem(NAV_ITEMS.WELCOME)}>
                    kepsim24
                </Typography>

                {/***************************** Profile **********************************/}
                <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title={p.account.fullName}>
                        <IconButton onClick={(e) => setAncAccount(e.currentTarget)} sx={{ p: 0 }}>
                            <Avatar alt={p.account.fullName} src="." /> {/* <Avatar alt={p.account.fullName} src="./static/images/avatar/2.jpg" /> */}
                        </IconButton>
                    </Tooltip>
                    <Menu id="menu-profile" sx={{ mt: '45px' }} keepMounted
                        anchorEl={ancAccount} anchorOrigin={originTopRight} transformOrigin={originTopRight}
                        open={Boolean(ancAccount)}
                        onClose={() => setAncAccount(null)} >
                        <MenuItemz label='Şifre Güncelleme' onClick={() => navigate(setAncAccount, NAV_ITEMS.UPDATE_PASSWORD)}/>
                        <MenuItemz label='Çıkış' onClick={(e) => { setAncAccount(null); p.logOut(); }}/>
                    </Menu>
                </Box>
                
                {/***************************** Common Menus *****************************/}
                <Menu keepMounted anchorOrigin={originBotCenter} transformOrigin={originTopLeft} 
                    anchorEl={ancParms}
                    open={Boolean(ancParms)} 
                    onClose={() => setAncParms(null)} >
                    <MenuItemz label='Firma Tanımları' onClick={() => navigate(setAncParms, NAV_ITEMS.DEF_COMPANY)}/>
                    <MenuItemz label='Kullanıcı Tanımları' onClick={() => navigate(setAncParms, NAV_ITEMS.DEF_USER)}/>
                    <MenuItemz label='Piyasa Tanımları' onClick={() => navigate(setAncParms, NAV_ITEMS.DEF_MARKET)}/>
                    <MenuItemz label='Banka Tanımları' onClick={() => navigate(setAncParms, NAV_ITEMS.DEF_BANK)}/>
                </Menu>
                <Menu keepMounted anchorOrigin={originBotCenter} transformOrigin={originTopLeft} 
                    anchorEl={ancFuncs}
                    open={Boolean(ancFuncs)} 
                    onClose={() => setAncFuncs(null)} >
                    <MenuItemz label='Olay Tanımları' onClick={() => navigate(setAncFuncs, NAV_ITEMS.DEF_CASE)}/>
                    <MenuItemz label='Eğitim Tanımları' onClick={() => navigate(setAncFuncs, NAV_ITEMS.DEF_TRAINING)}/>
                </Menu>
                <Menu keepMounted anchorOrigin={originBotCenter} transformOrigin={originTopLeft} 
                    anchorEl={ancRprts}
                    open={Boolean(ancRprts)} 
                    onClose={() => setAncRprts(null)} >
                    <MenuItemz label='Kullanıcı Özeti' onClick={() => navigateToNewTab(setAncRprts, NAV_ITEMS.SUMMARY_PLAYER_ADMIN)}/>
                    <MenuItemz label='Komite Özeti' onClick={() => navigateToNewTab(setAncRprts, NAV_ITEMS.SUMMARY_TEAM_ADMIN)}/>
                    <MenuItemz label='Olay Takip' onClick={() => navigateToNewTab(setAncRprts, NAV_ITEMS.CASE_FOLLOW_UP_ADMIN)}/>
                    <MenuItemz label='Kullanıcı-Komite Takip' onClick={() => navigateToNewTab(setAncRprts, NAV_ITEMS.FOLLOW_UP_ADMIN)}/>
                    <MenuItemz label='KPI Raporu' onClick={() => navigateToNewTab(setAncRprts, NAV_ITEMS.ACHIEVEMENT_REPORT_ADMIN)}/>
                </Menu>


            </Toolbar>
        </Container>
      </AppBar>
    );
}

export default AdminMenu;