import { useState, useRef } from 'react';
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material';
import SetMealOutlinedIcon from '@mui/icons-material/SetMealOutlined';
import ReCAPTCHA from "react-google-recaptcha";
import { apiFishMemory } from '../Lib/Api';
import { ACCOUNT_STATE, MESSAGES, isMessageKeyValid } from '../Lib/Constants';
import EMailInput from '../Components/EMailInput';
import Loading from '../Components/Loading';
import PopupMessage from '../Components/PopupMessage';

const FishMemory = (p) => {

    console.log('FishMemory render');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const recaptcha = useRef();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const data = {
            userId: ref0Value.current,
            captchaToken: recaptcha.current.getValue()
        };
        const account = await apiFishMemory(data);
        if (account && !!account.id)
            connected(account)
        else
            notConnected();
        setLoading(false);
    };


    const connected = (account) => {
        if (account.messageKey > 0) setMessageKey(account.messageKey);
        if (account.state === ACCOUNT_STATE.NOT_GRANTED)
            return; // stay on same page
        p.setAppMessageKey(MESSAGES.PASSWORD_RESET);
        p.setAccount(null);
    };

    const notConnected = () => setMessageKey(MESSAGES.INVALID_OPERATION);

    const handleGenius = () => p.setAccount(null);

    let ref0IsValid = useRef(false);
    let ref0Value = useRef('');
    const [ submitEnabled, setSubmitEnabled ] = useState(false);
    const handleInputChange = (m, v) => {
        ref0IsValid.current = m;
        ref0Value.current = v;
        setSubmitEnabled(m && recaptcha.current.getValue());
    };

    return (
        <>
            { p.firstEntry &&
                <Grid container justifyContent='flex-end' mt={2} paddingRight={4} >
                    <img src="./krediAkademiBuyuk.png" alt="kredi akademi" width={192} />
                </Grid>}
            <Container component="main" maxWidth="xs" >
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                    <Avatar sx={{ m: 1, bgcolor: '#53a57d' }}>
                        <SetMealOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" textAlign={'center'}>
                        Şifremi Sıfırla
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>    
                        <EMailInput label='E-posta Adresiniz' autoFocus onChange={(isValid, value) => handleInputChange(isValid, value)}/>
                        <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} onChange={() => handleInputChange(ref0IsValid.current, ref0Value.current)}/>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 1 }} disabled={!submitEnabled}>
                            SIFIRLA
                        </Button>
                        <Grid container justifyContent='flex-end'>
                            <Button sx={{textTransform: 'none'}} onClick={handleGenius}>Şifremi hatırladım</Button>
                        </Grid>
                    </Box>
                </Box>
            </Container>
            { !!(isMessageKeyValid(messageKey)) && 
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey}/> }
            { loading && <Loading loading={loading} />}
        </>
    );
};

export default FishMemory;