import { getMessage, MESSAGES } from "./Constants";

const processApi = async (con, checkApiMessage, setMessageKey, funcAsync, request)  => {      
    if (con)  console.log(con);
    const d = request ? await funcAsync(request) : await funcAsync();
    if (con)  console.log(con + ' response', d);
    if (!d || d.errors || d.traceId) {
        if (setMessageKey)
            setMessageKey(MESSAGES.INVALID_OPERATION);
        return null;
    }
    if ((typeof checkApiMessage !== 'function') || checkApiMessage(d)) return null;
    const msg = getMessage(d.messageKey);
    if (msg !== MESSAGES.NONE) {
        if (setMessageKey)
            setMessageKey(msg);
        // return null;
    }
    return d;
};

export default processApi;