import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './fonts/Aileron/Aileron-Regular.otf'
import './fonts/Montserrat/Montserrat-Regular.otf'
import './fonts/RussoOne/RussoOne-Regular.ttf'


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <App />
)