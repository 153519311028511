import { Box, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { displayFileInNewTab } from '../Lib/Constants'; 


const KSelect = (p) => {

    console.log('KSelect render');
    const href = process.env.REACT_APP_BASE_URL + p.displayUrl;

    const handleChange = (e) => p.setValue(e.target.value);

    return (
        <Box width={p.width || '100%'} height={p.height || 'auto'} marginTop={p.marginTop || 2} marginBottom={1} >
            <FormControl fullWidth>
                <InputLabel id="select-label">{p.label}</InputLabel>
                <Select labelId="select-label" id={p.id} value={p.value} label={p.label} onChange={handleChange}
                    endAdornment={
                        <InputAdornment position='start' >
                            {!!p.displayUrl && p.value !== '' &&
                                <IconButton onClick={() => displayFileInNewTab(href + p.value)} ><VisibilityOutlinedIcon fontSize='small' color='primary'/></IconButton>
                            }
                            {!!p.displayFn && p.value !== '' &&
                                <IconButton onClick={() => p.displayFn(p.value)}><VisibilityOutlinedIcon fontSize='small' color='primary' /></IconButton>
                            }
                        </InputAdornment>
                        }
                    >
                    {
                        p.data &&
                        p.data.map((item) => <MenuItem key={item.key} value={item.key}>{item.value}</MenuItem>)
                    }
                </Select>
            </FormControl>
        </Box>
    );
};

export default KSelect;