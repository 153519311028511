import { useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Tooltip } from '@mui/material';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import BankDisplay from './BankDisplay';
import CaseOptionListDisplay from './CaseOptionListDisplay';
import Display from '../Components/Display';
import KModal from '../Components/KModal';
import { displayFileInNewTab, maturityTypeToString, toCurrency } from '../Lib/Constants';
import { apiGetBank } from '../Lib/Api';
import processApi from '../Lib/ProcessApi';

const GameManagerCaseDetails = (p) => {

    console.log('GameManagerCaseDetails render: ', p.data.displayedCase);

    const [ data, setData ] = useState(p.data.displayedCase);
    const [ openModal, setOpenModal ] = useState(false);
    let modal = useRef(null);


    const displayBank = async () => {
        const bankData = await processApi('GameManagerCaseDetails displayBank', p.checkApiMessage, p.setMessageKey, apiGetBank, p.data.bankId);
        if (!bankData) return;
        modal.current = {
            title: bankData.title,
            form: <BankDisplay data={bankData} handleClose={closeModal} />,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);

    useEffect(() => setData(p.data.displayedCase), [p.data.displayedCase]);

    return (
        <Box sx={{ mt: 0 }}>
            {/* <Typography component='h6' variant='h6' sx={{ color: '#53a57d', fontWeight: 700 }}>CASE DETAILS</Typography> */}
            <Box display='flex' justifyContent='space-evenly' margin='0 8px 8px 8px' >
                <Tooltip title='Piyasa Bilgileri'>
                    <Button 
                        variant='contained' 
                        onClick={() => displayFileInNewTab('/api/market/Piyasa Bilgileri?id=' + p.data.marketId)}
                        startIcon={<CurrencyLiraIcon />} 
                        sx={{maxWidth:'175px', my:'4px'}}>PİYASA BİLGİLERİ</Button>
                </Tooltip>
                <Tooltip title='Banka Bilgileri'> 
                    <Button variant='contained' onClick={displayBank} startIcon={<AccountBalanceIcon />} sx={{maxWidth:'175px', my:'4px'}}>BANKA BİLGİLERİ</Button>
                </Tooltip>
                <Tooltip title='Firma Faaliyetleri - Mali Veriler'>
                    <Button 
                        variant='contained' 
                        onClick={() => displayFileInNewTab('/api/case/Firma Faaliyetleri - Mali Veriler?id=' + data.id)}
                        startIcon={<BalanceIcon />} 
                        sx={{maxWidth:'175px', my:'4px'}}>FİRMA BİLGİLERİ</Button>
                </Tooltip>
            </Box>

            
            <Grid container spacing={1}>
                {/* <Grid item xs={6}><StringInput label='Talep Nedeni' id='desc' value={data.desc} rows={2} readOnly/></Grid> */}
                {/* <Grid item xs={6}><AmountInput label='Kâr / Zarar *' id='profitLoss' value={data.profitLoss} readOnly/></Grid> */}
                <Grid item xs={12}><Display label='Talep Nedeni' value={data.desc}/></Grid>
                <Grid item xs={6}><Display label='Kâr / Zarar' value={data.profitLoss} suffix='TL' number/></Grid>
                <Grid item xs={6}><Display label='Kredinin Durumu' value={data.isPaidBack ? 'Geri ödemesi yapıldı' : 'Sorunlu hale geldi'}/></Grid>
                <Grid item xs={4}>
                    <CaseOptionListDisplay label='Limitler'
                        values={data && data.limits ? JSON.parse(data.limits) : []}
                        primary={(v) => toCurrency(v.limit) + ' TL'} />
                </Grid>
                <Grid item xs={4}>
                    <CaseOptionListDisplay label='Vadeler'
                        values={data && data.maturities ? JSON.parse(data.maturities) : []}
                        primary={(v) => toCurrency(v.maturity) + maturityTypeToString(v.mtype) } />
                </Grid>
                <Grid item xs={4}>
                    <CaseOptionListDisplay label='Teminatlar'
                        values={data && data.collaterals ? JSON.parse(data.collaterals) : []} 
                        primary={(v) => v.collateral} />
                </Grid>
            </Grid>
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
};

export default GameManagerCaseDetails;