import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import KSelect from '../Components/KSelect';
import UploadInput from '../Components/UploadInput';


const UserUploadForm = (p) => {

    console.log('UserUploadForm render');

    const [companyId, setCompanyId] = useState('');
    const [submitEnabled, setSubmitEnabled] = useState(!!p.data);

    const fileTooltip = 'Yüklemek istediğiniz dosyayı seçin *' ;
    
    let fileRef = useRef(null);

    const handleFileChange = (i, v) => {
        fileRef.current = v.files[0];
        setSubmitEnabled(companyId !== '' && fileRef.current != null);
    };
    const handleCompanyIdChange = (v) => {
        setCompanyId(v);
        setSubmitEnabled(v !== '');
    }

    const process = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('uploadFile', fileRef.current, fileRef.current.name);
        data.append('companyId', companyId);
        p.process(data);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 1 }}>
            <Box>
                <KSelect label='Firma Adı *' id='companyId' value={companyId} setValue={handleCompanyIdChange} data={p.select} />
                <UploadInput onChange={handleFileChange} label={fileTooltip} tooltip={fileTooltip} fileFilter='.xls, .xlsx, .xlsm, .csv'/>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    )
        ;
}

export default UserUploadForm;