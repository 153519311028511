
export const NAV_ITEMS = {
    WELCOME: 0,
    DEF_COMPANY: 1,
    DEF_USER: 2,
    DEF_MARKET: 3,
    DEF_BANK: 4,
    DEF_CASE: 5,
    DEF_TRAINING: 6,
    GAME_MANAGER: 7,
    GAME_PLAYER: 8,
    FOLLOW_UP_ADMIN: 9,
    FOLLOW_UP_USER: 10,
    CASE_FOLLOW_UP_ADMIN: 11,
    CASE_FOLLOW_UP_USER: 12,
    SUMMARY_PLAYER_ADMIN: 13,
    SUMMARY_PLAYER_USER: 14,
    SUMMARY_TEAM_ADMIN: 15,
    SUMMARY_TEAM_USER: 16,
    ACHIEVEMENT_REPORT_ADMIN: 17,
    ACHIEVEMENT_REPORT_USER: 18,
    UPDATE_PASSWORD: 19,
    DISPLAY_FILE: 20
};

export const ACCOUNT_STATE = {
    NOT_GRANTED: 0,
    TIME_RESTRICTED: 1,
    EXPIRED: 2,
    GRANTED: 3,
    FISH_MEMORY: 4
};

export const MESSAGES = {
    NONE: {severity: '', txt:''},

    INVALID_OPERATION: {severity: 'error', txt:'İşlemizi yaparken bir hata oluştu'},
    INVALID_CAPTCHA: {severity: 'warning', txt:'Captcha hatalı, lütfen tekrar deneyin'},
    INVALID_MAIL_ADDRESS: {severity: 'warning', txt:'E-posta adresi hatalı'},
    INVALID_PASSWORD: {severity: 'warning', txt:'Şifre hatalı'},
    INVALID_FULL_NAME: {severity: 'warning', txt:'Ad soyad hatalı'}, 
    INVALID_COMPANY_TITLE: {severity: 'warning', txt:'Firma adı hatalı'},
    INVALID_MARKET_TITLE: {severity: 'warning', txt:'Piyasa adı hatalı'},
    INVALID_BANK_TITLE: {severity: 'warning', txt:'Banka adı hatalı'},
    INVALID_BANK_DESC: {severity: 'warning', txt:'Banka tanımı hatalı'},
    INVALID_FILE: {severity: 'error', txt:'Yüklenmek istenen dosya boş veya hatalı'},
    INVALID_LIMITS: {severity: 'error', txt:'Limit bilgisi boş veya hatalı'},
    INVALID_MATURITIES: {severity: 'error', txt:'Vade bilgisi boş veya hatalı'},
    INVALID_COLLATERALS: {severity: 'error', txt:'Teminat bilgisi boş veya hatalı'},
    INVALID_BANK_ID: {severity: 'error', txt:'Banka bilgisi boş veya hatalı'},
    INVALID_MARKET_ID: {severity: 'error', txt:'Piyasa bilgisi boş veya hatalı'},
    INVALID_START_DATE: {severity: 'error', txt:'Başlanma tarihi boş veya hatalı'},
    INVALID_TRAINING_TITLE: {severity: 'error', txt:'Eğitim adı hatalı'},
    INVALID_USER_IDS: {severity: 'error', txt:'Sınıf bilgisi boş veya hatalı'},
    INVALID_CASE_IDS: {severity: 'error', txt:'Olay listesi boş veya hatalı'},
    INVALID_CASE_ID: {severity: 'warning', txt:'Aktif olmayan olay için cevap gönderiyorsunuz. Lütfen eğitimcinizi takip edin.'},
    INVALID_ID: {severity: 'warning', txt:'Kayıt bulunamadı'},
    INVALID_ACTIVATION_ORDER: {severity: 'error', txt:'Olaylar sırayla aktif yapılmalı'},
    INVALID_PAUSE_ACTION: {severity: 'error', txt:'Aktif durumda olmayan olayı duraksatamazsınız.'},
    INVALID_STOP_ACTION: {severity: 'error', txt:'Duraklatılmış durumda olmayan olayı durduramazsınız.'},

    // 25
    PASSWORDS_ARE_NOT_SAME: {severity: 'warning', txt:'Şifre ve tekrarı aynı olmalı'},
    TIME_RESTRICTED: {severity: 'warning', txt:'3 Hatalı giriş denemesi nedeniyle 3 dk beklemeye alındı'},
    UNAUTHORIZED: {severity: 'error', txt:'Bu işlemi yapmaya yetkili değilsiniz'},
    LOGGED_IN_ANOTHER_IP: {severity: 'error', txt:'Bu kullanıcı ile başka bir yerden giriş yapıldı.'},
    NOT_GRANTED: {severity: 'warning', txt:'E-posta adresi veya şifre hatalı'},
    EXPIRED: {severity: 'warning', txt:'Lütfen şifrenizi değiştirin'},
    COMPANY_ALREADY_DEFINED: {severity: 'warning', txt:'Benzer isimli bir firma tanımlı' },
    USER_ALREADY_DEFINED: {severity: 'warning', txt:'Kullanıcı adı daha önce tanımlanmış' },
    USER_IN_TRAININGS: {severity: 'warning', txt:'Kullanıcı adı oyunlarda tanımlı olduğu için değiştirilemez' },
    USER_IN_SUMMARIES: {severity: 'warning', txt:'Kullanıcı adı özetlerde bulunduğu için değiştirilemez' },
    NO_DATA_FOUND_IN_FILE: {severity: 'warning', txt:'Dosyada geçerli kayıt bulunamadı' },
    SIM_STARTED_IN_OTHER_IP_FOR_SAME_USER: {severity: 'warning', txt:'Başka bir sunucuda aktif olan Simulasyon burada tekrar başlatıldı.' },
    SIM_STARTED_FOR_ANOTHER_USER: {severity: 'warning', txt:'Başka bir eğitimcinin kullandığı Simulasyon burada tekrar başlatıldı.' },
    SIM_ALREADY_ACTIVE: {severity: 'success', txt:'Simulasyon aktif durumda.' },
    SIM_RUNNING_SOMEWHERE_ELSE: {severity: 'warning', txt:'Simulasyon başka bir yerde çalışıyor.' },

    // 40
    PASSWORD_RESET: {severity: 'success', txt:'Yeni şifreniz e-posta adresinize gönderildi.'},
    PASSWORD_UPDATED: { severity: 'success', txt:'Şifreniz güncellendi'},
    CREATED: { severity: 'success', txt:'Yeni kayıt başarıyla oluşturuldu'},
    UPDATED: { severity: 'success', txt:'Kayıt güncellendi'},
    UPLOADED: { severity: 'success', txt:'Dosya yükleme işlemi başarıyla tamamlandı'},
    SIMULATION_STARTED: { severity: 'success', txt:'Simülasyon başlatıldı'},
    SIMULATION_REFRESHED: { severity: 'success', txt:'Eğitim değişiklikleri önbelleğe aktarıldı'},
    CASE_STARTED: { severity: 'success', txt:'Olay aktif hale getirildi.'},
    CASE_PAUSED: { severity: 'success', txt:'Olay duraksatıldı. Olay Takip Tablosu görülebilir durumda.'},
    CASE_STOPPED: { severity: 'success', txt:'Olay tamamlandı. Özet ve Takip Tabloları tamamlanan olaylar için görülebilir durumda.'},
    PLAYER_ANSWER_POSTED: { severity: 'success', txt:'Kredi kararı başarılı bir şekilde gönderildi.'},
    TEAM_ANSWER_POSTED: { severity: 'success', txt:'Komite kararı başarılı bir şekilde gönderildi.'},
    CASE_CLONED: { severity: 'success', txt:'Olay başarıyla kopyalandı'},
    TRAINING_CLONED: { severity: 'success', txt:'Eğitim başarıyla kopyalandı'},
    LOGGED_OUT: { severity: 'success', txt:'Hoşçakalın...'},

};

let msg = [];
for(let v in MESSAGES) msg.push(v);//msg = [...msg, v];

export const getMessage = (i) => {
    const m = typeof i === 'number' ? MESSAGES[msg[i]] : typeof i === 'string' ? MESSAGES[i] : i;
    return m ? m : MESSAGES.NONE;
}

export const isMessageKeyValid = (k) => k && (
    (typeof k == 'number' && k > 0) ||
    (typeof k == 'string' && k.length > 0) ||
    (typeof k == 'object' && k.severity.length > 0)
    );

export const getBool = (s) => s === 'true' ? true : s === 'false' ? false : null;

const NEWTAB = 'newtab';
export const displayFileInNewTab = (url) => {
    localStorage.setItem(NEWTAB, JSON.stringify({ navItem: NAV_ITEMS.DISPLAY_FILE, url: url, noMenu: true }));
    window.open('/');
}
export const displayReportInNewTab = (ni) => {
    localStorage.setItem(NEWTAB, JSON.stringify({ navItem: ni, noMenu: true }));
    window.open('/');
}
export const isNavigatedToNewTab = () => {
    const newTab = localStorage.getItem(NEWTAB);
    if (!newTab) return null;
    localStorage.removeItem(NEWTAB)
    return JSON.parse(newTab);
}
export const getNewTabAccount = () => JSON.parse(localStorage.getItem('account'));


const regexStrip = /[/.,]/g;
export const stripSeperators = (x) =>{ 
    if (x == null)
        return null;
    let s = x.toString();
    const i = s.indexOf(',');
    if (i > -1)
        s = s.substr(0, i);
    return s.replace(regexStrip, '');
}

const trNumberFormat = new Intl.NumberFormat('tr-TR');
export const toCurrency = (s) =>  !s || s === 0 ? '' : trNumberFormat.format(stripSeperators(stripSeperators(s)));

export const toPercentage = (n) => new Intl.NumberFormat('default', { style: 'percent', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(n / 100);
export const toDecimal = (n, f = 0) => new Intl.NumberFormat('default', { minimumFractionDigits: f, maximumFractionDigits: f }).format(n);

export const maxId = (array) => !array || array.length === 0 ? 0 : (Math.max(...array.map(o => o.id)) + 1);

const padLeft = (n) => n.toString().padStart(2, '0');
export const dateToReadableString = (str) => !str || str.length < 10 ? '-' : str.length < 16 
    ? str.slice(8, 10) + '.' + str.slice(5, 7) + '.' + str.slice(0, 4)
    : str.slice(8, 10) + '.' + str.slice(5, 7) + '.' + str.slice(0, 4) + ' ' + str.slice(11, 13) + ':' + str.slice(14, 16) ;
export const toDate = (str) => str === null || str.length < 10 ? null : str.length < 16 
    ? new Date(Number(str.slice(0, 4)), Number(str.slice(5, 7))-1, Number(str.slice(8, 10)))
    : new Date(Number(str.slice(0, 4)), Number(str.slice(5, 7))-1, Number(str.slice(8, 10)), Number(str.slice(11,13)), Number(str.slice(14,16)));
export const toDateString = (date) => date.getFullYear() + padLeft(date.getMonth() + 1) + padLeft(date.getDate()) + padLeft(date.getHours()) + padLeft(date.getMinutes());
export const toTeamName = (teamId) => `Kredi Komitesi ${(Number(teamId) + 1).toString()}`;
export const maturityTypeToString = (mtype) => mtype === 0 ? ' Gün' : mtype === 1 ? ' Ay' : mtype === 2 ? ' Yıl' : '';

export const getItemStyle = (isDragging, draggableStyle) => ({
    ...draggableStyle,
    ...(isDragging && {
        background: 'ThreeDFace',
        border: '3px dotted #53a57d',
        transition: 'transform 0.4s'
    })
});