import { useRef, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AmountInput from '../Components/AmountInput';
import DualSwitch from '../Components/DualSwitch';
import KModal from '../Components/KModal';
import StringInput from '../Components/StringInput';
import UploadInput from '../Components/UploadInput'
import CaseOptionList from './CaseOptionList';
import CaseLimitForm from './CaseLimitForm'
import CaseMaturityForm from './CaseMaturityForm';
import CaseCollateralForm from './CaseCollateralForm';
import { maturityTypeToString, stripSeperators, toCurrency } from '../Lib/Constants';


const CaseForm = (p) => {

    console.log('CaseForm render: ', p.data);

    const [ isActive, setIsActive ] = useState(!p.data || p.data.isActive);
    const [ isPaidBack, setIsPaidBack ] = useState(!p.data || p.data.isPaidBack);
    const [ limits, setLimits ] = useState(p.data && p.data.limits ? JSON.parse(p.data.limits) : []);
    const [ maturities, setMaturities ] = useState(p.data && p.data.maturities ? JSON.parse(p.data.maturities) : []);
    const [ collaterals, setCollaterals ] = useState(p.data && p.data.collaterals ? JSON.parse(p.data.collaterals) : []);
    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);
    const [ openModal, setOpenModal] = useState(false);

    const fileTooltip = !!p.data ? "Değişmemesi için boş bırakın" : 'Firma Faaliyetleri / Mali Veriler *' ;
    
    let modalTitle = useRef(null);
    let modalForm = useRef(null);

    const onAddLimit = () => onProcessOption('Yeni Limit', <CaseLimitForm processButtonTitle='Ekle' process={processAddOption} values={limits} setValues={setLimits} data={null} handleClose={closeModal} seqNo={limits.length}/>);
    const onAddMaturity = () => onProcessOption('Yeni Vade', <CaseMaturityForm processButtonTitle='Ekle' process={processAddOption} values={maturities} setValues={setMaturities} data={null} handleClose={closeModal} seqNo={maturities.length}/>);
    const onAddCollateral = () => onProcessOption('Yeni Teminat', <CaseCollateralForm processButtonTitle='Ekle' process={processAddOption} values={collaterals} setValues={setCollaterals} data={null} handleClose={closeModal} seqNo={collaterals.length}/>);

    const onUpdateLimit = (data, index) => onProcessOption('Limit Güncelleme', <CaseLimitForm processButtonTitle='Güncelle' process={processUpdateOption} values={limits} setValues={setLimits} data={data} handleClose={closeModal} seqNo={index}/>);
    const onUpdateMaturity = (data, index) => onProcessOption('Vade Güncelleme', <CaseMaturityForm processButtonTitle='Güncelle' process={processUpdateOption} values={maturities} setValues={setMaturities} data={data} handleClose={closeModal} seqNo={index}/>);
    const onUpdateCollateral = (data, index) => onProcessOption('Teminat Güncelleme', <CaseCollateralForm processButtonTitle='Güncelle' process={processUpdateOption} values={collaterals} setValues={setCollaterals} data={data} handleClose={closeModal} seqNo={index}/>);
    
    const onProcessOption = (t, f) => {
        modalTitle.current = t;
        modalForm.current = f;
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);
    const processAddOption = (data, setValues, values, index) => { 
        const t = (data.isDefault) ? values.map(i => {i.isDefault = false; return i;} ) : values.slice();
        t.push(data); 
        fix(t, setValues);
    };
    const processUpdateOption = (data, setValues, values, index) => {
        const t = values.slice();
        if (index >= t.length) return;
        t.splice(index, 1, data);
        fix(t, setValues); 
    };
    const processDeleteOption = (values, setValues, index) => {
        const isDefault = values[index].isDefault;  
        if (index >= values.length) return;
        const t = values.slice();
        t.splice(index, 1);
        if (isDefault && t.length > 0) t[0].isDefault = true;
        fix(t, setValues); 
    };
    const processSetDefault = (values, setValues, index) => {
        if (index >= values.length) return;
        const t = values.map(i => {i.isDefault = false; return i;});
        t[index].isDefault = true;
        fix(t, setValues);
    };
    const fix = (t, setValues) => { setValues(t); setOpenModal(false); decideSubmit(t);}

    
    let ref0IsValid = useRef(!!p.data && p.data.fileName);  // uploadFile
    let ref1IsValid = useRef(!!p.data);     // title
    let ref2IsValid = useRef(!!p.data);     // desc
    let ref3IsValid = useRef(!!p.data);     // profitLoss
    let ref0Value = useRef(null);
    let ref1Value = useRef(p.data && p.data.title);
    let ref2Value = useRef(p.data && p.data.desc);
    let ref3Value = useRef(p.data && p.data.profitLoss);

    const handleInputChange = (x, i, v) => {
        if (x === 1) {ref1IsValid.current = i; ref1Value.current = v;}
        else if (x === 2) {ref2IsValid.current = i; ref2Value.current = v;}
        else if (x === 3) {ref3IsValid.current = i; ref3Value.current = v;}
        decideSubmit();
    }; 
    const handleFileChange = (i, v) => {
        ref0Value.current = v.files[0];
        ref0IsValid.current = !!p.data || (ref0Value.current && ref0Value.current.name);
        decideSubmit();        
    };

    const decideSubmit = (t) => {
        if ((t && t.length === 0) || !ref0IsValid.current || !ref1IsValid.current || !ref2IsValid.current || !ref3IsValid.current) {
            setSubmitEnabled(false);
            return;
        }
        const l = t && t.length > 0 && t[0].limit ? t : limits;
        const m = t && t.length > 0 && t[0].maturity ? t : maturities;
        const c = t && t.length > 0 && t[0].collateral ? t : collaterals;
        setSubmitEnabled( l != null && l.length > 0 && m != null && m.length > 0  && c != null &&c.length > 0);
    };
        

    const process = (e) => {
        e.preventDefault();
        let data = new FormData();
        if (p.data)
            data.append('id', p.data.id);
        if (ref0Value.current) 
            data.append('uploadFile', ref0Value.current, ref0Value.current.name);
        data.append('title', ref1Value.current);
        data.append('desc', ref2Value.current);
        data.append('profitLoss', Number(stripSeperators(ref3Value.current)));
        data.append('isActive', isActive);
        data.append('isPaidBack', isPaidBack);
        data.append('limits', JSON.stringify(limits));
        data.append('maturities', JSON.stringify(maturities));
        data.append('collaterals', JSON.stringify(collaterals));
        p.process(data);
    };

    return (
        <Box sx={{ mt: 0 }}>
            <Box>
                <UploadInput onChange={handleFileChange} label='Firma Faaliyetleri / Mali Veriler *' tooltip={fileTooltip} fileFilter='application/pdf' fileName={p.data?.fileName} id={p.data?.id} displayUrl='api/case/Firma Faaliyetleri - Mali Veriler?id=' sx={{ mt: 0}}/>
                <StringInput label='Olay Adı' id='title' value={ref1Value.current} onChange={(i, v) => handleInputChange(1, i, v)} autoFocus/>
                <StringInput label='Talep Nedeni' id='desc' value={ref2Value.current} onChange={(i, v) => handleInputChange(2, i, v)} multiline rows={2}/>
                <AmountInput label='Kâr / Zarar *' id='profitLoss' value={ref3Value.current} onChange={(i, v) => handleInputChange(3, i, v)} />
                <Grid container spacing={2}>
                    <Grid item xs={6}><DualSwitch value={isPaidBack} setValue={setIsPaidBack} labelTrue='Ödendi' labelFalse='Ödenmedi' width={180} height={24} size={15}/></Grid>
                    <Grid item xs={6}><DualSwitch value={isActive} setValue={setIsActive} labelTrue='Aktif' labelFalse='Pasif' width={180} height={24} size={15}/></Grid>
                    <Grid item xs={4}>
                        <CaseOptionList label='Limitler' newItemLabel='Yeni Limit' 
                            values={limits} setValues={setLimits}
                            primary={(v) => toCurrency(v.limit) + ' TL'}
                            onAddClick={onAddLimit} 
                            onUpdateClick={onUpdateLimit} 
                            delete={processDeleteOption} 
                            setDefault={processSetDefault} />
                    </Grid>
                    <Grid item xs={4}>
                        <CaseOptionList label='Vadeler' newItemLabel='Yeni Vade'
                            values={maturities} setValues={setMaturities}
                            primary={(v) => toCurrency(v.maturity) + maturityTypeToString(v.mtype) }
                            onAddClick={onAddMaturity} 
                            onUpdateClick={onUpdateMaturity} 
                            delete={processDeleteOption} 
                            setDefault={processSetDefault} />
                    </Grid>
                    <Grid item xs={4}>
                        <CaseOptionList label='Teminatlar' newItemLabel='Yeni Teminat'
                            values={collaterals} setValues={setCollaterals}
                            primary={(v) => v.collateral}
                            onAddClick={onAddCollateral} 
                            onUpdateClick={onUpdateCollateral} 
                            delete={processDeleteOption} 
                            setDefault={processSetDefault} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button onClick={process} variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
            {openModal &&
                <KModal title={modalTitle.current} open={openModal} handleClose={closeModal} top='40%' width='300px'>
                    {modalForm.current}
                </KModal>
            }
        </Box>
    )
    ;
}

export default CaseForm;