import { Box, Button } from '@mui/material';
import Display from '../Components/Display';

const BankDisplay = (p) => {

    console.log('BankDisplay render: ', p.data);

    return (
        <Box sx={{ mt: 2 }}>
            <Box>
                <Display label='Genel Banka Verileri' value={p.data.desc} />
                <Box container spacing='4px' marginTop='4px'>
                    <Display label='Plasman Hedefi' value={p.data.targetPlasman} suffix='TL' number />
                    <Display label='Kâr Hedefi' value={p.data.targetProfit} suffix='TL' number />
                    <Display label='Max NPL %' id='targetNplRate' value={p.data.targetNplRate} prefix='%' number />
                    <Display label='Max NPL Tutarı' value={p.data.targetNpl} suffix='TL' number />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button variant="contained" onClick={p.handleClose}>KAPAT</Button>
            </Box>
        </Box>
    )
    ;
}

export default BankDisplay;