import { useEffect, useState } from "react";
import { Box, IconButton, List, ListItemButton, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import ForwardIcon from '@mui/icons-material/Forward';
import { getItemStyle } from '../Lib/Constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


const CaseSelectionFormFullList = (p) => {

    console.log('CaseSelectionFormFullList render');

    const [searchWord, setSearchWord] = useState(p.searchWord ?? '');
    const [filtered, setFiltered] = useState(p.fullUserList ?? []);

    useEffect(() => {
        let temp = [...p.fullCaseList];
        const upper = searchWord?.toUpperCase() ?? '';
        temp = upper !== '' ? temp.filter((c) => (c.value).toUpperCase().includes(upper)) : temp;
        temp = temp.filter(c => !p.selectedCaseList.some(s => s.key === c.key));
        setFiltered(temp);
        p.setFiltered(temp);
    }, [p, p.fullCaseList, p.selectedCaseList, searchWord]);


    return (
        <Box width='100%'  >
            <TextField id='searchWord' label='Filtrelemek İçin Kelime Girebilirsiniz' fullWidth value={searchWord} onChange={(e) => { setSearchWord(e.target.value); p.setSearchWord(e.target.value); }} />
            <Box backgroundColor='lightGrey' mt='8px' >
                <Typography component='h6' sx={{ fontSize: '14px', fontWeight: 700, paddingLeft: 1 }}>Tüm Olaylar</Typography>
                <Paper style={{ maxHeight: '660px', overflowY: 'auto', overflowX: 'hidden', backgroundColor: 'ThreeDFace' }}>
                    <Droppable droppableId={'newCase'} type={'case'}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <List dense >
                                    {filtered && filtered.length > 0 && filtered.map((c, i) =>
                                        <Draggable key={'newCaseDrag-' + i.toString()} draggableId={'newCaseDrag-' + i.toString()} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                    ref={provided.innerRef} >
                                                    <ListItem key={c.key} disableGutters disablePadding dense
                                                        secondaryAction={
                                                            <>
                                                            <Tooltip title='Görüntüle'><IconButton onClick={() => p.displayCase(c.key)}><VisibilityOutlinedIcon fontSize='small' color='primary' /></IconButton></Tooltip>
                                                            <Tooltip title={'Taşı - ' + c.value}><IconButton edge='end' size='small' onClick={() => p.move(c)}><ForwardIcon color='primary' /></IconButton></Tooltip>
                                                            </>
                                                        }>
                                                        <ListItemButton>
                                                            <Tooltip title={c.value}><ListItemText primary={c.value} /></Tooltip>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                </List>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </Paper>
            </Box>
        </Box>
    );
}

export default CaseSelectionFormFullList;