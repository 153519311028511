// import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: '#53a57d',
    },
}));

const KProgressBar = (p) => {

    console.log('KProgressBar render');
    // const [ value, setValue ] = useState(!!p.value && !!p.scale ? Math.round(p.value * 100 / p.scale) : 0)

    // useEffect(() => setValue(!!p.value && !!p.scale ? Math.round(p.value * 100 / p.scale) : 0), [p.value, p.scale]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginLeft: '8px' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <BorderLinearProgress variant="determinate" value={!!p.value && !!p.scale ? Math.round(p.value * 100 / p.scale) : 0} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">{`%${!!p.value && !!p.scale ? Math.round(p.value * 100 / p.scale) : 0}`}</Typography>
            </Box>
        </Box>
    );
}

export default KProgressBar;