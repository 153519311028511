import { useRef, useState } from "react";
import { Box, Grid, Hidden, IconButton, Tooltip, Typography } from "@mui/material";
import MuiButton from "@mui/material/Button";
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AnswerFormApproveCommit from "./AnswerFormApproveConfirm";
import AnswerFormDenyCommit from "./AnswerFormDenyConfirm";
import KModal from "../Components/KModal";
import KRadioGroup from "../Components/KRadioGroup";
import Loading from "../Components/Loading";
import { getMessage, maturityTypeToString, toCurrency } from "../Lib/Constants";
import processApi from "../Lib/ProcessApi";

const limitOptionValue = (o) => `${toCurrency(o.limit)} TL`;
const maturityOptionValue = (o) => `${toCurrency(o.maturity)} ${maturityTypeToString(o.mtype)}`;
const collateralOptionValue = (o) => o.collateral;

const answerFormTheme = createTheme({
    palette: {
      primary: {
        main: '#53a57d',
        contrastText: '#fff'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    }
  });

const ApproveButton = styled(MuiButton)(({ theme }) => ({
    width:'60px', 
    height: '60px', 
    fontSize: 24, 
    fontWeight: 700,
    borderRadius: '50%'
}));
const DenyButton = styled(MuiButton)(({ theme }) => ({
    width:'60px', 
    height: '60px', 
    fontSize: 24, 
    fontWeight: 700,
    borderRadius:  '50%',
    backgroundColor: 'crimson',
    '&:hover': {
        backgroundColor: 'darkred'
    },
}));

const getInfoText = (teamId, decision) => decision == null ? '' : 
    teamId 
    ? decision 
        ? 'Kredi Komitesi ONAY Kararı Gönderildi'
        : 'Kredi Komitesi RED Kararı Gönderildi.'
    : decision 
        ? 'Kredi ONAY Kararınız Gönderildi'
        : 'Kredi RED Kararınız Gönderildi';

const AnswerForm = (p) => {

    console.log('AnswerForm render: ', p);
    
    let teamId = useRef(p.teamId);
    let trainingId = useRef(p.trainingId);
    let caseId = useRef(p.data.id)
    let limits = useRef(JSON.parse(p.data.limits));
    let maturities = useRef(JSON.parse(p.data.maturities));
    let collaterals = useRef(JSON.parse(p.data.collaterals));
    let answer = useRef(!p.teamId ? p.data.playerAnswer : p.data.teamAnswer);
    let modal = useRef(null);
    
    const [ limitId, setLimitId ] = useState(answer.current?.limitId ?? limits.current.find(o => o.isDefault).id);
    const [ commitedLimitId, setCommitedLimitId ] = useState(answer.current?.limitId);

    const [ maturityId, setMaturityId ] = useState(answer.current?.maturityId ?? maturities.current.find(o => o.isDefault).id);
    const [ commitedMaturityId, setCommitedMaturityId ] = useState(answer.current?.maturityId);
    
    const [ collateralId, setCollateralId ] = useState(answer.current?.collateralId ?? collaterals.current.find(o => o.isDefault).id);
    const [ commitedCollateralId, setCommitedCollateralId ] = useState(answer.current?.collateralId);
    
    const [ infoText, setInfoText ] = useState(getInfoText(p.teamId, answer.current?.decision));
    const [ loading, setLoading ] = useState(false);
    const [ openModal, setOpenModal ] = useState(false);

    const onApproveClick = () => { 
        modal.current = {
            title: teamId.current ? 'Kredi Komitesi Kararı' : 'Kredi Kararı',
            form: <AnswerFormApproveCommit teamId={teamId.current}  process={approve} handleClose={closeModal}
                limit={limitOptionValue(limits.current.find(o => o.id === Number(limitId)))} 
                maturity={maturityOptionValue(maturities.current.find(o => o.id === Number(maturityId)))} 
                collateral={collateralOptionValue(collaterals.current.find(o => o.id === Number(collateralId)))} />,
            top: '40%',
            width: { xs: '300px', sm: '400px' }
        };
        setOpenModal(true);
    }
    const onDenyClick = () => {
        modal.current = {
            title: teamId.current ? 'Kredi Komitesi Kararı' : 'Kredi Kararı',
            form: <AnswerFormDenyCommit teamId={teamId.current}  process={deny} handleClose={closeModal}/>,
            top: '40%',
            width: '300px'
        };
        setOpenModal(true);
    }
    const approve = async () => process(true, limitId, maturityId, collateralId);
    const deny = async() => process(false, null, null, null);
    const process = async(d, l, m, c) => {
        setLoading(true);
        const req = {
            id: trainingId.current,
            caseId: caseId.current,
            teamId: teamId.current,
            decision: d,
            limitId: d ? limitId : -1,
            limit: d ? limits.current.find(o => o.id === Number(limitId)).limit : 0,
            maturityId: d ? maturityId : -1,
            maturity: d ? maturities.current.find(o => o.id === Number(maturityId)).maturity : 0,
            maturityType: d ? maturities.current.find(o => o.id === Number(maturityId)).mtype : -1,
            collateralId: d ? collateralId : -1,
            collateral: d ? collaterals.current.find(o => o.id === Number(collateralId)).collateral : ''
        };
        const res = await processApi('AnswerForm answer', p.checkApiMessage, p.setMessageKey, p.sendAnswer, req);
        setLoading(false);
        if (!res) return;
        const msg = getMessage(res.messageKey);
        if (msg.severity !== 'success') return;
        setInfoText(getInfoText(teamId.current, d));
        setCommitedLimitId(l);
        setCommitedMaturityId(m);
        setCommitedCollateralId(c);
        closeModal();
    }

    const closeModal = () => setOpenModal(false);

    return (
        <Box marginTop={p.marginTop || 'none'}>
            <ThemeProvider theme={answerFormTheme}>

                <Hidden mdDown> 
                    <Grid container spacing='0px' marginTop={0} >
                        <Grid item xs={4} padding={2}>
                            <KRadioGroup value={limitId} commited={commitedLimitId} values={limits.current} title='Limit' convert={limitOptionValue} setValue={setLimitId}/>
                        </Grid>
                        <Grid item xs={4} padding={2}>
                            <KRadioGroup value={maturityId} commited={commitedMaturityId} values={maturities.current} title='Vade' convert={maturityOptionValue}  setValue={setMaturityId}/>
                        </Grid>
                        <Grid item xs={4} padding={2}>
                            <KRadioGroup value={collateralId} commited={commitedCollateralId} values={collaterals.current} title='Teminat' convert={collateralOptionValue}  setValue={setCollateralId} />
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Box marginBottom={2}>
                        <KRadioGroup value={limitId} commited={commitedLimitId} values={limits.current} title='Limit' convert={limitOptionValue} setValue={setLimitId}/>
                        <KRadioGroup value={maturityId} commited={commitedMaturityId} values={maturities.current} title='Vade' convert={maturityOptionValue}  setValue={setMaturityId}/>
                        <KRadioGroup value={collateralId} commited={commitedCollateralId} values={collaterals.current} title='Teminat' convert={collateralOptionValue}  setValue={setCollateralId} />
                    </Box>
                </Hidden>

                <Box display='flex' justifyContent='space-evenly' paddingTop={2}>
                    <Tooltip title='ONAY'>
                        <ApproveButton variant='contained' onClick={onApproveClick} disabled={limitId < 0 || maturityId < 0 || collateralId < 0}>
                            <ThumbUpOffAltIcon sx={{ transform: 'scale(1.6)'}} />
                        </ApproveButton>
                    </Tooltip>
                    <Tooltip title='RED'>
                        <DenyButton variant='contained' onClick={onDenyClick}>
                            <ThumbDownOffAltIcon sx={{ transform: 'scale(1.6)'}}/>
                        </DenyButton>
                    </Tooltip>
                </Box>
                <Box width='100%' display='flex' justifyContent='end' my={1}>
                    <Typography variant='span' align='right' sx={{ color: '#53a57d', fontSize: '12px', fontWeight: 500, whiteSpace: 'nowrap' }} >{infoText}</Typography>
                </Box>
            </ThemeProvider>

            {loading &&
                <Loading loading={loading} />}
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
}

export default AnswerForm;