import { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import UserForm from '../Forms/UserForm';
import UserUploadForm from '../Forms/UserUploadForm';
import { apiAddUser, apiGetAllActiveCompanies, apiGetAllUsers, apiGetUser, apiUpdateUser, apiUploadUser } from '../Lib/Api';
import { getBool, isMessageKeyValid, MESSAGES } from "../Lib/Constants";
import processApi from '../Lib/ProcessApi';
import IsActive from '../Components/IsActive';
import KModal from '../Components/KModal';
import KTable from "../Components/KTable";
import Loading from "../Components/Loading";
import PopupMessage from "../Components/PopupMessage";
import TripleSwitch from '../Components/TripleSwitch';

const UserDefinition = (p) => {

    console.log('UserDefinition render');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const [data, setData] = useState(null);
    const [activeFilter, setActiveFilter] = useState('null');
    const [adminFilter, setAdminFilter] = useState('null');
    const [openModal, setOpenModal] = useState(false);
    const [openUpload, setOpenUpload] = useState(false);
    const [refresh, setRefresh] = useState(0);

    let modalTitle = useRef(null);
    let formProcessButtonTitle = useRef(null);
    let formProcess = useRef(null);
    let formSelect = useRef(null);
    let formData = useRef(null);



    const onAddClick = async () => {    
        const c = await processApi('UserDefinition onAddClick', p.checkApiMessage, setMessageKey, apiGetAllActiveCompanies); 
        if (!c) return;
        modalTitle.current = 'Yeni Kullanıcı Tanımı';
        formProcessButtonTitle.current = 'Sakla';
        formProcess.current = processAdd;
        formSelect.current = c;
        formData.current = null;
        setOpenModal(true);
    }

    const onUpdateClick = async (id) => {
        const c = await processApi('UserDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetAllActiveCompanies); 
        if (!c) return;

        const d = await processApi('UserDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetUser, { userId: id }); 
        if (!d) return;

        modalTitle.current = 'Kullanıcı Güncelleme';
        formProcessButtonTitle.current = 'Güncelle';
        formProcess.current = processUpdate;
        formSelect.current = c;
        formData.current = d;
        setOpenModal(true);
    }

    const onUploadClick = async () => {
        const c = await processApi('UserDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetAllActiveCompanies); 
        if (!c) return;

        modalTitle.current = 'Toplu Kullanıcı Tanımlama';
        formProcessButtonTitle.current = 'Yükle';
        formProcess.current = processUpload;
        formSelect.current = c;
        setOpenUpload(true);
    }

    const headers = [
        {
            id: 'fullName',
            align: 'left',
            label: 'Adı Soyadı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'userId',
            align: 'left',
            label: 'Kullanıcı Adı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'companyTitle',
            align: 'left',
            label: 'Firma Unvanı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'isActive',
            align: 'center',
            label: 'Aktif?',
            disablePadding: true,
            sortable: true,
            convert: (d) => <IsActive isActive={d} />
        },
        {
            id: 'isAdmin',
            align: 'center',
            label: 'Admin?',
            disablePadding: true,
            sortable: true,
            convert: (d) =>
                <Tooltip title={d ? 'Admin' : 'Kullanıcı'}>
                    {d ? <AdminPanelSettingsOutlinedIcon  color='success'/> : <PermIdentityOutlinedIcon />}
                </Tooltip>
        },
        {
            id: 'id',
            idx: 'userId',
            align: 'right',
            label: '',
            disablePadding: true,
            sortable: false,
            convert: (id) =>
                <Tooltip title={'Güncelle'}>
                    {<IconButton onClick={async () => await onUpdateClick(id)}><UpdateOutlinedIcon fontSize='small' color='primary' /></IconButton>}
                </Tooltip>
        }
    ];

    const closeModal = () => setOpenModal(false);
    const closeUpload = () => setOpenUpload(false);
    
    const processAdd = async (d) => await process('processAdd', MESSAGES.CREATED, apiAddUser, d, closeModal);
    const processUpdate = async (d) => await process('processUpdate', MESSAGES.UPDATED, apiUpdateUser, d, closeModal);
    const processUpload = async (d) => await process('processUpload', MESSAGES.UPLOADED, apiUploadUser, d, closeUpload);

    const process = async (t, m, f, d, close) => {
        const r = await processApi('UserDefinition ' + t, p.checkApiMessage, setMessageKey, f, d); 
        if (!r) return;
        close();
        setMessageKey(m);
        setRefresh(refresh+1);
    };


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const req = {
                activeFilter: getBool(activeFilter),
                adminFilter: getBool(adminFilter)
            }
            const d = await apiGetAllUsers(req);
            if (p.checkApiMessage(d)) return;
            setData(d);
            setLoading(false);
        };
        fetchData();
    }, [p, refresh, activeFilter, adminFilter]);

    return (
        <Grid component="main" maxWidth='md' justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' sx={{ color: '#53a57d', fontWeight: 700 }}>Kullanıcı Tanımları</Typography>
            <Box display={{ xs:'block', xm:'flex'}} width={{ xs: '100%', sm: '50%', xm: '66%'}} mx={2} my={2}  justifyContent={'space-evenly'}>
                <Box width={{xs:'100%', xm:'50%'}} display='flex' justifyContent='center' >
                    <TripleSwitch 
                    value={activeFilter}
                    setValue={setActiveFilter}
                    labels={[ 'Aktif', 'Hepsi', 'Pasif']} 
                    values={[ 'true', 'null', 'false' ]}
                    width={200} height={20} size={12} />
                </Box>
                <Box width={{xs:'100%', xm:'50%'}}  display='flex' justifyContent='center' my={{ xs:2, xm: 'auto'}}>
                    <TripleSwitch 
                    value={adminFilter}
                    setValue={setAdminFilter}
                    labels={[ 'Admin', 'Hepsi', 'Kullanıcı']} 
                    values={[ 'true', 'null', 'false' ]}
                    width={200} height={20} size={12} />
                </Box>
            </Box>
            <KTable 
                orderBy='fullName' 
                rowKey='userId' 
                uploadLabel='Toplu Kullanıcı Tanımlama'
                isDense='true'
                marginTop={{xs:0, sm:-7}}
                initialRowsPerPage={10} 
                data={data} 
                headers={headers} 
                onAddClick={onAddClick} 
                onUploadClick={onUploadClick} 
                />
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
            {loading && 
                <Loading loading={loading} />}
            {openModal && 
                <KModal title={modalTitle.current} open={openModal} handleClose={closeModal} top='40%'>
                    <UserForm processButtonTitle={formProcessButtonTitle.current} process={formProcess.current} data={formData.current} select={formSelect.current} handleClose={closeModal}/>
                </KModal>
            }
            {openUpload && 
                <KModal title={modalTitle.current} open={openUpload} handleClose={closeUpload} >
                    <UserUploadForm processButtonTitle={formProcessButtonTitle.current} process={formProcess.current} select={formSelect.current} handleClose={closeUpload}/>
                </KModal>
            }
        </Grid>
    );
};

export default UserDefinition;