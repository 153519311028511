import { useRef } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

const TripleSwitch = (p) => {

    console.log('TripleSwitch render');

    const fullWidth = p.width || 240;
    const thirdWidth = fullWidth / 3;
    const fullHeight = p.height || 40;
    const fontSize = p.size || 24;
    const index = (v) => v === p.values[2] ? 2 : v === p.values[1] ? 1 : 0;
    const getColor = (i) => value.current === p.values[i] ? 'white' : 'gray';

    let value = useRef(p.value);
    let intLeft = useRef(thirdWidth * index(p.value));

    const handleRadio = (e) => {
        p.setValue(e.target.value);
        value.current = e.target.value;
        intLeft.current = thirdWidth * index(value.current); 
    }


    return (
        <Box component='div' width={fullWidth} minWidth={fullWidth} height={fullHeight} borderRadius='100px' boxShadow={'1px 1px 10px 0px #53a57d'} >
            <Box height={fullHeight} width={thirdWidth} left={intLeft.current} bgcolor='#53a57d' borderRadius='100px' zIndex={1} position={'relative'} ></Box>
            <RadioGroup row name='rg' value={value.current} onChange={handleRadio} 
                sx={{ position: 'relative', top: -fullHeight+.5, mx: 0, width: '100%', alignItems: 'center'}} >
                <FormControlLabel value={p.values[0]} sx={{ width: '33%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-evenly' }}  
                    control={<Radio sx={{ display: 'none', zIndex: -3 }}/>} 
                    label={<Typography zIndex='2' color={getColor(0)} fontWeight={700} fontSize={fontSize} >{p.labels[0]}</Typography>}  />
                <FormControlLabel value={p.values[1]} sx={{ width: '33%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-evenly' }}
                        control={<Radio sx={{ display: 'none', zIndex: -3 }}/>} 
                        label={<Typography zIndex='2' color={getColor(1)} fontWeight={700} fontSize={fontSize} >{p.labels[1]}</Typography>}  />
                <FormControlLabel value={p.values[2]} sx={{ width: '33%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-evenly' }} 
                    control={<Radio sx={{ display: 'none', zIndex: -3 }} />} 
                    label={<Typography zIndex='2' color={getColor(2)} fontWeight={700} fontSize={fontSize} >{p.labels[2]}</Typography>} />
            </RadioGroup>
        </Box>
    );
};

export default TripleSwitch