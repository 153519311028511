import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Tooltip, Typography } from "@mui/material";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { getItemStyle } from '../Lib/Constants';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';


const CaseSelectionFormSelectedList = (p) => {

    console.log('CaseSelectionFormSelectedList render');

    const removeCase = (i) => { const t = [...p.selectedCaseList]; t.splice(i, 1); p.removeCase(t); }

    return (
        <Box backgroundColor='lightGrey' mt='0' width='100%'>
            <Box display='flex' alignItems='center' >
                <Typography component='h6' sx={{ fontSize: '14px', fontWeight: 700, paddingLeft: 1, textWrap: 'nowrap' }}>Seçilmiş Olaylar</Typography>
            </Box>
            <Droppable droppableId='case' type='case'>
                {(provided) =>
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Paper style={{ maxHeight: '725px', overflowY: 'auto', overflowX: 'hidden', backgroundColor: 'ThreeDFace' }}>
                            <List dense >
                                {p.selectedCaseList && p.selectedCaseList.map((c, i) =>
                                    <Draggable key={'case-' + c.key} draggableId={'case-' + c.key} index={i}>
                                        {(provided, snapshot) =>
                                            <div {...provided.draggableProps} {...provided.dragHandleProps}
                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                ref={provided.innerRef} >
                                                <ListItem key={'case-' + i.toString()} disableGutters disablePadding dense sx={{ pl: '8px' }}
                                                    secondaryAction={
                                                        <>
                                                            <Tooltip title='Görüntüle'><IconButton onClick={() => p.displayCase(c.key)}><VisibilityOutlinedIcon fontSize='small' color='primary' /></IconButton></Tooltip>
                                                            <Tooltip title={'Sil - ' + c.value}><IconButton edge='end' size='small' onClick={() => removeCase(i)}><DeleteIcon color='primary' /></IconButton></Tooltip>
                                                        </>
                                                    }>
                                                    <ListItemButton><ListItemText primary={c.value} /></ListItemButton>
                                                </ListItem>
                                            </div>
                                        }
                                    </Draggable>
                                )}
                            </List>
                        </Paper>
                        {provided.placeholder}
                    </div>
                }
            </Droppable>
        </Box>
    );
}

export default CaseSelectionFormSelectedList;