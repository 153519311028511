import { useRef, useState } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import CurrencyLiraIcon from '@mui/icons-material/CurrencyLira';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BalanceIcon from '@mui/icons-material/Balance';
import AnswerForm from "./AnswerForm";
import BankDisplay from './BankDisplay';
import Display from "../Components/Display";
import KModal from "../Components/KModal";
import { apiGetBank, apiPlayerAnswer, apiTeamAnswer } from "../Lib/Api";
import { displayFileInNewTab, toTeamName } from "../Lib/Constants";
import processApi from "../Lib/ProcessApi";

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#53a57d',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: 'white'
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        margin: 0,
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: '16px 16px 0 16px',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
  


const GamePlayerCaseDetails = (p) => {

    console.log('GamePlayerCaseDetails render: ', p);

    const [ openModal, setOpenModal ] = useState(false);
    const [ expanded, setExpanded]  = useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };

    let modal = useRef(null);

    const displayBank = async () => {
        const bankData = await processApi('GameManagerCaseDetails displayBank', p.checkApiMessage, p.setMessageKey, apiGetBank, p.data.bankId);
        if (!bankData) return;
        modal.current = {
            title: bankData.title,
            form: <BankDisplay data={bankData} handleClose={closeModal} />,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);

    return (
        <Box sx={{ mt: 1, mr: 1 }}>
            <Box display={{ xs:'block', md:'flex'}} >
                <Typography variant='span' sx={{ fontSize: '24px', color: 'black', fontWeight: 700, whiteSpace: 'nowrap' }} >{p.data.displayedCase.title}</Typography>
                <Box display='flex' justifyContent='space-evenly' margin={0} width='100%'>
                    <Tooltip title='Piyasa Bilgileri'>
                        <Button 
                            variant='contained' 
                            onClick={() => displayFileInNewTab('/api/market/Piyasa Bilgileri?id=' + p.data.marketId)}
                            startIcon={<CurrencyLiraIcon />} 
                            sx={{maxWidth:'175px', my:'4px'}}>PİYASA BİLGİLERİ</Button>
                    </Tooltip>
                    <Tooltip title='Banka Bilgileri'> 
                        <Button variant='contained' onClick={displayBank} startIcon={<AccountBalanceIcon />} sx={{maxWidth:'175px', my:'4px'}}>BANKA BİLGİLERİ</Button>
                    </Tooltip>
                    <Tooltip title='Firma Faaliyetleri - Mali Veriler'>
                        <Button 
                            variant='contained' 
                            onClick={() => displayFileInNewTab('/api/case/Firma Faaliyetleri - Mali Veriler?id=' + p.data.displayedCase.id)}
                            startIcon={<BalanceIcon />} 
                            sx={{maxWidth:'175px', my:'4px'}}>FİRMA BİLGİLERİ</Button>
                    </Tooltip>
                </Box>
            </Box>
            <Box mt={2}> <Display label='Talep Nedeni' value={p.data.displayedCase.desc}/> </Box>
            {!p.data.isTeamHead &&
                <AnswerForm data={p.data.displayedCase} trainingId={p.data.trainingId} sendAnswer={apiPlayerAnswer} checkApiMessage={p.checkApiMessage} setMessageKey={p.setMessageKey} marginTop='12px' />
            }
            {p.data.isTeamHead &&
                <>
                    <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                        <AccordionSummary id='playerAnswer' sx={{ minHeight: '30px'  }}>
                            <Typography sx={{ fontWeight: 500, color: 'white'}}>Kullanıcı Kararı</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AnswerForm data={p.data.displayedCase} trainingId={p.data.trainingId} sendAnswer={apiPlayerAnswer} checkApiMessage={p.checkApiMessage} setMessageKey={p.setMessageKey} />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                        <AccordionSummary id='teamAnswer' sx={{ minHeight: '30px'  }}>
                            <Typography sx={{ fontWeight: 500, color: 'white'}}>{toTeamName(p.data.teamId)}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <AnswerForm data={p.data.displayedCase} trainingId={p.data.trainingId} sendAnswer={apiTeamAnswer} checkApiMessage={p.checkApiMessage} setMessageKey={p.setMessageKey} teamId={p.data.teamId}/>
                        </AccordionDetails>
                    </Accordion>
                </>
            }
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
};

export default GamePlayerCaseDetails;