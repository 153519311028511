import { useState } from 'react';
import { Box, IconButton, Slider, Tooltip, Typography } from '@mui/material';
import ForwardIcon from '@mui/icons-material/Forward';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import styles from '../Styles/styles.module.css';
import './KFileDisplayer.css';

const KFileDisplayer = (p) => {

    console.log('KFileDisplayer render');
    const [ numPages, setNumPages ] = useState(null);
    const [ pageNumber, setPageNumber ] = useState(1);
    const [ scaleFactor, setScaleFactor ] = useState(1);

    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //3.11.174
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
    }
    
    const updateScaleFactor = (e, v) => setScaleFactor(v);

    return (
        <Box display='block' justifyContent='center' backgroundColor='gray' sx={{ width: 'vmax', height: '100vh'}} overflow='auto' >
            {numPages > 1 &&
                <Box display='flex' justifyContent='center' paddingTop={2} width='100vw'>
                    {pageNumber > 1 && 
                        <Tooltip title='Önceki Sayfa'>
                            <IconButton sx={{ transform: 'scale(2) rotate(180deg)'}} onClick={() => setPageNumber(pageNumber-1)}>
                                <ForwardIcon color='primary'/>
                            </IconButton>
                        </Tooltip>
                    }
                    <Typography color='white' fontWeight='700' padding={2}>{`${pageNumber} / ${numPages}`}</Typography>
                    {pageNumber < numPages &&    
                        <Tooltip title='Sonraki Sayfa'>
                            <IconButton sx={{ transform: 'scale(2)'}} onClick={() => setPageNumber(pageNumber+1)}><ForwardIcon color='primary'/></IconButton>
                        </Tooltip>
                        }
                </Box>
            }
            <Box display='flex' justifyContent='center' paddingX={2} paddingTop={1} width='100vw' >
                <Slider defaultValue={1.0} step={0.05} min={0.4} max={2.0} sx={{maxWidth:'800px'}} onChange={updateScaleFactor}/>
            </Box>
            <Box display='flex' justifyContent='center' padding={2}  >
                <Document 
                    file={p.src} onLoadSuccess={onDocumentLoadSuccess}
                    onContextMenu={(e) => e.preventDefault()} 
                    className={styles.pdf}>
                    <Page pageNumber={pageNumber} renderTextLayer={false} renderAnnotationLayer={false} scale={scaleFactor} />
                </Document>
            </Box>
        </Box>
    );
};


export default KFileDisplayer;