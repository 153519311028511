import { Box, Button, Typography } from '@mui/material';
import MuiButton from "@mui/material/Button";
import { styled } from '@mui/material/styles';

const DenyButton = styled(MuiButton)(({ theme }) => ({
    backgroundColor: 'crimson',
    '&:hover': {
        backgroundColor: 'darkred'
    },
}));

const AnswerFormDenyConfirm = (p) => {

    console.log('AnswerFormDenyConfirm render: ', p);

    
    return (
        <Box marginTop={2}>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>RED etmek istediğinize</Typography>
            <Typography variant='span' display='block' sx={{ fontWeight: 500, textAlign: 'center' }}>emin misiniz?</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >

                <DenyButton onClick={p.process} variant="contained" >RED</DenyButton>
                <Button variant='contained' onClick={p.handleClose} >İPTAL</Button>
                
            </Box>
        </Box>
    );
};

export default AnswerFormDenyConfirm;