import { useState, Fragment } from 'react'
import { Box, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ACCOUNT_STATE, getNewTabAccount, isMessageKeyValid, isNavigatedToNewTab } from './Lib/Constants'
import Footer from './Components/Footer.jsx'
import PopupMessage from './Components/PopupMessage';
import FishMemory from './Views/FishMemory.jsx'
import Home from './Views/Home.jsx'
import Login from './Views/Login.jsx'
import UpdatePassword from './Views/UpdatePassword.jsx'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const defaultTheme = createTheme({
    palette: {
      primary: {
        main: '#53a57d',
        contrastText: '#fff'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        xm: 750,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    }
  });

const App = () => {
    
    console.log('App render');

    const [ newTab ] = useState(isNavigatedToNewTab());
    const [ account, setAccount ] = useState(newTab ? getNewTabAccount() : null);
    const [ messageKey, setMessageKey ] = useState(0);

    // account.status
    // 0:NotGranted             ==> Login    
    // 1:TimeRestrictedLogin    ==> Login
    // 2:Expired                ==> UpdatePassword  
    // 3:TimeRestrictedUpdate   ==> UpdatePassword
    // 4:Granted                ==> Home
    // 5:FishMemory             ==> FishMemory

    const main = (!account || !account.state  || account.state === ACCOUNT_STATE.NOT_GRANTED || account.state === ACCOUNT_STATE.TIME_RESTRICTED) 
        ? <Login setAccount={setAccount} />
        : (account.state === ACCOUNT_STATE.EXPIRED || account.state === ACCOUNT_STATE.TIME_RESTRICTED_UPDATE)
            ? <UpdatePassword setAccount={setAccount} setAppMessageKey={setMessageKey} />
            : (account.state === ACCOUNT_STATE.GRANTED)                    
                ? <Home account={account} setAccount={setAccount} setAppMessageKey={setMessageKey} {...newTab} />
                : <FishMemory setAccount={setAccount} setAppMessageKey={setMessageKey} />;
    return (
        <Fragment>
            <ThemeProvider theme={defaultTheme}>
                <CssBaseline />
                <Box>
                    {main}
                    <Footer/>
                </Box>
                { !!(isMessageKeyValid(messageKey)) &&
                    <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey}/>
                }
            </ThemeProvider>
        </Fragment>
    );
};

export default App;