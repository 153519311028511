import { useRef, useState } from 'react'
import { Box } from '@mui/material';
import AdminMenu from './AdminMenu';
import UserMenu from './UserMenu';
import BankDefinition from './BankDefinition';
import CaseDefinition from './CaseDefinition'
import CaseFollowupReport from '../Forms/CaseFollowupReport';
import CompanyDefinition from './CompanyDefinition';
import TrainingDefinition from './TrainingDefinition'
import GameManager from './GameManager'
import GamePlayer from './GamePlayer'
import MarketDefinition from './MarketDefinition';
import ReportWelcomeAdmin from './ReportWelcomeAdmin';
import ReportWelcomeUser from './ReportWelcomeUser';
import UpdatePassword from './UpdatePassword';
import UserDefinition from './UserDefinition';
import AchievementReport from '../Forms/AchievementReport';
import UserFollowupReport from '../Forms/UserFollowupReport';
import UserTeamSummary from '../Forms/UserTeamSummary';
import Welcome from './Welcome';
import WelcomeUser from './WelcomeUser';
import { getMessage, MESSAGES, NAV_ITEMS } from '../Lib/Constants';
import { apiReportTeamSummary, apiReportUserSummary } from '../Lib/Api';
import KFileDisplayer from '../Components/KFileDisplayer';

const Home = (p) => {

    console.log('Home render');

    const [ navItem, setNavItem ] = useState(p.navItem ?? 0);
    let data = useRef(null);

    const logOut = () => {
        localStorage.removeItem('account');
        p.setAccount(null);
        p.setAppMessageKey(MESSAGES.LOGGED_OUT);
    };

    const checkApiMessage = (res) => {
        if (!res || !res.messageKey) return false;
        const msg = getMessage(res.messageKey);
        if (msg !== MESSAGES.UNAUTHORIZED && msg !== MESSAGES.LOGGED_IN_ANOTHER_IP) return false;
        localStorage.removeItem('account');
        p.setAccount(null);
        p.setAppMessageKey(msg);
        return true;
    };

    const menu = p.account.role === 'admin' 
        ? <AdminMenu account={p.account} setNavItem={setNavItem} logOut={logOut} />
        : <UserMenu  account={p.account} setNavItem={setNavItem} logOut={logOut} />;

    const startGameManager = (rtn) => {
        setNavItem(NAV_ITEMS.GAME_MANAGER);
        p.setAppMessageKey(rtn.messageKey);
        data.current = rtn;
    }

    const startGamePlayer = (rtn) => {
        setNavItem(NAV_ITEMS.GAME_PLAYER);
        data.current = rtn;
    };

    const reportFollowup = {
        checkApiMessage: checkApiMessage ,
        title: 'Kullanıcı-Komite Takip',
        tooltip: 'Kullanıcı-Komite Takip Raporu',
        reportType: 'followup',
        maxWidth: 'xl',
        form: (i, m) => <UserFollowupReport id={i} checkApiMessage={checkApiMessage} setMessageKey={m} />
    };
    const reportCaseFollowup = {
        checkApiMessage: checkApiMessage ,
        title: 'Olay Takip',
        tooltip: 'Olay Takip Raporu',
        reportType: 'followup',
        maxWidth: 'xl',
        form: (i, m) => <CaseFollowupReport id={i} checkApiMessage={checkApiMessage} setMessageKey={m} />
    };
    const reportSummaryPlayer = {
        checkApiMessage: checkApiMessage ,
        title: 'Kullanıcı Özeti',
        tooltip: 'Kullanıcı Özeti',
        reportType: 'summary',
        maxWidth: 'md',
        form: (i, m) => <UserTeamSummary id={i} checkApiMessage={checkApiMessage} setMessageKey={m} process={apiReportUserSummary} reportName='userSummary'/>
    };
    const reportSummaryTeam = {
        checkApiMessage: checkApiMessage ,
        title: 'Komite Özeti',
        tooltip: 'Komite Özeti',
        reportType: 'summary',
        maxWidth: 'md',
        form: (i, m) => <UserTeamSummary id={i} checkApiMessage={checkApiMessage} setMessageKey={m} process={apiReportTeamSummary} reportName='teamSummary'/>
    };
    const reportAchievement= {
        checkApiMessage: checkApiMessage ,
        title: 'KPI Raporu',
        tooltip: 'KPI (Key Performance Indıicator) Raporu',
        reportType: 'followup',
        form: (i, m) => <AchievementReport id={i} checkApiMessage={checkApiMessage} setMessageKey={m} />
    };

    const homer = 
        navItem === NAV_ITEMS.DEF_COMPANY ? <CompanyDefinition checkApiMessage={checkApiMessage} /> :
        navItem === NAV_ITEMS.DEF_USER ? <UserDefinition checkApiMessage={checkApiMessage} /> :
        navItem === NAV_ITEMS.DEF_MARKET ? <MarketDefinition checkApiMessage={checkApiMessage} /> :
        navItem === NAV_ITEMS.DEF_BANK ? <BankDefinition checkApiMessage={checkApiMessage} /> :
        navItem === NAV_ITEMS.DEF_CASE ? <CaseDefinition checkApiMessage={checkApiMessage} /> :
        navItem === NAV_ITEMS.DEF_TRAINING ? <TrainingDefinition checkApiMessage={checkApiMessage} startSim={startGameManager}/> :
        navItem === NAV_ITEMS.GAME_MANAGER ? <GameManager checkApiMessage={checkApiMessage} data={data.current} /> :
        navItem === NAV_ITEMS.GAME_PLAYER ? <GamePlayer checkApiMessage={checkApiMessage} data={data.current} /> :        
        navItem === NAV_ITEMS.FOLLOW_UP_ADMIN ? <ReportWelcomeAdmin  {...reportFollowup} /> :
        navItem === NAV_ITEMS.FOLLOW_UP_USER ? <ReportWelcomeUser {...reportFollowup} /> :     
        navItem === NAV_ITEMS.CASE_FOLLOW_UP_ADMIN ? <ReportWelcomeAdmin  {...reportCaseFollowup} /> :
        navItem === NAV_ITEMS.CASE_FOLLOW_UP_USER ? <ReportWelcomeUser {...reportCaseFollowup} /> :
        navItem === NAV_ITEMS.SUMMARY_PLAYER_ADMIN ? <ReportWelcomeAdmin  {...reportSummaryPlayer} /> :
        navItem === NAV_ITEMS.SUMMARY_PLAYER_USER ? <ReportWelcomeUser {...reportSummaryPlayer} /> :
        navItem === NAV_ITEMS.SUMMARY_TEAM_ADMIN ? <ReportWelcomeAdmin  {...reportSummaryTeam} /> :
        navItem === NAV_ITEMS.SUMMARY_TEAM_USER ? <ReportWelcomeUser {...reportSummaryTeam} /> :
        navItem === NAV_ITEMS.ACHIEVEMENT_REPORT_ADMIN ? <ReportWelcomeAdmin  {...reportAchievement} /> :
        navItem === NAV_ITEMS.ACHIEVEMENT_REPORT_USER ? <ReportWelcomeUser {...reportAchievement} /> :
        navItem === NAV_ITEMS.UPDATE_PASSWORD ? <UpdatePassword setAccount={p.setAccount} setAppMessageKey={p.setAppMessageKey} setNavItem={setNavItem} /> :
        navItem === NAV_ITEMS.DISPLAY_FILE ? <KFileDisplayer src={p.url} /> :
        p.account.role === 'admin' ? <Welcome/> : <WelcomeUser checkApiMessage={checkApiMessage} startSim={startGamePlayer}/>;

    return (
        <Box>
            {!p.noMenu && menu}
            {homer}
        </Box>
    );
}

export default Home;