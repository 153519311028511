import { Box, Typography } from "@mui/material";


const Footer = () => {

    console.log('Footer render');

    return (
        <Box component="footer" sx={{py:.6, backgroundColor: '#53a57d', position: 'fixed', bottom: 0, width: '100%', borderRadius: 1, display: { xs: 'none', sm: 'block'}}}>
            <Typography variant="body2" sx={{ color: 'white', float: 'right', pr:2 }}>
                {"Copyright © "}
                {new Date().getFullYear()}
                &nbsp;&nbsp; Kepsim24 by kredi akademi & Venasis
            </Typography>
        </Box>
    );
};

export default Footer;