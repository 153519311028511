import { useEffect, useRef, useState } from 'react';
import { Grid, IconButton, Tooltip, Typography } from '@mui/material';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import { apiAddCompany, apiUpdateCompany, apiGetAllCompanies, apiGetCompany } from '../Lib/Api';
import { isMessageKeyValid, MESSAGES } from '../Lib/Constants';
import processApi from '../Lib/ProcessApi'
import IsActive from '../Components/IsActive';
import KModal from "../Components/KModal";
import KTable from "../Components/KTable";
import Loading from "../Components/Loading";
import PopupMessage from "../Components/PopupMessage";
import CompanyForm from '../Forms/CompanyForm';

const CompanyDefinition = (p) => {

    console.log('CompanyDefinition render');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const [data, setData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState(0);

    let modalTitle = useRef(null);
    let formProcessButtonTitle = useRef(null);
    let formProcess = useRef(null);
    let formData = useRef(null);

    const onAddClick = () => {
        modalTitle.current = 'Yeni Firma Tanımı';
        formProcessButtonTitle.current = 'Sakla';
        formProcess.current = processAdd;
        formData.current = null;
        setOpenModal(true);
    }

    const onUpdateClick = async (id) => {
        const d = await processApi('CompanyDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetCompany, id); 
        if (!d) return;
        modalTitle.current = 'Firma Güncelleme';
        formProcessButtonTitle.current = 'Güncelle';
        formProcess.current = processUpdate;
        formData.current = d;
        setOpenModal(true);
    }

    const headers = [
        {
            id: 'title',
            align: 'left',
            label: 'Firma Ünvanı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'isActive',
            align: 'center',
            label: 'Aktif?',
            disablePadding: true,
            sortable: true,
            convert: (d) => <IsActive isActive={d} />
        },
        {
            id: 'id',
            align: 'right',
            label: '',
            disablePadding: true,
            sortable: false,
            convert: (id) =>
                <Tooltip title={'Güncelle'}>
                    {<IconButton onClick={async () => await onUpdateClick(id)}><UpdateOutlinedIcon fontSize='small' color='primary' /></IconButton>}
                </Tooltip>
        }
    ];


    const processAdd = async (d) => await process('processAdd', MESSAGES.CREATED, apiAddCompany, d);
    const processUpdate = async (d) => await process('processUpdate', MESSAGES.UPDATED, apiUpdateCompany, d);

    const process = async (t, m, f, d) => {
        const r = await processApi('CompanyDefinition ' + t, p.checkApiMessage, setMessageKey, f, d); 
        if (!r) return;
        setOpenModal(false);
        setMessageKey(m);
        setRefresh(refresh+1);
    };

    const closeModal = () => setOpenModal(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const d = await apiGetAllCompanies();
            if (p.checkApiMessage(d)) return;
            setData(d);
            setLoading(false);
        };
        fetchData();
    }, [p, refresh]);

    return (
        <Grid component="main" maxWidth='sm' justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' sx={{ color: '#53a57d', fontWeight: 700 }}>Firma Tanımları</Typography>
            <KTable headers={headers} data={data} marginTop={{xs:0, sm:-7}} initialRowsPerPage={10} orderBy='title' onAddClick={onAddClick} />
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
            {loading && 
                <Loading loading={loading} />}
            {openModal && 
                <KModal title={modalTitle.current} open={openModal} handleClose={closeModal} >
                    <CompanyForm processButtonTitle={formProcessButtonTitle.current} process={formProcess.current} data={formData.current} handleClose={closeModal}/>
                </KModal>
            }
        </Grid>
    );
};

export default CompanyDefinition;