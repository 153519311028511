import { useState, useRef } from 'react';
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import { apiUpdatePassword } from '../Lib/Api';
import { ACCOUNT_STATE, MESSAGES, NAV_ITEMS, isMessageKeyValid } from '../Lib/Constants';
import PasswordInput from '../Components/PasswordInput';
import Loading from '../Components/Loading';
import PopupMessage from '../Components/PopupMessage';

const UpdatePassword = (p) => {

    console.log('UpdatePassword render: ');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const [innerMsg, setInnerMsg] = useState('');

    const title = !p.setNavItem ? 'Lütfen Şifrenizi Güncelleyin' : 'Şifre Güncelleme';

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        const userId = JSON.parse(localStorage.getItem('account')).id;
        const data = {
            userId: userId,
            password: ref0Value.current,
            newPassword: ref1Value.current,
            newPassword2: ref2Value.current
        };
        const account = await apiUpdatePassword(data);
        if (account && !!account.id)
            connected(account)
        else
            notConnected();
        setLoading(false);
    };


    const connected = (account) => {
        if (account.messageKey > 0) setMessageKey(account.messageKey);
        else if (account.state === ACCOUNT_STATE.NOT_GRANTED) setMessageKey(MESSAGES.NOT_GRANTED);
        else if (account.state === ACCOUNT_STATE.TIME_RESTRICTED) p.setAppMessageKey(MESSAGES.TIME_RESTRICTED);
        else if (account.state === ACCOUNT_STATE.GRANTED) { p.setAppMessageKey(MESSAGES.PASSWORD_UPDATED); if (p.setNavItem) p.setNavItem(NAV_ITEMS.WELCOME); }
        if (account.state === ACCOUNT_STATE.NOT_GRANTED)
            return; // stay on same page
        localStorage.setItem('account', JSON.stringify(account));
        p.setAccount(account);
    };

    const notConnected = () => {
        console.log('Login connect error');
        setMessageKey(MESSAGES.INVALID_OPERATION);
    };

    const handleFishMemory = async () => p.setAccount({ state: ACCOUNT_STATE.FISH_MEMORY });

    let ref0IsValid = useRef(false);
    let ref1IsValid = useRef(false);
    let ref2IsValid = useRef(false);
    let ref0Value = useRef('');
    let ref1Value = useRef('');
    let ref2Value = useRef('');
    const [submitEnabled, setSubmitEnabled] = useState(false);

    const handleInputChange = (x, i, v) => {
        if (x === 0) { ref0IsValid.current = i; ref0Value.current = v; }
        else if (x === 1) { ref1IsValid.current = i; ref1Value.current = v; }
        else if (x === 2) { ref2IsValid.current = i; ref2Value.current = v; }
        setInnerMsg(getInnerMessage());
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current && ref2IsValid.current && ref0Value.current !== ref1Value.current && ref1Value.current === ref2Value.current);
    };

    const getInnerMessage = () => (ref0Value.current !== '' && ref1Value.current !== '' && ref0Value.current === ref1Value.current)
        ? 'Yeni şifreniz, mevcut şifreden farklı olmalı'
        : (ref1Value.current.length >= 6 && ref2Value.current.length >= 6 && ref1Value.current !== ref2Value.current)
            ? 'Yeni şifreniz ve tekrarı aynı olmalı'
            : '';

    return (
        <>
            {!p.setNavItem &&
                <Grid container justifyContent='flex-end' mt={2} paddingRight={4} >
                    <img src="./krediAkademiBuyuk.png" alt="kredi akademi" width={192} />
                </Grid>}
            <Container component="main" maxWidth="xs" >
                <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <Avatar sx={{ m: 1, bgcolor: '#53a57d' }}>
                        <LockResetOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" textAlign={'center'}>
                        {title}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                        <PasswordInput label='Mevcut Şifreniz' id='p0' onChange={(isValid, value) => handleInputChange(0, isValid, value)} autoFocus />
                        <PasswordInput label='Yeni Şifreniz' id='p1' onChange={(isValid, value) => handleInputChange(1, isValid, value)} />
                        <PasswordInput label='Yeni Şifre Tekrar' id='p2' onChange={(isValid, value) => handleInputChange(2, isValid, value)} />
                        <Box sx={{ mt: 2 }}>
                            <Typography component="span" textAlign={'center'} color={'error'} fontWeight={'bold'}>
                                {innerMsg}
                            </Typography>
                        </Box>
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 1, mb: 1 }} disabled={!submitEnabled}>
                            GÜNCELLE
                        </Button>
                        {!p.setNavItem &&
                            <Grid container justifyContent='flex-end'>
                                <Button sx={{ textTransform: 'none' }} onClick={handleFishMemory}>Şifremi unuttum?</Button>
                            </Grid>
                        }
                    </Box>
                </Box>
            </Container>
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
            {loading && <Loading loading={loading} />}
        </>
    );
};

export default UpdatePassword;