import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import Download from '../Components/Download';
import KTable from '../Components/KTable';
import { apiReportAchievement } from '../Lib/Api';
import { toCurrency, toDecimal, toPercentage } from '../Lib/Constants';
import processApi from '../Lib/ProcessApi';


const AchievementReport = (p) => {

    console.log('AchievementReport render');
    const [ data, setData ] = useState([]);
    const [ title, setTitle ] = useState(null);
    const [ counter, setCounter ] = useState(0);

    const width = [201, 180, 180, 180, 178];
    const bold = {fontSize: 12, paddingX: 1, fontWeight: 700 };
    const regular = {fontSize: 12, paddingX: 1 };

    const headers = [
        {
            id: 'orderNo',
            align: 'center',
            label: 'Sıra',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'fullName',
            align: 'left',
            label: 'Adı',
            tooltip: 'userId',
            sortable: true,
            disablePadding: false
        },
        {
            id: 'plasman',
            align: 'right',
            label: 'Top.Plasman',
            disablePadding: false,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'plasmanItem0',
            idx: 'plasmanItem',
            align: 'right',
            label: 'Plasman H/G %',
            disablePadding: false,
            sortable: true,
            convert: (d) => toPercentage(d.rate)
        },
        {
            id: 'plasmanItem1',
            idx: 'plasmanItem',
            align: 'right',
            label: 'Plasman KPI',
            disablePadding: false,
            sortable: true,
            convert: (d) => toDecimal(d.kpi, 2)
        },
        {
            id: 'profitLoss',
            align: 'right',
            label: 'Top.K/Z',
            disablePadding: false,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'profitLossItem0',
            idx: 'profitLossItem',
            align: 'right',
            label: 'Kar/Zarar H/G %',
            disablePadding: false,
            sortable: true,
            convert: (d) => toPercentage(d.rate)
        },
        {
            id: 'profitLossItem1',
            idx: 'profitLossItem',
            align: 'right',
            label: 'Kar/Zarar KPI',
            disablePadding: false,
            sortable: true,
            convert: (d) => toDecimal(d.kpi, 2)
        },
        {
            id: 'nplamount',
            align: 'right',
            label: 'Top.NPL Tut.',
            disablePadding: false,
            sortable: true,
            convert: (d) => toCurrency(-d)
        },
        {
            id: 'nplRate',
            align: 'right',
            label: 'NPL %',
            disablePadding: false,
            sortable: true,
            convert: (d) => toPercentage(d)
        },
        {
            id: 'nplItem0',
            idx: 'nplItem',
            align: 'right',
            label: 'NPL H/G %',
            disablePadding: false,
            sortable: true,
            convert: (d) => toPercentage(d.rate)
        },
        {
            id: 'nplItem1',
            idx: 'nplItem',
            align: 'right',
            label: 'NPL KPI',
            disablePadding: false,
            sortable: true,
            convert: (d) => toDecimal(d.kpi, 2)
        },
        {
            id: 'kpi',
            align: 'right',
            label: 'TOPLAM KPI',
            disablePadding: false,
            sortable: true,
            convert: (d) => toDecimal(d, 2)
        },
    ];


    useEffect(() => {
        const fetchData = async () => {
            const d = await processApi('AchievementReport useEffect', p.checkApiMessage, p.setMessageKey, apiReportAchievement, p.id);
            if (!d || d.messageKey !== 0) return;
            setTitle(d.title);
            setData(d.lines);
        };
        fetchData();
    }, [p, counter]);


    return (
        <Box>
            <Typography component='span' variant='span' sx={{ color: 'secondary.main', fontWeight: 700 }}>{title && `(${title})`}</Typography>
            <Download id={p.id} reportName='achievement'/>
            <Box display='flex' border={1} sx={{ mt: 3, minWidth: 743, maxWidth: 743, maxHeight: '800px', overflow: 'auto' }} >
                <Box borderRight={1} minWidth={width[0]} maxWidth={width[0]} display='flex' flexDirection='column' justifyContent='center'>
                    <Typography sx={bold} align='center'>Hedeflenen</Typography>
                </Box>
                <Box>
                    <Box display='flex' backgroundColor='primary.main' height={30}>
                        <Box borderRight={1} minWidth={width[1]} maxWidth={width[1]} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={bold} align='right'>Plasman (Toplam Limit)</Typography>
                        </Box>
                        <Box borderRight={1} minWidth={width[2]} maxWidth={width[2]} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={bold} align='right'>Kâr</Typography>
                        </Box>
                        <Box minWidth={width[3]} maxWidth={width[3]} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={bold} align='right'>NPL Tutarı</Typography>
                        </Box>
                    </Box>
                    <Box display='flex'>
                        <Box borderRight={1} minWidth={width[1]} maxWidth={width[1]} height={30} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={regular} align='right' >{data.length > 0 ? toCurrency(data[0].plasmanItem.target) : ''}</Typography>
                        </Box>
                        <Box borderRight={1} minWidth={width[2]} maxWidth={width[2]} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={regular} align='right'>{data.length > 0 ? toCurrency(data[0].profitLossItem.target) : ''}</Typography>
                        </Box>
                        <Box minWidth={width[3]} maxWidth={width[3]} display='flex' flexDirection='column' justifyContent='center'>
                            <Typography sx={regular} align='right'>{data.length > 0 ? toCurrency(data[0].nplItem.target) : ''}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box marginTop={2}>
                <KTable headers={headers} data={data} rowKey='fullName' marginTop={0} noSearch noPagination isDense />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button variant='contained' onClick={() => {const i = counter + 1; setCounter(i);}}>YENİLE</Button>
            </Box>
        </Box>
    );
}

export default AchievementReport;