import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import KTable from '../Components/KTable';
import { toCurrency, toDecimal } from '../Lib/Constants';
import Download from '../Components/Download';
import processApi from '../Lib/ProcessApi';

const UserTeamSummary = (p) => {
    
    console.log('UserTeamSummary render');
    const [ data, setData ] = useState(null);
    const [ title, setTitle ] = useState(null);
    const [ counter, setCounter ] = useState(0);

    const headers = [
        {
            id: 'orderNo',
            align: 'center',
            label: 'Sıra',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'fullName',
            align: 'left',
            label: 'Adı',
            tooltip: 'userId',
            sortable: true,
            disablePadding: false
        },
        {
            id: 'plasman',
            align: 'right',
            label: 'Top.Plasman',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'profitLoss',
            align: 'right',
            label: 'Top.K/Z',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'nplamount',
            align: 'right',
            label: 'Top.NPL Tut.',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'kpi',
            align: 'right',
            label: 'KPI',
            disablePadding: false,
            sortable: true,
            convert: (d) => toDecimal(d, 2)
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            const d = await processApi('UserTeamSummary useEffect', p.checkApiMessage, p.setMessageKey, p.process, p.id);
            if (!d || d.messageKey !== 0) return;
            setTitle(d.title);
            setData(d.lines);
        };
        fetchData();
    }, [p, counter]);

    return (
        <Box sx={{ mt: 0 }}>
            <Typography component='span' variant='span' sx={{ color: 'secondary.main', fontWeight: 700 }}>{title && `(${title})`}</Typography>
            <Download id={p.id} reportName={p.reportName}/>
            <Box sx={{ mt:2, maxHeight: '600px', overflow: 'auto' }}>
                <KTable headers={headers} data={data} rowKey='fullName' marginTop={0} noSearch noPagination isDense />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button variant='contained' onClick={() => {const i = counter + 1; setCounter(i);}}>YENİLE</Button>
            </Box>
        </Box>
    );
};

export default UserTeamSummary;