import { Box, IconButton, List, ListItem, ListItemIcon, Typography, Tooltip, ListItemText } from '@mui/material'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DoneIcon from '@mui/icons-material/Done';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { getItemStyle } from '../Lib/Constants';


const CaseOptionList = (p) => {

    console.log('CaseOptionList render: ', p.values);
    const mtx = p.marginTop || 0;

    const onDragEnd = (result) => {
        if (!result.destination) return;

        const items = p.values;
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        p.setValues(items);
    };

    return (
        <Box sx={{ width: '100%', mt: mtx }} bgcolor={'ThreeDFace'}>
            <Box display='flex' bgcolor={'LightGrey'}>
                <Typography component='h6' variant='h6' sx={{ color: '#53a57d', fontWeight: 700, paddingLeft: 1 }}>{p.label}</Typography>
                <Box display='flex' justifyContent='flex-end' width='100%'>
                    <Tooltip title={p.newItemLabel} sx={{ alignContent: 'end' }}>
                        <IconButton onClick={p.onAddClick}>
                            <AddCircleIcon fontSize='small' color='primary' />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
            {p.values.length > 0 &&
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="options">
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                <List dense >
                                    {p.values.map((v, i) =>
                                        <Draggable key={v.id} draggableId={v.id.toString()} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}
                                                    ref={provided.innerRef} >
                                                    <ListItem key={i} disableGutters disablePadding dense secondaryAction={
                                                        <>
                                                            {!v.isDefault &&
                                                                <Tooltip title='Talep edilen olarak işaretle'>
                                                                    <IconButton edge='end' size='small' onClick={() => p.setDefault(p.values, p.setValues, i)}><CheckOutlinedIcon /></IconButton>
                                                                </Tooltip>
                                                            }
                                                            <Tooltip title='Sil'><IconButton edge='end' size='small' onClick={() => p.delete(p.values, p.setValues, i)}><DeleteIcon /></IconButton></Tooltip>
                                                            <Tooltip title='Güncelle'><IconButton edge='end' size='small' onClick={() => p.onUpdateClick(v, i)}><UpdateOutlinedIcon /></IconButton></Tooltip>
                                                        </>
                                                    } >
                                                        {v.isDefault &&
                                                            <Tooltip title='Talep Edilen'>
                                                                <ListItemIcon sx={{ minWidth: '0' }}><DoneIcon color='primary' /></ListItemIcon>
                                                            </Tooltip>
                                                        }
                                                        <ListItemText sx={{ paddingLeft: v.isDefault ? '0' : '24px', whiteSpace: 'preserve-breaks' }} primary={p.primary(v)} secondary={v.score + ' puan'} />
                                                    </ListItem>
                                                </div>
                                            )}
                                        </Draggable>
                                    )}
                                </List>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </Box>
    );
};

export default CaseOptionList;