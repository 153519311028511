import { useState } from "react";
import { Box, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from "@mui/material";
import { Draggable, Droppable } from 'react-beautiful-dnd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { getItemStyle } from '../Lib/Constants';


const UserSelectionFormSelectedList = (p) => {

    console.log('UserSelectionFormSelectedList render');

    const [open, setOpen] = useState(new Array(p.selectedUserList?.length ?? 1).fill(true));

    const handleClick = (teamId) => {
        const toggle = p.selectedTeamId === teamId;
        p.setSelectedTeamId(teamId);
        if (toggle) setOpen(open.map((v, i) => i === teamId ? !v : v));
    }
    const addNewTeam = () => {
        const t = [...open];
        t.push(true);
        setOpen(t);
        p.addTeam();
    }
    const removeTeam = () => {
        const t = [...open];
        t.splice(p.selectedTeamId, 1);
        setOpen(t);
        p.removeTeam();
    }
    const removeUser = (v, i) => {
        const tempAll = [...p.selectedUserList]
        tempAll[v.tid].splice(i, 1);
        p.removeUser(tempAll);
    }


    return (
        <Box backgroundColor='lightGrey' mt='0' width='100%'>
            <Box display='flex' alignItems='center' >
                <Typography component='h6' sx={{ fontSize: '14px', fontWeight: 700, paddingLeft: 1, textWrap: 'nowrap' }}>Seçilmiş Kullanıcılar</Typography>
                <Box display='flex' justifyContent='flex-end' width='100%' >
                    {p.selectedUserList[p.selectedTeamId].length === 0 && open.length > 1 &&
                        <IconButton onClick={removeTeam} > <Tooltip title={"SİL: Kredi Komitesi " + (p.selectedTeamId + 1).toString()}><DeleteIcon color='primary' /></Tooltip></IconButton>
                    }
                    <IconButton onClick={addNewTeam}> <Tooltip title={'Yeni Komite Ekle'}><AddCircleIcon color='primary' /></Tooltip> </IconButton>
                </Box>
            </Box>
            <Droppable droppableId='team' type='team'>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        <Paper style={{ maxHeight: '725px', overflowY: 'auto', overflowX: 'hidden', backgroundColor: 'ThreeDFace' }}>
                            {p.selectedUserList && (() => {
                                let userIndex = 0; return (p.selectedUserList.map((team, teamId) =>
                                    <Droppable droppableId={'user-' + teamId.toString()} key={'user-' + teamId.toString()} type={'user'}>
                                        {(provided) => (
                                            <div ref={provided.innerRef} {...provided.droppableProps}>
                                                <List key={'teamList-' + teamId} dense >
                                                    <Draggable key={'teamDrag-' + teamId.toString()} draggableId={'teamDrag-' + teamId.toString()} index={teamId}>
                                                        {(provided, snapshot) => (
                                                            <div {...provided.draggableProps} {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                ref={provided.innerRef} >
                                                                <ListItemButton selected={p.selectedTeamId === teamId} onClick={() => handleClick(teamId)}>
                                                                    <ListItemIcon><Diversity3Icon /></ListItemIcon>
                                                                    <ListItemText primary={<Typography style={{ fontWeight: '700' }}>{'Kredi Komitesi ' + (teamId + 1).toString() + (p.selectedTeamId === teamId ? ' ****' : '')}</Typography>} />
                                                                    {open[teamId] ? <ExpandLess /> : <ExpandMore />}
                                                                </ListItemButton>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                    {team &&
                                                        <Collapse in={open[teamId]} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {team && team.map((v, i) =>
                                                                    <Draggable key={'user-' + v.userId} draggableId={'user-' + v.userId} index={userIndex++}>
                                                                        {(provided, snapshot) => (
                                                                            <div {...provided.draggableProps} {...provided.dragHandleProps}
                                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                ref={provided.innerRef} >
                                                                                <ListItem key={'user-' + v.userId} disableGutters disablePadding dense sx={{ pl: '8px' }}
                                                                                    secondaryAction={<Tooltip title={'Sil - ' + v.userId}><IconButton edge='end' size='small' onClick={() => removeUser(v, i)}><DeleteIcon color='primary' /></IconButton></Tooltip>}>
                                                                                    {v.is &&
                                                                                        <Tooltip title='Kredi Komitesi Başkanı'>
                                                                                            <ListItemIcon sx={{ minWidth: '0' }}><DoneIcon color='primary' /></ListItemIcon>
                                                                                        </Tooltip>
                                                                                    }
                                                                                    <ListItemButton><Tooltip title={v.userId}><ListItemText sx={{ paddingLeft: v.is ? '0' : '24px' }} primary={v.fullName} /></Tooltip></ListItemButton>
                                                                                </ListItem>
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )}
                                                            </List>
                                                        </Collapse>
                                                    }
                                                </List>
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                ))
                            })()}
                        </Paper>
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </Box>
    );
}

export default UserSelectionFormSelectedList;