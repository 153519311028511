import { useState } from 'react';
import { TextField } from '@mui/material';

const EMailInput = (p) => {

    console.log('EMailInput render');

    const [userIdErrorState, setUserIdErrorState] = useState(false);
    const handleUserId = (e) => {
        const isValid = e.target.value && !!e.target.value.match('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
        p.onChange(isValid, e.target.value);
        setUserIdErrorState(!isValid);
    };

    return (
        <TextField 
            required fullWidth
            id='userId'
            name='userId'
            margin='normal'
            autoComplete='userId'
            autoFocus={p.autoFocus}
            label={p.label}
            defaultValue={p.value}
            error ={userIdErrorState}
            helperText={userIdErrorState && 'Geçerli bir e-posta adresi girmelisiniz'}
            onChange={handleUserId}
        />
    );
};

export default EMailInput;