import { useEffect, useState } from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import GameManagerCaseFollowup from './GameManagerCaseFollowup.jsx';
import PopupMessage from '../Components/PopupMessage';
import styles from '../Styles/styles.module.css'
import { apiStartCase, apiPauseCase, apiStopCase } from '../Lib/Api.js';
import { displayReportInNewTab, getMessage, isMessageKeyValid, NAV_ITEMS } from '../Lib/Constants.js';
import processApi from '../Lib/ProcessApi.js';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const GameManagerControl = (p) => {

    console.log('GameManagerControl render');
    const [ messageKey, setMessageKey ] = useState(0);
    const [ data, setData ] = useState(p.data);

    const playCase = async () => await doCase('GameManagerControl playCase', apiStartCase);
    const pauseCase = async () => await doCase('GameManagerControl pauseCase', apiPauseCase);
    const stopCase = async () => await doCase('GameManagerControl stopCase', apiStopCase, false);
    const doCase = async (t, f, isLocal = true) => { 
        const rtn = await processApi(t, p.checkApiMessage, setMessageKey, f, {id: data.trainingId, caseId: data.displayedCase.id, caseIndex: data.displayedCaseIndex});
        if (!rtn || rtn.messageKey == null) return;
        const msg = getMessage(rtn.messageKey);
        if (msg.severity !== 'success') return;
        setData(rtn);
        if (!isLocal) p.setData(rtn);
    };    
    

    useEffect(() => setData(p.data), [p.data]);

    return (
        <Box sx={{ mt: 0 }} >
            <Box display='flex' width='100%' justifyContent='space-between'>
                <Box display='flex'>
                <Typography variant='span' sx={{ fontSize: '24px', color: 'black', fontWeight: 700, paddingTop: '9px' }} >{data.displayedCase.title}</Typography>
                { (data.isToPlay || data.isToPause || data.isToStop) &&
                    <Box display='flex'>
                        {data.isToPlay && <Tooltip title='PLAY'><IconButton color='primary' onClick={playCase}><PlayCircleOutlineOutlinedIcon className={styles.mediumIcon}/></IconButton></Tooltip>}
                        {data.isToPause && <Tooltip title='PAUSE'><IconButton color='primary' onClick={pauseCase}><PauseCircleOutlinedIcon className={styles.mediumIcon}/></IconButton></Tooltip>}
                        {data.isToStop && <Tooltip title='STOP'><IconButton color='primary' onClick={stopCase}><StopCircleOutlinedIcon className={styles.mediumIcon}/></IconButton></Tooltip>}
                    </Box>
                }
                </Box>
                <Box marginRight={1}>
                    <Tooltip title='Kullanıcı-Komite Takip'>
                        <Button variant='contained' onClick={() => displayReportInNewTab(NAV_ITEMS.FOLLOW_UP_ADMIN)} startIcon={<ContentPasteSearchIcon />} sx={{my:'4px'}}>KULLANICI-KOMİTE TAKİP</Button>
                    </Tooltip>
                </Box>
            </Box>
            <GameManagerCaseFollowup 
                tid={data.trainingId} 
                cid={data.displayedCase.id} 
                isToRefresh={data.isToPause} 
                checkApiMessage={p.checkApiMessage} 
                setMessageKey={setMessageKey}/>
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Box>
    );
};

export default GameManagerControl;