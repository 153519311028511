import { useEffect, useState } from "react";
import { Box, Card, CardContent, List, ListItem, ListItemText, Typography } from "@mui/material";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { toTeamName } from "../Lib/Constants";
import { apiGetClassList } from "../Lib/Api";
import processApi from '../Lib/ProcessApi';


const ClassList = (p) => {

    console.log('ClassList render: ');

    const [ userList, setUserList ] = useState([[]]);

    useEffect( () => {
        const fetchData = async () => {
            const d = await processApi('GamePlayerInitialState useEffect', p.checkApiMessage, p.setMessageKey, apiGetClassList, {id: p.trainingId});
            if (!d || d.messageKey !== 0 || !d.userList) return;
            setUserList(d.userList);
        };
        fetchData();
    }, []);

    return (
        <Box display='flex' flexWrap='wrap' justifyContent='space-evenly' marginTop={3} width='100%'>
            {userList.length > 0 && userList.map((team, teamId) =>
                    <Card key={`teamCard-${teamId}`} sx={{ width: 250, minWidth: 250, marginBottom: 2 }} elevation={12} margin={1}>
                        <CardContent>
                            <Box display='flex' alignItems='start'>
                                <Diversity3Icon />
                                <Typography component='h6' variant='h6' sx={{ color: 'purple', fontWeight: 700, textAlign: 'center', paddingLeft: 1}}>
                                    {toTeamName(teamId)}
                                </Typography>  
                            </Box>
                            {team && team.length > 0 && 
                                <List key={`teamList-${teamId}`} disablePadding>
                                    {team.map( (user, i) =>
                                        <ListItem  key={`user-${i}`} disableGutters dense><ListItemText primary={user.fullName} /></ListItem>
                                    )}
                                </List>
                            }
                        </CardContent>
                    </Card> 
            )}
        </Box>
    );
}

export default ClassList;
