import { useEffect, useRef, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { apiAddMarket, apiUpdateMarket, apiGetAllMarkets, apiGetMarket } from '../Lib/Api';
import { displayFileInNewTab, getBool, isMessageKeyValid, MESSAGES } from '../Lib/Constants';
import processApi from '../Lib/ProcessApi'
import IsActive from '../Components/IsActive';
import KModal from "../Components/KModal";
import KTable from "../Components/KTable";
import Loading from "../Components/Loading";
import PopupMessage from "../Components/PopupMessage";
import TripleSwitch from '../Components/TripleSwitch';
import MarketForm from '../Forms/MarketForm';

const href = process.env.REACT_APP_BASE_URL + 'api/market/Piyasa Bilgileri?id=';

const MarketDefinition = (p) => {

    console.log('MarketDefinition render');

    const [loading, setLoading] = useState(false);
    const [messageKey, setMessageKey] = useState(0);
    const [data, setData] = useState(null);
    const [activeFilter, setActiveFilter] = useState('null');
    const [openModal, setOpenModal] = useState(false);
    const [refresh, setRefresh] = useState(0);

    let modalTitle = useRef(null);
    let formProcessButtonTitle = useRef(null);
    let formProcess = useRef(null);
    let formData = useRef(null);

    const onAddClick = () => {
        modalTitle.current = 'Yeni Piyasa Tanımı';
        formProcessButtonTitle.current = 'Sakla';
        formProcess.current = processAdd;
        formData.current = null;
        setOpenModal(true);
    }

    const onUpdateClick = async (id) => {
        const d = await processApi('MarketDefinition onUpdateClick', p.checkApiMessage, setMessageKey, apiGetMarket, id);
        if (!d) return;
        modalTitle.current = 'Piyasa Bilgileri Güncelleme';
        formProcessButtonTitle.current = 'Güncelle';
        formProcess.current = processUpdate;
        formData.current = d;
        setOpenModal(true);
    }

    const headers = [
        {
            id: 'title',
            align: 'left',
            label: 'Piyasa Adı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'isActive',
            align: 'center',
            label: 'Aktif?',
            disablePadding: true,
            sortable: true,
            convert: (d) => <IsActive isActive={d} />
        },
        {
            id: 'id',
            align: 'right',
            label: '',
            disablePadding: true,
            sortable: false,
            convert: (id) =>
                <Box>
                    <Tooltip title={'Güncelle'}>
                        {<IconButton onClick={async () => await onUpdateClick(id)}><UpdateOutlinedIcon fontSize='small' color='primary' /></IconButton>}
                    </Tooltip>
                    <Tooltip title={'Dosya görüntüle'}>
                        <IconButton onClick={() => displayFileInNewTab(href+id)}><VisibilityOutlinedIcon fontSize='small' color='primary' /></IconButton>
                    </Tooltip>
                </Box>
        }
    ];


    const processAdd = async (d) => await process('processAdd', MESSAGES.CREATED, apiAddMarket, d);
    const processUpdate = async (d) => await process('processUpdate', MESSAGES.UPDATED, apiUpdateMarket, d);

    const process = async (t, m, f, d) => {
        const r = await processApi('MarketDefinition ' + t, p.checkApiMessage, setMessageKey, f, d);
        if (!r) return;
        setOpenModal(false);
        setMessageKey(m);
        setRefresh(refresh + 1);
    };

    const closeModal = () => setOpenModal(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const d = await apiGetAllMarkets({ activeFilter: getBool(activeFilter) });
            if (p.checkApiMessage(d)) return;
            setData(d);
            setLoading(false);
        };
        fetchData();
    }, [p, refresh, activeFilter]);

    return (
        <Grid component="main" maxWidth='sm' justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' sx={{ color: '#53a57d', fontWeight: 700 }}>Piyasa Tanımları</Typography>
            <Box display='block' width={{ xs: '100%', sm: '50%' }} mx={2} my={2} justifyContent={'space-evenly'}>
                <Box width='100%' display='flex' justifyContent='center' >
                    <TripleSwitch
                        value={activeFilter}
                        setValue={setActiveFilter}
                        labels={['Aktif', 'Hepsi', 'Pasif']}
                        values={['true', 'null', 'false']}
                        width={200} height={20} size={12} />
                </Box>
            </Box>
            <KTable headers={headers} data={data} marginTop={{ xs: 0, sm: -7 }} initialRowsPerPage={10} orderBy='title' onAddClick={onAddClick} isDense />
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
            {loading &&
                <Loading loading={loading} />}
            {openModal &&
                <KModal title={modalTitle.current} open={openModal} handleClose={closeModal} width='500px'>
                    <MarketForm processButtonTitle={formProcessButtonTitle.current} process={formProcess.current} data={formData.current} handleClose={closeModal} />
                </KModal>
            }
        </Grid>
    );
};

export default MarketDefinition;