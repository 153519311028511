import { useState } from 'react';
import { Box, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { displayFileInNewTab } from '../Lib/Constants'; 


const transparentInputTheme = createTheme({
    palette: {
        primary: {
            main: '#53a57d',
            contrastText: '#fff'
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    opacity: 0,
                }
            },
        },
    },
});

const UploadInput = (p) => {

    console.log('UploadInput render');

    let [ isFileSelected, setIsFileSelected ] = useState(false);
    
    const id = p.id ? p.id : 'uploadFile'
    const href = process.env.REACT_APP_BASE_URL + p.displayUrl + p.id;

    const handleFileChanged = (e) => {
        const i = e.target.value.lastIndexOf('\\') + 1;
        setIsFileSelected(i > 0);
        document.getElementById('typo').innerText = e.target.value.substring(i);
        p.onChange(true, e.target);
    }

    return (
        <FormControl fullWidth variant='outlined' sx={{ marginTop: '4px', marginBottom: '4px', position: 'relative', display: 'inline-block' }} >
            <InputLabel htmlFor={id}>{p.label}</InputLabel>
            <ThemeProvider theme={transparentInputTheme}>
                <Tooltip title={p.tooltip}>
                    <Box >
                        <Typography id='typo' name='typo' zIndex={2} position='absolute' margin={2} />
                        <OutlinedInput
                            required fullWidth
                            type={p.readOnly ? '' : 'file'}
                            id={id}
                            autoFocus={!!p.autoFocus}
                            label={p.label}
                            disabled={p.readOnly}
                            readOnly={p.readOnly}
                            inputProps={{ accept: p.fileFilter }}
                            onChange={handleFileChanged}
                            endAdornment={
                                <InputAdornment position='end'>
                                    { !!p.fileName && !isFileSelected &&
                                        <IconButton onClick={() => displayFileInNewTab(href)}><VisibilityOutlinedIcon fontSize='small' color='primary' /></IconButton>
                                    }
                                    {!p.readOnly &&
                                        <label htmlFor={id} opacity={0}>
                                            <IconButton variant="contained" color="primary" component="span">
                                                <CloudUploadOutlinedIcon fontSize='large' color='primary' />
                                            </IconButton>
                                        </label>
                                    }
                                </InputAdornment>
                            }
                        />
                    </Box>
                </Tooltip>
            </ThemeProvider>
        </FormControl>
    );
};

export default UploadInput;