import { useEffect, useRef, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import PopupMessage from '../Components/PopupMessage';
import { apiGetLastPlayerState, apiWelcomePlayer } from "../Lib/Api";
import { isMessageKeyValid } from '../Lib/Constants.js';
import processApi from "../Lib/ProcessApi";


const WelcomeUser = (p) => {

    console.log('WelcomeUser render');
    const [ trainings, setTrainings ] = useState([]);
    const [ messageKey, setMessageKey ] = useState(0);

    const startSim = async (trainingId) => {
        const rtn = await processApi('WelcomeUser startSim', p.checkApiMessage, setMessageKey, apiGetLastPlayerState, {id: trainingId, counter: -1, caseIndex: -1});
        if (!rtn || rtn.messageKey !== 0) return;
        p.startSim(rtn);
    };


    const duration = process.env.REACT_APP_USER_REFRESH_TIME ?? 3000;
    let ignore = useRef(false);
    let preData = useRef(0);

    useEffect(() => {
        const refresh = async () => {
            if (ignore.current) 
                return;
            ignore.current = true;
            const d = await apiWelcomePlayer();
            ignore.current = false;
            if (!d || d.errors || d.traceId || p.checkApiMessage(d) || d.messageKey !== 0) return;
            if (preData.current == d.trainings.length)
                return;
            preData.current = d.trainings.length;
            setTrainings(d.trainings);
        };
        let interval;
        refresh();
        //if (trainings.length === 0)
            interval = setInterval(refresh, duration);
        return () => clearInterval(interval);
    }, [p, p.checkApiMessage, trainings.length, duration]);

    return (
        <Box component="main"  sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>Kepsim24</Typography>
            <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>Kredi Simülasyon Uygulamasına</Typography>
            <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>Hoşgeldiniz</Typography>
            {trainings.length === 0 &&
                <Typography component='h6' variant='h6' marginTop={3} sx={{ color: 'purple', fontWeight: 700, textAlign: 'center' }}>Kayıtlı olduğunuz eğitimler aktif olduğında burada görebilirsiniz.</Typography>
            }
            <Box display='flex' justifyContent='space-evenly' marginTop={3} width='100%' >
                {trainings.map((t) =>
                        <Card key={t.key} sx={{ width: 200 }} elevation={12}>
                            <CardContent>
                                <Typography component='h6' variant='h6' marginTop={3} sx={{ color: 'purple', fontWeight: 700, textAlign: 'center' }}>
                                    {t.value}
                                </Typography>                                
                            </CardContent>
                            <CardActions>
                                <Button color='primary' onClick={() => startSim(t.key)}>GİRİŞ</Button>
                            </CardActions>
                        </Card> 
                )}
            </Box>
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Box>
    );
};

export default WelcomeUser;