import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Download from '../Components/Download';
import { apiReportFollowup } from '../Lib/Api';
import { maturityTypeToString, toCurrency } from '../Lib/Constants';
import processApi from '../Lib/ProcessApi';

const UserFollowupReport = (p) => {

    console.log('UserFollowupReport render: ');
    const [ data, setData ] = useState(null);
    const [ counter, setCounter ] = useState(0);
    const [ expanded, setExpanded]  = useState(false);

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&::before': {
            display: 'none',
        },
    }));
    
    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor: '#53a57d',
        flexDirection: 'row-reverse', minHeight: 22, paddingRight: 8,
        '& .MuiAccordionSummary-expandIconWrapper': {
        },
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)'
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        }
    }));
    
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: '0 0 0 16px'
    }));


    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
      };

    const headers = [
        {
            id: 'fullName',
            subRowId: 'title',
            align: 'left',
            label: 'Olay',
            tooltip: 'userId',
            width: 250,
            sortable: false
        },
        {
            id: 'decision',
            flag: 'result',
            align: 'left',
            label: 'Cevap',
            width: 150,
            sortable: false,
            convert: (d, r) => r == null ? '' : r === 0 ? 'Henüz Cevaplamadı' : d ? 'Onayladı' : 'Reddetti'
        },
        {
            id: 'result',
            align: 'left',
            label: 'Sonuç',
            width: 150,
            sortable: false,
            convert: (d) => d == null ? '' : d === 0 ? 'Henüz Cevaplamadı' : d === 1 ? 'Kullandırdı' : d === 2 ? 'Kullandıramadı' : d === 3 ? 'Reddetti' : '-'
        },
        {
            id: 'limit',
            align: 'right',
            label: 'Limit',
            width: 100,
            sortable: false,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'maturity',
            flag: 'maturityType',
            align: 'right',
            label: 'Vade',
            width: 120,
            sortable: false,
            convert: (d, t) => toCurrency(d) + maturityTypeToString(t)
        },
        {
            id: 'collateral',
            title: 'collateral',
            align: 'left',
            label: 'Teminat',
            width: 190,
            fixHeader: 'Toplam',
            sortable: false,
            convert: (d) => !d ? '' : d.length <= 27 ? d : (d.slice(0, 27) + '..')
        },
        {
            id: 'plasman',
            subRowId: 'plasman',
            align: 'right',
            label: 'Plasman',
            width: 100,
            headerBox: true,
            sortable: false,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'profitLoss',
            subRowId: 'profitLoss',
            align: 'right',
            label: 'Kâr / Zarar',
            width: 100,
            headerBox: true,
            sortable: false,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'nplamount',
            subRowId: 'nplamount',
            align: 'right',
            label: 'NPL Tutarı',
            width: 100,
            headerBox: true,
            sortable: false,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'nplRate',
            align: 'right',
            label: 'NPL %',
            width: 65,
            headerBox: true,
            sortable: false,
            convert: (d) => toCurrency(d)
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            const d = await processApi('UserFollowupReport useEffect', p.checkApiMessage, p.setMessageKey, apiReportFollowup, p.id);
            if (!d || d.messageKey !== 0) return;
            setData(d);
            if (d?.userFollowUpList?.length > 0) setExpanded(d.userFollowUpList[0].fullName);
        };
        fetchData();
    }, [p, counter]);

    return (
        <Box sx={{ minWidth: 1350, maxWidth:1350 }}>
            <Typography component='span' variant='span' sx={{ color: 'secondary.main', fontWeight: 700 }}>{data && `(${data.title})`}</Typography>
            <Download id={p.id} reportName='followup'/>
            {data &&
                <Box sx={{ mt:3, maxHeight: '800px', overflow: 'auto' }}>
                    {data.userFollowUpList.map( (row) => 
                        <Accordion expanded={expanded === row.fullName} onChange={handleChange(row.fullName)} key={`acd-${row.fullName}`} >
                            <AccordionSummary  >
                                <Box display='flex' borderRight={1}>
                                    {headers.map( (h) =>
                                            <Box borderTop={h.headerBox || h.fixHeader ? 1 : 0} borderBottom={h.headerBox || h.fixHeader ? 1 : 0} borderLeft={h.headerBox || h.fixHeader ? 1 : 0} minWidth={h.width} maxWidth={h.width} key={`acsbh-${h.id}`}>
                                                <Typography sx={{fontSize: 12, fontWeight: 700, paddingX: 1 }} align={h.fixHeader ? 'right' : h.align}>
                                                    {h.fixHeader ? h.fixHeader : h.convert ? h.convert(row[h.id]) : row[h.id]}
                                                </Typography>
                                            </Box>
                                    )}
                                </Box>  
                            </AccordionSummary>
                            <AccordionDetails key={`acdd-${row.fullName}`} >
                                <Box paddingLeft='6px' paddingRight={0} borderRight={1}>
                                    <Box display='flex' key={`acdd-${row.fullName}`}>
                                        {headers.map( (h) =>
                                            <Box borderLeft={h.headerBox ? 1 : 0} minWidth={h.width} maxWidth={h.width} key={`acdbh-${h.id}`}>
                                                <Typography sx={{fontSize: 12, fontWeight: 700, paddingX: 1}} align={h.align}>{h.label}</Typography>
                                            </Box>                                    
                                        )}
                                    </Box>
                                    {row.lines.map( (line, i) =>
                                        <Box display='flex' backgroundColor={i % 2 === 1 ? 'white' : 'ghostWhite'} key={`acddb-${line[headers[0].subRowId || headers[0].id]}`}>
                                            {headers.map( (h) =>
                                                <Box borderLeft={h.headerBox ? 1 : 0} minWidth={h.width} maxWidth={h.width} key={`acdbhb-${h.id}`} >
                                                    <Typography sx={{fontSize: 12, paddingX: 1}} align={h.align}>{h.convert ? h.convert(line[h.subRowId || h.id], line[h.flag]) : line[h.subRowId || h.id]}</Typography>
                                                </Box>                                    
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )}
                </Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button variant='contained' onClick={() => {const i = counter + 1; setCounter(i);}}>YENİLE</Button>
            </Box>
        </Box>
    );
}


export default UserFollowupReport;