import { Box, Button, Grid, Tooltip } from '@mui/material';
import Display from '../Components/Display';
import DualSwitch from '../Components/DualSwitch';
import CaseOptionListDisplay from './CaseOptionListDisplay';
import { displayFileInNewTab, maturityTypeToString, toCurrency } from '../Lib/Constants';
import BalanceIcon from '@mui/icons-material/Balance';

const CaseDisplay = (p) => {

    console.log('CaseDisplay render: ', p.data);

    return (
        <Box sx={{ mt: 0 }}>
            <Box>
                {/* <UploadInput label='Firma Faaliyetleri / Mali Veriler' tooltip='Firma Faaliyetleri / Mali Veriler' fileName={p.data?.fileName} id={p.data?.id} displayUrl='api/case/Firma Faaliyetleri - Mali Veriler?id=' sx={{ mt: 0}} readOnly/> */}
                <Box display='flex' justifyContent='space-evenly' margin={1} >  
                    <Tooltip title='Firma Faaliyetleri - Mali Veriler'>
                        <Button 
                            variant='contained' 
                            onClick={() => displayFileInNewTab('/api/case/Firma Faaliyetleri - Mali Veriler?id=' + p.data.id)}
                            startIcon={<BalanceIcon />} 
                            sx={{maxWidth:'175px', my:'4px'}}>FİRMA BİLGİLERİ</Button>
                    </Tooltip>
                </Box>

                {/* <StringInput label='Talep Nedeni' id='desc' value={p.data.desc} multiline rows={2} readOnly/> */}
                <Display label='Talep Nedeni' value={p.data.desc} />
                {/* <AmountInput label='Kâr / Zarar *' id='profitLoss' value={p.data.profitLoss} readOnly/> */}
                
                <Grid container spacing={2}>
                    <Grid item xs={6}><Display label='Kâr / Zarar *' value={p.data.profitLoss} suffix='TL' number/></Grid>
                    <Grid item xs={6}>
                        <Box display='flex' paddingTop={1} alignItems='center'>
                            <DualSwitch value={!p.data || p.data.isPaidBack} labelTrue='Ödendi' labelFalse='Ödenmedi' width={180} height={24} size={15} disabled/>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <CaseOptionListDisplay label='Limitler'
                            values={p.data && p.data.limits ? JSON.parse(p.data.limits) : []}
                            primary={(v) => toCurrency(v.limit) + ' TL'} />
                    </Grid>
                    <Grid item xs={4}>
                        <CaseOptionListDisplay label='Vadeler'
                            values={p.data && p.data.maturities ? JSON.parse(p.data.maturities) : []}
                            primary={(v) => toCurrency(v.maturity) + maturityTypeToString(v.mtype) } />
                    </Grid>
                    <Grid item xs={4}>
                        <CaseOptionListDisplay label='Teminatlar'
                            values={p.data && p.data.collaterals ? JSON.parse(p.data.collaterals) : []} 
                            primary={(v) => v.collateral} />
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button variant="contained" onClick={p.handleClose}>ÇIKIŞ</Button>
            </Box>
        </Box>
    )
    ;
}

export default CaseDisplay;