import { useState } from 'react';
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = (p) => {

    console.log('PasswordInput render: ', p.autoFocus);

    const id = p.id ? p.id : 'password'

    const [pwdErrorState, setPwdErrorState] = useState(false);
    const handlePassword = (e) => {
        const isValid = e.target.value && e.target.value.length >= 6
        p.onChange(isValid, e.target.value);
        setPwdErrorState(!isValid);
    };


    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => { setShowPassword(!showPassword); }
    const handleMouseDownPassword = (e) => { e.preventDefault(); }


    return (
        // <TextField 
        //     required fullWidth 
        //     id={id}  
        //     name={id}
        //     type='password'
        //     margin='normal' 
        //     autoComplete='current-password'
        //     autoFocus={p.autoFocus}
        //     label={p.label}
        //     error ={pwdErrorState}
        //     helperText={pwdErrorState && 'Şifre için en az 6 karakter girmelisiniz'}
        //     onChange={handlePassword}
        // />
        <FormControl fullWidth variant='outlined' sx={{ marginTop: 1, marginBottom: 1 }} >
            <InputLabel htmlFor={id}>{p.label}</InputLabel>
            <OutlinedInput
                required fullWidth
                id={id}
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                autoFocus={!!p.autoFocus}
                label={p.label}
                error={pwdErrorState}
                onChange={handlePassword}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            aria-label='toggle password visibility'
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword ? <VisibilityOff color='primary'/> : <Visibility color='primary'/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {pwdErrorState && 
                <FormHelperText error id="accountId-error">Şifre için en az 6 karakter girmelisiniz</FormHelperText>
            }
        </FormControl>
    );
};

export default PasswordInput;