import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import KTable from '../Components/KTable';
import PopupMessage from '../Components/PopupMessage';
import TripleSwitch from '../Components/TripleSwitch';
import { apiGetAllTrainings } from '../Lib/Api';
import { dateToReadableString, getBool, isMessageKeyValid } from '../Lib/Constants';
import ListAltIcon from '@mui/icons-material/ListAlt';

const ReportWelcomeAdmin = (p) => {

    console.log('ReportWelcomeAdmin render');

    const [ messageKey, setMessageKey ] = useState(0);
    const [ data, setData ] = useState(null);
    const [ reportForm, setReportForm ] = useState(null);
    const [ activeFilter, setActiveFilter ] = useState('true');

    const headers = [
        {
            id: 'title',
            align: 'left',
            label: 'Eğitimin Adı',
            sortable: true,
            disablePadding: false,
        },
        {
            id: 'marketTitle',
            align: 'left',
            label: 'Piyasa',
            sortable: true,
            disablePadding: true
        },
        {
            id: 'bankTitle',
            align: 'left',
            label: 'Banka',
            sortable: true,
            disablePadding: true
        },
        {
            id: 'startDate',
            align: 'center',
            label: 'Başlama Tarihi',
            sortable: true,
            disablePadding: true,
            convert: (d) => dateToReadableString(d)
        },
        {
            id: 'endDate',
            align: 'center',
            label: 'Bitiş Tarihi',
            sortable: true,
            disablePadding: true,
            convert: (d) => dateToReadableString(d)
        },
        {
            id: 'id',
            align: 'right',
            label: '',
            sortable: false,
            disablePadding: true,
            convert: (id) =>
                <Box display='flex'>
                    <Tooltip title={p.tooltip || p.title}>
                        {<IconButton onClick={() => setReportForm(p.form(id, setMessageKey))}><ListAltIcon fontSize='small' color='primary' /></IconButton>}
                    </Tooltip>
                </Box>
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            // const d = await processApi('ReportWelcomeAdmin useEffect', p.checkApiMessage, setMessageKey, apiReportWelcomeAdmin);
            // if (!d || d.messageKey !== 0) return;
            // if (d.trainings.length === 1) setReportForm(p.form(d.trainings[0].id, setMessageKey));
            // else setData(d.trainings);
            const d = await apiGetAllTrainings({ activeFilter: getBool(activeFilter) });
            if (p.checkApiMessage(d)) return;
            if (d.length === 1) setReportForm(p.form(d[0].id, setMessageKey));
            else setData(d);
        };
        fetchData();
    }, [p, p.checkApiMessage, setReportForm, activeFilter]);

    return (
        <Grid component='main' maxWidth={data && !reportForm ? 'md' : p.maxWidth} justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
            <Typography component='h5' variant='h5' sx={{ color: '#53a57d', fontWeight: 700 }}>{p.title}</Typography>
            {data && !reportForm &&
                <Box>
                    <Box display='block' width={{ xs: '100%', sm: '50%' }} mx={2} my={2} justifyContent={'space-evenly'}>
                        <Box width='100%' display='flex' justifyContent='center' >
                            <TripleSwitch
                                value={activeFilter}
                                setValue={setActiveFilter}
                                labels={['Aktif', 'Hepsi', 'Pasif']}
                                values={['true', 'null', 'false']}
                                width={200} height={20} size={12} />
                        </Box>
                    </Box>
                    <KTable headers={headers} data={data} marginTop={{ xs: 0, sm: -7 }} orderBy='title' isDense />
                </Box>
            }
            {
                reportForm
            }
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Grid>
    );
};

export default ReportWelcomeAdmin;