import { useRef, useState } from 'react';
import { Box, Button, Paper, Step, StepButton, Stepper, Typography } from "@mui/material";
import ClassList from './ClassList';
import KModal from '../Components/KModal';


const GameManagerNav = (p) => {
    
    console.log('GameManagerNav render: ', p.index);    

    const [ openModal, setOpenModal ] = useState(false);
    let modal = useRef(null);

    const displayClassList = async () => {
        modal.current = {
            title: 'Sınıf Listesi',
            form: <ClassList trainingId={p.trainingId} checkApiMessage={p.checkApiMessage} setMessageKey={p.setMessageKey}/>,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);

    return (

        <Paper elevation={18} sx={{ padding: 2}} >
            <Box display='flex' width='100%' justifyContent='space-between'>
                <Typography component='h5' variant='h5' sx={{ color: '#53a57d', fontWeight: 700, whiteSpace: 'nowrap', paddingBottom: 1 }} >{p.title}</Typography>
                <Button onClick={displayClassList}>SINIF LİSTESİ</Button>
            </Box>
            <Stepper nonLinear activeStep={p.index}>
                {p.caseList.map( (item, index) => 

                    <Step key={item.key} completed={p.completedCaseIds.includes(item.key)}>
                        <StepButton onClick={() => p.setIndex(index)}>{item.value}</StepButton>
                    </Step>
                )}
            </Stepper>
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Paper>
    );
};

export default GameManagerNav;