import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import AmountInput from '../Components/AmountInput';
import KSelect from '../Components/KSelect';
import { maxId, stripSeperators } from '../Lib/Constants';

const regex3= /^(0|[1-9][0-9]{0,2})$/;
const regex4 = /^(0|[1-9][0-9/.]{0,4})$/;
const select = [{ key:0, value:'Gün'}, { key:1, value:'Ay'}, { key:2, value:'Yıl'}, ];

const CaseMaturityForm = (p) => {

    console.log('CaseMaturityForm render ', p);

    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);
    const [ mtype, setMType ] = useState(p.data && p.data.mtype ? p.data.mtype : 0);

    let ref0IsValid = useRef(!!p.data);     // maturity
    let ref1IsValid = useRef(!!p.data);     // score
    let ref0Value = useRef(p.data && p.data.maturity);
    let ref1Value = useRef(p.data && p.data.score);
    const handleInputChange = (x, i, v) => {
        if (x === 0) {ref0IsValid.current = i; ref0Value.current = v;}
        else if (x === 1) {ref1IsValid.current = i; ref1Value.current = v;}
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current);
    }; 

    const process = (e) => {
        e.preventDefault();
        const data = {
            id: p.data ? p.data.id : maxId(p.values),
            maturity: Number(stripSeperators(ref0Value.current)),
            mtype: mtype,
            score: Number(stripSeperators(ref1Value.current)),
            isDefault: p.data ? p.data.isDefault : p.seqNo === 0 
        };
        p.process(data, p.setValues, p.values, p.seqNo);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 0 }}>
            <Box>
                <Box display='flex'>
                    <AmountInput label='Vade' id='maturity' value={ref0Value.current} onChange={(i, v) => handleInputChange(0, i, v)} suffix=' ' regex={regex4} errorMsg='0-9999 arası bir rakam girmelisiniz' />
                    <KSelect label='*' id='mtype' value={mtype} setValue={setMType} data={select} width='100px' marginTop='4px'/>
                </Box>
                <AmountInput label='Puan' id='score' value={ref1Value.current} onChange={(i, v) => handleInputChange(1, i, v)} suffix='puan' regex={regex3} errorMsg='0-999 arası bir rakam girmelisiniz' />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    );
};

export default CaseMaturityForm;