import { useRef, useState, useEffect } from "react";
import { Box, Grid, Hidden } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import PopupMessage from '../Components/PopupMessage';
import GamePlayerCaseDetails from "../Forms/GamePlayerCaseDetails";
import GamePlayerCaseFollowup from "../Forms/GamePlayerCaseFollowup";
import GamePlayerInitialState from "../Forms/GamePlayerInitialState";
import GamePlayerNav from "../Forms/GamePlayerNav";
import GamePlayerRuntimeSummary from "../Forms/GamePlayerRuntimeSummary";
import { isMessageKeyValid, toTeamName } from '../Lib/Constants';
import { apiGetLastPlayerState } from "../Lib/Api";
import processApi from "../Lib/ProcessApi";
import styles from '../Styles/styles.module.css';

const gameManagerTheme = createTheme({
    palette: {
        primary: {
            main: '#53a57d',
            contrastText: '#fff'
        }
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    }
});

  const GamePlayer = (p) => {

    console.log('GamePlayer render: ', p.data);

    const [ data, setData ] = useState(p.data);
    const [ messageKey, setMessageKey ] = useState(0);
    let ignore = useRef(false);
    let trainingId = useRef(p.data.trainingId);
    let counter = useRef(p.data.counter);
    let displayedCaseIndex = useRef(p.data.displayedCaseIndex);

    const setIndex = async (i) => {
        const req = { id: data.trainingId, caseIndex: i };
        ignore.current = true;
        const d = await processApi('GamePlayer setIndex', p.checkApiMessage, setMessageKey, apiGetLastPlayerState, req);
        ignore.current = false;
        if (!d || d.messageKey !== 0) return;
        displayedCaseIndex.current = i;
        setData(d);
    }

    const duration = process.env.REACT_APP_USER_REFRESH_TIME ?? 3000;

    useEffect(() => {
        const refresh = async () => {
            if (ignore.current) {
                return;
            }
            const req = { id: trainingId.current, counter: counter.current, caseIndex: displayedCaseIndex.current };
            ignore.current = true;
            const d = await processApi('GamePlayer useEffect', p.checkApiMessage, setMessageKey, apiGetLastPlayerState, req);
            ignore.current = false;
            if (!d || d.messageKey !== 0 || !d.trainingId) return;
            trainingId.current = d.trainingId;
            displayedCaseIndex.current = d.displayedCaseIndex;
            counter.current = d.counter;
            setData(d);
        };
        let interval;
        clearInterval(interval);
        interval = setInterval(refresh, duration);
        return () => clearInterval(interval);
    }, [p.checkApiMessage, duration]);


    return (
        <Grid component="main" justifyContent='flex-start' my={1} mx={{ xs: 1, sm: 2 }} >
            <GamePlayerNav 
                caseList={data.caseList} 
                completedCaseIds={data.completedCaseIds} 
                currentCaseId={data.currentCaseId} 
                index={displayedCaseIndex.current} 
                checkApiMessage={p.checkApiMessage} 
                setMessageKey={setMessageKey}
                trainingId={data.trainingId}
                title={data.trainingTitle} 
                fullName={data.fullName}
                teamId={data.teamId}
                setIndex={setIndex} />
            <Box flex={1} overflow='auto' marginTop={2} mx={{xs: 0, sm: 2 }}>
                <ThemeProvider theme={gameManagerTheme}>
                    <Hidden smDown> 
                        <PanelGroup direction="horizontal">
                            <Panel defaultSize={60}>
                                {data.isInitialState 
                                    ? <GamePlayerInitialState data={data} checkApiMessage={p.checkApiMessage} setMessageKey={setMessageKey} />
                                    : data.isToPlay
                                        ? <GamePlayerCaseDetails data={data} checkApiMessage={p.checkApiMessage} setMessageKey={setMessageKey} />
                                        : <GamePlayerCaseFollowup userData={data.caseFollowupUserTable} teamData={data.caseFollowupTeamTable} title={data.displayedCase.title} />
                                }
                                <br/><br/><br/><br/>
                            </Panel>
                            <PanelResizeHandle className={styles.horizontalResizeHandleStyle}/>
                            <Panel>
                                <GamePlayerRuntimeSummary data={data.playerSummary} title={data.fullName}/>
                                <GamePlayerRuntimeSummary data={data.teamSummary} title={toTeamName(data.teamId)} marginTop={4}/>
                                <br/><br/><br/><br/>
                            </Panel>
                        </PanelGroup>
                    </Hidden>
                    <Hidden smUp>
                                {data.isInitialState 
                                    ? <GamePlayerInitialState />
                                    : data.isToPlay
                                        ? <GamePlayerCaseDetails data={data} checkApiMessage={p.checkApiMessage} setMessageKey={setMessageKey} />
                                        : <GamePlayerCaseFollowup userData={data.caseFollowupUserTable} teamData={data.caseFollowupTeamTable} ttitle={data.displayedCase.title} />
                                }
                        <GamePlayerRuntimeSummary data={data.playerSummary} title={data.fullName}/>
                        <GamePlayerRuntimeSummary data={data.teamSummary} title={toTeamName(p.teamId)} marginTop={4}/>
                        <br/><br/><br/><br/>
                    </Hidden>
                    </ThemeProvider>
            </Box>


            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Grid>
    );
};

export default GamePlayer;