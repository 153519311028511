import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import DualSwitch from '../Components/DualSwitch';
import StringInput from '../Components/StringInput';
import UploadInput from '../Components/UploadInput';


const MarketForm = (p) => {

    console.log('MarketForm render');

    const fileTooltip = !!p.data ? "Değişmemesi için boş bırakın" : 'Yüklemek istediğiniz dosyayı seçin *' ;


    let ref0IsValid = useRef(!!p.data);                     // title
    let ref1IsValid = useRef(!!p.data && p.data.fileName);  // uploadFile
    let ref0Value = useRef(p.data && p.data.title);
    let ref1Value = useRef(null);
    const [ isActive, setIsActive ] = useState(!p.data || p.data.isActive);
    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);

    const handleInputChange = (i, v) => {
        ref0IsValid.current = i; 
        ref0Value.current = v;
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current);
    };    
    const handleFileChange = (i, v) => {
        ref1Value.current = v.files[0];
        ref1IsValid.current = !!p.data || (ref1Value.current && ref1Value.current.name);
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current);
    };

    const process = (e) => {
        e.preventDefault();
        let data = new FormData();
        data.append('title', ref0Value.current);
        data.append('isActive', !p.data || isActive);
        if (p.data)
            data.append('id', p.data.id);        
        if (ref1Value.current) 
            data.append('uploadFile', ref1Value.current, ref1Value.current.name);
        p.process(data);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 1 }}>
            <Box>
                <StringInput label='Piyasa Adı' id='title' value={ref0Value.current} onChange={handleInputChange}/>
                <UploadInput onChange={handleFileChange} label='Yüklemek istediğiniz dosyayı seçin *' tooltip={fileTooltip} fileFilter='application/pdf' fileName={p.data?.fileName} id={p.data?.id} displayUrl='api/market/Piyasa Bilgileri?id=' />
                { p.data && <DualSwitch value={isActive} setValue={setIsActive} labelTrue='Aktif' labelFalse='Pasif' width={240} height={40} size={24}/> }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    )
    ;
}

export default MarketForm;