import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


const KPieChart = (p) => {

    console.log('KPieChart render: ', p.data);
    const [ data, setData ] = useState([]);

    const pallete = p.pallete || ['#53a57d', 'purple'];
    const arcFormat = {
        [`& .${pieArcLabelClasses.root}`]: {
            fill: p.labelFill || 'red',
            fontWeight: p.labelWeight || 'bold',
        },
    };
    const pieFormat = { 
        width: p.width || 400, 
        height: p.height || 200 
    };
    const radius = p.radius || 100;


    useEffect(() => {
        if (!p || !p.labels || !p.data || p.labels.length < p.data.length) return;
        const d = [];
        for(let i=0; i < p.labels.length; i++)
            d.push({value: p.data[i], label: `${p.labels[i]}: ${p.data[i].toString()}`});
        setData(d);
    }, [p, p.labels, p.data]);

    return (
        <Box flexGrow={1} >
            <Typography>{p.title}</Typography>
            <PieChart
                colors={pallete}
                series={[
                    {
                        // arcLabel: (item) => item.label,
                        // arcLabelMinAngle: 45,
                        data: data,
                        outerRadius: radius
                    },
                ]}
                sx={arcFormat}
                {...pieFormat}
            />
        </Box>
    );
};

export default KPieChart;