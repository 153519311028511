import { useRef } from 'react';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';


const DualSwitch = (p) => {

    console.log('DualSwitch render');

    const fullWidth = p.width || 240;
    const halfWidth = fullWidth / 2;
    const fullHeight = p.height || 40;
    const fontSize = p.size || 24;

    let value = useRef(p.value);
    let intLeft = useRef(p.value ? 0 : halfWidth);

    const handleRadio = (e) => toggle(e.target.value === 'true');

    const toggle = (v) => {
        p.setValue(v);
        value.current = v;
        intLeft.current = intLeft.current ? 0 : halfWidth; 
    }


    return (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }} >
            <Box component='div' width={fullWidth} height={fullHeight} borderRadius='100px' boxShadow={'1px 1px 10px 0px #53a57d'} >
                <Box height={fullHeight} width={halfWidth} left={intLeft.current} bgcolor='#53a57d' borderRadius='100px' zIndex={2} position={'relative'} ></Box>
                <RadioGroup row name='rg' value={value.current} onChange={handleRadio}
                    sx={{ position: 'relative', top: -fullHeight+.5, mx: 0, width: '100%', justifyContent: 'space-evenly', alignItems: 'center'}} >
                    <FormControlLabel value={true} sx={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-evenly' }}
                        control={<Radio sx={{ display: 'none', zIndex: -3 }} disabled={p.disabled} />} 
                        label={<Typography zIndex='1' color='gray' fontWeight={700} fontSize={fontSize} >{p.labelFalse}</Typography>}  />
                    <FormControlLabel value={false} sx={{ width: '50%', marginLeft: 'auto', marginRight: 'auto', justifyContent: 'space-evenly' }}
                        control={<Radio sx={{ display: 'none', zIndex: -3 }} disabled={p.disabled} />} 
                        label={<Typography zIndex='1' color='primary' fontWeight={700} fontSize={fontSize} >{p.labelTrue}</Typography>} />
                </RadioGroup>
            </Box>
        </Box>
    );
};

export default DualSwitch