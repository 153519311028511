import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';


const MenuItemz = (p) => {

    return (
        <MenuItem onClick={p.onClick}>
            <Typography textAlign="center">{p.label}</Typography>
        </MenuItem>
    );
};

export default MenuItemz;