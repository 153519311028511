import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import DualSwitch from '../Components/DualSwitch';
import EMailInput from '../Components/EMailInput';
import KSelect from '../Components/KSelect';
import StringInput from '../Components/StringInput';


const UserForm = (p) => {

    console.log('UserForm render: ', p.data);

    let ref0IsValid = useRef(!!p.data);     // userId
    let ref1IsValid = useRef(!!p.data);     // fullName
    let ref2IsValid = useRef(true);         // password
    let ref0Value = useRef(p.data && p.data.userId);
    let ref1Value = useRef(p.data && p.data.fullName);
    let ref2Value = useRef(p.data ? '' : '123456');
    const [ companyId, setCompanyId ] = useState((p.data && p.data.companyId) ?? '');
    const [ isAdmin, setIsAdmin ] = useState((p.data && p.data.isAdmin) ?? false);
    const [ isActive, setIsActive ] = useState(!p.data || p.data.isActive);
    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);
    const handleInputChange = (x, i, v) => {
        if (x === 0) {ref0IsValid.current = i; ref0Value.current = v;}
        else if (x === 1) {ref1IsValid.current = i; ref1Value.current = v;}
        else if (x === 2) {ref2IsValid.current = i; ref2Value.current = v;}
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current && ref2IsValid.current && companyId !== '');
    };
    const handleCompanyIdChange = (v) => {
        setCompanyId(v);
        setSubmitEnabled(ref0IsValid.current && ref1IsValid.current && ref2IsValid.current && v !== '');
    } 

    const process = (e) => {
        e.preventDefault();
        const data = {
            'userId': ref0Value.current,
            'preUserId': p.data?.userId ?? '',
            'fullName': ref1Value.current,
            'companyId': companyId,
            'password': ref2Value.current,
            'isAdmin': isAdmin,
            'isActive': isActive
        };
        p.process(data);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 1 }}>
            <Box>
                <EMailInput label='E-posta Adresi' autoFocus value={ref0Value.current} onChange={(isValid, value) => handleInputChange(0, isValid, value)}/>
                
                <StringInput label='Ad Soyad' id='fullName'  value={ref1Value.current} onChange={(isValid, value) => handleInputChange(1, isValid, value)}/>

                <KSelect label='Firma Adı *' id='companyId' value={companyId} setValue={handleCompanyIdChange} data={p.select} />

                <StringInput label='Şifre - değişmemesi için boş bırakın' id='password' nullIsOK={!!p.data} value={ref2Value.current} minLength={6} onChange={(isValid, value) => handleInputChange(2, isValid, value)}/>

                { p.data && <DualSwitch value={isActive} setValue={setIsActive} labelTrue='Aktif' labelFalse='Pasif' width={240} height={40} size={24}/> }

                <DualSwitch value={isAdmin} setValue={setIsAdmin} labelTrue='Admin' labelFalse='Kullanıcı' width={240} height={40} size={24}/>

            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    )
    ;
}

export default UserForm;