import { useState } from 'react';
import { TextField } from '@mui/material';

const StringInput = (p) => {

    console.log('StringInput render');
    const minLength = p.minLength || 4;

    const isValid = (t) => (p.nullIsOK && (t == null || t === '')) || (t && t.length >= minLength);

    const [stringInputErrorState, setStringInputErrorState] = useState(false);
    const handleStringInput = (e) => {
        const f = isValid(e.target.value);
        if (p.onChange) p.onChange(f, e.target.value);
        setStringInputErrorState(!f);
    };

    return (
        <TextField 
            required fullWidth
            id={p.id}
            name={p.id}
            // margin='normal'
            sx={{ my: '4px'}}
            autoComplete={p.id}
            autoFocus={p.autoFocus}
            multiline={p.multiline}
            rows={p.multiline && (p.rows || 4)}
            defaultValue={p.value}
            label={p.label}            
            error ={stringInputErrorState}
            helperText={stringInputErrorState && ('En az ' + minLength + ' karakter girmelisiniz')}
            onChange={handleStringInput}
            InputProps={{ readOnly: p.readOnly }}
        />
    );
};

export default StringInput;