import { useState } from 'react';
import { Box, Grid, Hidden } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import PopupMessage from '../Components/PopupMessage';
import GameManagerCaseDetails from '../Forms/GameManagerCaseDetails'
import GameManagerControl from '../Forms/GameManagerControl';
import GameManagerNav from '../Forms/GameManagerNav';
import { isMessageKeyValid } from '../Lib/Constants';
import { apiGetSimCase } from '../Lib/Api';
import processApi from '../Lib/ProcessApi';
import styles from '../Styles/styles.module.css';

const gameManagerTheme = createTheme({
    palette: {
      primary: {
        main: '#53a57d',
        contrastText: '#fff'
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    }
  });

const GameManager = (p) => {

    console.log('GameManager render');

    const [ data, setData ] = useState(p.data);
    const [ messageKey, setMessageKey ] = useState(0);

    const setIndex = async (i) => {
        const req = { id: data.trainingId, caseIndex: i };
        const d = await processApi('GameManager setIndex', p.checkApiMessage, setMessageKey, apiGetSimCase, req);
        if (!d || d.messageKey !== 0) return;
        setData(d);
    }

    return (
        <Grid component="main" justifyContent='flex-start' my={1} mx={{ xs: 1, sm: 2 }} >
            
            <GameManagerNav 
                caseList={data.caseList} 
                completedCaseIds={data.completedCaseIds} 
                index={data.displayedCaseIndex} 
                title={data.trainingTitle} 
                checkApiMessage={p.checkApiMessage}
                trainingId={data.trainingId}
                setMessageKey={setMessageKey}
                setIndex={setIndex} />
            
            <Box flex={1} overflow='auto' marginTop={2} mx={{xs: 0, sm: 2 }}>
                <ThemeProvider theme={gameManagerTheme}>

                    <Hidden smDown> 
                        <PanelGroup direction="horizontal">
                            <Panel defaultSize={60}>
                                <GameManagerControl data={data} setData={setData} checkApiMessage={p.checkApiMessage} />
                                <br/><br/><br/><br/>
                            </Panel>
                            <PanelResizeHandle className={styles.horizontalResizeHandleStyle}/>
                            <Panel>
                                <GameManagerCaseDetails data={data} checkApiMessage={p.checkApiMessage} setMessageKey={setMessageKey}/>
                                <br/><br/><br/><br/>
                            </Panel>
                        </PanelGroup>
                    </Hidden>
                    <Hidden smUp>
                        <GameManagerControl data={data} setData={setData} checkApiMessage={p.checkApiMessage} />
                        <GameManagerCaseDetails data={data} checkApiMessage={p.checkApiMessage} setMessageKey={setMessageKey}/>
                        <br/><br/><br/><br/>
                    </Hidden>
                </ThemeProvider>
            </Box>
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Grid>
    );
};

export default GameManager;