import { useState } from 'react';
import { FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { toCurrency } from '../Lib/Constants';

const alignedInputTheme = createTheme({
    palette: {
        primary: {
            main: '#53a57d',
            contrastText: '#fff'
        }
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    textAlign: 'end',
                    paddingRight: '2'
                }
            },
        },
    },
});

const PercentageInput = (p) => {

    console.log('PercentageInput render');

    const regex = p.regex  || /^(0|[1-9][0-9]{0,2})$/;
    const prefix = p.prefix  || '%';
    const id = p.id ? p.id : 'percentage';

    const [errorState, setErrorState] = useState(false);
    const handleInput = (e) => {
        if (!p.onChange) return;
        const isValid = e.target.value && regex.test(e.target.value);
        p.onChange(isValid, e.target.value);
        setErrorState(!isValid);
    };



    return (
        <FormControl fullWidth variant='outlined' sx={{ marginTop: '4px', marginBottom: '4px' }} >
            <InputLabel htmlFor={id}>{p.label}</InputLabel>
            <ThemeProvider theme={alignedInputTheme}>
                <OutlinedInput
                    required fullWidth
                    id={id}
                    autoFocus={!!p.autoFocus}
                    label={p.label}
                    error={errorState}
                    disabled={p.disabled} 
                    readOnly={p.readOnly}
                    onChange={handleInput} 
                    defaultValue={toCurrency(p.value)}
                    autoComplete='percentage'
                    startAdornment={<InputAdornment position='start'>{prefix}</InputAdornment>}
                />
            </ThemeProvider>
            {errorState &&
                <FormHelperText error id="percentage-error">Geçerli bir {prefix} oran girmelisiniz</FormHelperText>
            }
        </FormControl>
    );
};

export default PercentageInput;