import { Box, Tooltip } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const Download = (p) => {

    console.log('Download render');

    return (
        <Box position='relative'>
            <Box position='absolute' top='-60px' right={0} display='flex'>
                <Box sx={{ marginRight: 1 }}>
                    <Tooltip title='PDF olarak indir'>                
                        <a href={`./api/download/${p.reportName}Pdf?id=${p.id}`} download>
                            <PictureAsPdfIcon fontSize='large' color='primary'/>
                        </a>
                    </Tooltip>
                </Box>
                <Tooltip title='EXCEL olarak indir'>
                    <a href={`./api/download/${p.reportName}Excel?id=${p.id}`} download >
                        <svg width='2.1em' height='2.1em' viewBox='0 0 24 24'><path fill='#53a57d' d='M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8zm1.8 18H14l-2-3.4l-2 3.4H8.2l2.9-4.5L8.2 11H10l2 3.4l2-3.4h1.8l-2.9 4.5zM13 9V3.5L18.5 9z'/></svg>
                    </a>
                </Tooltip>
            </Box>
        </Box>
    );
}

export default Download;