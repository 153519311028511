// download
// export const apiDownloadFollowupPdf = async (id) => await api('api/download/followupPdf?id='+id, 'apiDownloadFollowupPdf');
// export const apiDownloadFollowupExcel = async (id) => await api('api/download/followupExcel?id='+id, 'apiDownloadFollowupExcel');
// export const apiDownloadUserSummaryPdf = async (id) => await api('api/download/userSummaryPdf?id='+id, 'apiDownloadUserSummaryPdf');
// export const apiDownloadUserSummaryExcel = async (id) => await api('api/download/userSummaryExcel?id='+id, 'apiDownloadUserSummaryExcel');
// export const apiDownloadTeamSummaryPdf = async (id) => await api('api/download/teamSummaryPdf?id='+id, 'apiDownloadTeamSummaryPdf');
// export const apiDownloadTeamSummaryExcel = async (id) => await api('api/download/teamSummaryExcel?id='+id, 'apiDownloadTeamSummaryExcel');
// export const apiDownloadAchievementPdf = async (id) => await api('api/download/achievementPdf?id='+id, 'apiDownloadAchievementPdf');
// export const apiDownloadAchievementExcel = async (id) => await api('api/download/achievementExcel?id='+id, 'apiDownloadAchievementExcel');

// summary
export const apiReportWelcomeAdmin = async () => await api('api/report/welcomeadmin', 'apiReportWelcomeAdmin');
export const apiReportWelcomeUser = async (request) => await api('api/report/welcomeuser', 'apiReportWelcomeUser', request);
export const apiReportFollowup = async (id) => await api('api/report/followup?id='+id, 'apiReportFollowup');
export const apiReportCaseFollowup = async (id) => await api('api/report/casefollowup?id='+id, 'apiReportCaseFollowup');
export const apiReportUserSummary = async (id) => await api('api/report/usersummary?id='+id, 'apiReportUserSummary');
export const apiReportTeamSummary= async (id) => await api('api/report/teamsummary?id='+id, 'apiReportTeamSummary');
export const apiReportAchievement= async (id) => await api('api/report/achievement?id='+id, 'apiReportAchievement');


// simulation
export const apiStartTimulation = async (request) => await api('api/sim/startsim', 'apiStartSimulation', request);
export const apiRefreshSimulation = async (request) => await api('api/sim/refresh', 'apiRefreshSimulation', request);
export const apiGetSimCase = async (request) => await api('api/sim/getcase', 'apiGetSimCase', request);
export const apiGetCaseFollowUp = async (request) => await api('api/sim/casefollowup', 'apiGetCaseFollowUp', request);
export const apiGetUserFollowUp = async (request) => await api('api/sim/userFollowup', 'apiGetUserFollowUp', request);
export const apiStartCase = async (request) => await api('api/sim/startcase', 'apiStartCase', request);
export const apiPauseCase = async (request) => await api('api/sim/pausecase', 'apiPauseCase', request);
export const apiStopCase = async (request) => await api('api/sim/stopcase', 'apiStopCase', request);
export const apiWelcomePlayer = async () => await api('api/sim/welcomeplayer', 'apiWelcomePlayer');
export const apiGetLastPlayerState = async (request) => await api('api/sim/lastplayerstate', 'apiGetLastPlayerState', request);
export const apiPlayerAnswer = async (request) => await api('api/sim/answer', 'apiPlayerAnswer', request);
export const apiTeamAnswer = async (request) => await api('api/sim/teamanswer', 'apiTeamAnswer', request);
export const apiGetClassList = async (request) => await api('api/sim/getClassList', 'apiGetClassList', request);


// training
export const apiGetAllTrainings = async (request) => await api('api/training/all', 'apiGetAllTrainings', request);
export const apiGetTraining = async (id) => await api('api/training/get?id='+id, 'apiGetTraining');
export const apiCloneTraining = async (id) => await api('api/training/clone?id='+id, 'apiCloneTraining');
export const apiAddTraining = async (request) => await api('api/training/add', 'apiAddTraining', request);
export const apiUpdateTraining = async (request) => await api('api/training/update', 'apiUpdateTraining', request);


// case
export const apiGetAllCases = async (request) => await api('api/case/all', 'apiGetAllCases', request);
export const apiGetAllActiveCases = async () => await api('api/case/active', 'apiGetAllActiveCases');
export const apiGetCase = async (id) => await api('api/case/get?id='+id, 'apiGetCase');
export const apiCloneCase = async (id) => await api('api/case/clone?id='+id, 'apiCloneCase');
export const apiAddCase = async (request) => await api('api/case/add', 'apiAddCase', request);
export const apiUpdateCase = async (request) => await api('api/case/update', 'apiUpdateCase', request);

// bank
export const apiGetAllBanks = async (request) => await api('api/bank/all', 'apiGetAllBanks', request);
export const apiGetAllActiveBanks = async () => await api('api/bank/active', 'apiGetAllActiveBanks');
export const apiGetBank = async (id) => await api('api/bank/get?id='+id, 'apiGetBank');
export const apiAddBank = async (request) => await api('api/bank/add', 'apiAddBank', request);
export const apiUpdateBank = async (request) => await api('api/bank/update', 'apiUpdateBank', request);

// market
export const apiGetAllMarkets = async (request) => await api('api/market/all', 'apiGetAllMarkets', request);
export const apiGetAllActiveMarkets = async () => await api('api/market/active', 'apiGetAllActiveMarkets');
export const apiGetMarket = async (id) => await api('api/market/get?id='+id, 'apiGetMarket');
export const apiAddMarket = async (request) => await api('api/market/add', 'apiAddMarket', request);
export const apiUpdateMarket = async (request) => await api('api/market/update', 'apiUpdateMarket', request);

// user
export const apiGetAllUsers = async (request) => await api('api/user/all', 'apiGetAllUsers', request);
export const apiGetAllActiveUsers = async () => await api('api/user/active', 'apiGetAllActiveUsers');
export const apiGetUser = async (request) => await api('api/user/get', 'apiGetUser', request);
export const apiAddUser = async (request) => await api('api/user/add', 'apiAddUser', request);
export const apiUpdateUser = async (request) => await api('api/user/update', 'apiUpdateUser', request);
export const apiUploadUser = async (request) => await api('api/user/upload', 'apiUploadUser', request);

// company
export const apiGetAllCompanies = async () => await api('api/company/all', 'apiGetAllCompanies');
export const apiGetAllActiveCompanies = async () => await api('api/company/active', 'apiGetAllCompanies');
export const apiGetCompany = async (id) => await api('api/company/get?id='+id, 'apiGetCompany');
export const apiAddCompany = async (request) => await api('api/company/add', 'apiAddCompany', request);
export const apiUpdateCompany = async (request) => await api('api/company/update', 'apiUpdateCompany', request);

// login
export const apiLogin = async (request) => await api('api/account/auth', 'apiLogin', request);
export const apiUpdatePassword = async (request) => await api('api/account/update', 'apiUpdatePassword', request);
export const apiFishMemory = async (request) => await api('api/account/reset', 'apiFishMemory', request);


const getOptions = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
    },
    referrerPolicy: 'origin-when-cross-origin',
    cache: "no-cache",
    mode: 'cors'
};
const fileUploadOptions = {
    method: 'POST',
    headers: { }
};
const postOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Connection': 'keep-alive'
    }, 
    referrerPolicy: 'origin-when-cross-origin',
    cache: "no-cache",
    mode: 'cors'
};

const addToken = (headers) => {
    const item = localStorage.getItem('account');
    if (!item) return;
    const account = JSON.parse(item);
    if (!account || !account.token) return;
    headers['Authorization'] = account.token;
};

const api = async (urlExt, parent, request) =>
{
    const url = process.env.REACT_APP_BASE_URL + urlExt;
    const opt = structuredClone(request ? (request instanceof FormData ? fileUploadOptions : postOptions)  : getOptions);
    if (request)
        opt.body = (request instanceof FormData) ? request : JSON.stringify(request);
    addToken(opt.headers);

    console.log('Api opt: ' + url, opt);

    let response = await fetch(url, opt)
        .catch((error) => {
            console.log("error catch in " + parent + " fetch: ", error);
        });
    if (!response)
        return null;

    return await response.json();
};