import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import KProgressBar from "../Components/KProgressBar";
import { toCurrency, toDecimal, toPercentage } from "../Lib/Constants";

const headerStyle = { color: 'white', fontWeight: 700, border: '1px solid black', paddingY: 0};
const boldCellStyle = { fontWeight: 700, border: 1, paddingY: 0 };
const resultCellStyle = { color: 'white', fontWeight: 700, border: '1px solid black', paddingY: 0, backgroundColor: '#53a57d'};
const cellStyle = { border: 1, paddingY: 0};
const emptyCellStyle = { border: 1, paddingY: 0, backgroundColor: 'gray'};

const GamePlayerRuntimeSummary = (p) => {

    console.log('GamePlayerRuntimeSummary render: ', p.orderNo);


    return (
        // <Box marginTop={2} marginRight={1} >
        <Box marginTop={p.marginTop || 1} >
            <Box display='flex' maxWidth='545px'>
                <Tooltip title={`${p.title.startsWith('Kredi') ? 'Komite' : 'Kullanıcı'} Sıra No`}>
                    <Box component='span' display='flex' sx={{ backgroundColor: 'purple', width: 40, height: 40, paddingX: 2, borderRadius: '50%', justifyContent: 'center', position: 'relative'}} >
                        <Typography sx={{ fontSize:'20px', color: 'white', fontWeight: 700, whiteSpace: 'nowrap', alignSelf: 'center' }} >{p.data.orderNo}</Typography>
                        <MilitaryTechIcon  sx={{ color: 'purple', position: 'absolute', right: -15, top: -5}}/>
                    </Box>                    
                </Tooltip>
                <Typography sx={{ fontSize:'18px', color: '#53a57d', fontWeight: 500, whiteSpace: 'nowrap', marginLeft: 2 }} alignSelf='center' >{p.title}</Typography>
                <KProgressBar value={p.data.numberOfAnswers} scale={p.data.numberOfCases} />
            </Box>
            <Box sx={{ maxWidth: '536px', marginTop: 1 }}>
                <TableContainer>
                    <Table size='small'>
                        <TableHead sx={{ backgroundColor: '#53a57d'}}>
                            <TableRow>
                                <TableCell align='center' sx={headerStyle}></TableCell>
                                <TableCell align='center' sx={headerStyle}>Hedef</TableCell>
                                <TableCell align='center' sx={headerStyle}>Gerçekleşen</TableCell>
                                <TableCell align='center' sx={headerStyle}>H/G %</TableCell>
                                <TableCell align='center' sx={headerStyle}>KPI</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ backgroundColor: 'ghostwhite'}}>
                                <TableCell align='left' sx={boldCellStyle}>Plasman</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.plasman.target)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.plasman.real)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toPercentage(p.data.plasman.rate)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toDecimal(p.data.plasman.kpi, 2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align='left' sx={boldCellStyle}>Kâr</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.profit.target)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.profit.real)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toPercentage(p.data.profit.rate)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toDecimal(p.data.profit.kpi, 2)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: 'ghostwhite'}}>
                                <TableCell align='left' sx={boldCellStyle}>NPL Oranı</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.nplRate.target)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.nplRate.real)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toPercentage(p.data.nplRate.rate)}</TableCell>
                                <TableCell align='right' sx={emptyCellStyle}/>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: 'ghostwhite'}}>
                                <TableCell align='left' sx={boldCellStyle}>NPL Tutarı</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.npl.target)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toCurrency(p.data.npl.real)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toPercentage(p.data.npl.rate)}</TableCell>
                                <TableCell align='right' sx={cellStyle}>{toDecimal(p.data.npl.kpi, 2)}</TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: 'ghostwhite'}}>
                                <TableCell align='left' sx={boldCellStyle}>Toplam</TableCell>
                                <TableCell align='right' sx={emptyCellStyle}/>
                                <TableCell align='right' sx={emptyCellStyle}/>
                                <TableCell align='right' sx={emptyCellStyle}/>
                                <TableCell align='right' sx={resultCellStyle}>{toDecimal(p.data.kpi, 2)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
}

export default GamePlayerRuntimeSummary;