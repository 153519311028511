import { useRef, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { apiGetCase } from "../Lib/Api";
import processApi from '../Lib/ProcessApi';
import KModal from "../Components/KModal";
import CaseSelectionFormFullList from "./CaseSelectionFormFullList";
import CaseSelectionFormSelectedList from "./CaseSelectionFormSelectedList";
import CaseDisplay from './CaseDisplay';


const CaseSelectionForm = (p) => {

    console.log('CaseSelectionForm render');

    const [ submitEnabled, setSubmitEnabled ] = useState(false);
    const [ caseList, setCaseList ] = useState([...p.caseList]);
    const [ openModal, setOpenModal ] = useState(false);
    let filtered = useRef(p.data?.fullUserList ?? []);
    let modal = useRef(null);

    const process = () => {
        p.setCaseList(caseList);
        p.handleClose();
    };

    const moveCase = (v) => { const t = [...caseList]; t.push(v); set(t); };
    const removeCase = (v) => set(v);
    const set = (finalList, flag) => { setCaseList(finalList); if (!flag) setSubmitEnabled(true);}

    const onDragEnd = (result) => {       
        if (!result.destination) { set([...caseList], false); return; }
        if (result.source.droppableId === 'newCase' && result.destination.droppableId === 'case')
            addDraggedCase(result.source.index, result.destination.index);
        else if (result.source.droppableId === 'case' && result.destination.droppableId === 'case')
            moveDraggedCase(result.source.index, result.destination.index);
        else if (result.destination.droppableId === 'newCase' && result.source.droppableId === 'case')
            removeDraggedCase(result.source.index);
        else
            set([...caseList], false);
    } 

    const addDraggedCase = (si, di) => {        
        const item = filtered.current[si];
        const t = [...caseList];
        t.splice(di, 0, item);
        set(t);
    }
    
    const moveDraggedCase = (si, di) => {        
        const t = [...caseList];
        const [item] = t.splice(si, 1);
        t.splice(di, 0, item);
        set(t);        
    }

    const removeDraggedCase = (si) => {     
        const t = [...caseList];
        t.splice(si, 1);
        set(t);
    }

    const displayCase= async (id) => {
        const caseData = await processApi('TrainingForm displayCase', p.data.checkApiMessage, p.data.setMessageKey, apiGetCase, id);
        if (!caseData) return;
        modal.current = {
            title: caseData.title,
            form: <CaseDisplay data={caseData} handleClose={closeModal} />,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);

    return (
        <Box sx={{ mt: 0 }}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Grid container spacing='4' marginTop='0'>
                    <Grid item xs={6} paddingRight={2}>
                        <CaseSelectionFormFullList 
                            searchWord={p.data?.searchWord}
                            fullCaseList={p.data?.fullCaseList} 
                            selectedCaseList={caseList}
                            setSearchWord={p.data?.setSearchWord} 
                            setFiltered={(f) => filtered.current = f}
                            displayCase={displayCase}
                            move={moveCase} />
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={6}>
                        <CaseSelectionFormSelectedList
                            selectedCaseList={caseList}
                            setSelectedCaseList={setCaseList}
                            displayCase={displayCase}
                            removeCase={removeCase}
                        />
                    </Grid>
                </Grid>
            </DragDropContext>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button onClick={process} variant="contained" disabled={!submitEnabled} >SAKLA</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
};

export default CaseSelectionForm;