import { useEffect, useState } from "react";
import { Box, Button, Card, CardActions, CardContent, Grid, Typography } from "@mui/material";
import PopupMessage from '../Components/PopupMessage.jsx';
import { apiReportWelcomeUser } from "../Lib/Api.js";
import { isMessageKeyValid } from '../Lib/Constants.js';
import processApi from "../Lib/ProcessApi.js";


const ReportWelcomeUser = (p) => {

    console.log('ReportWelcomeUser render');
    const [ trainings, setTrainings ] = useState([]);
    const [ messageKey, setMessageKey ] = useState(0);
    const [ reportForm, setReportForm] = useState(null);

    
    useEffect(() => {
        const fetchData = async () => {
            const d = await processApi('ReportWelcomeUser useEffect', p.checkApiMessage, setMessageKey, apiReportWelcomeUser, {reportType: p.reportType});
            if (!d || d.messageKey !== 0) return;
            if (d.trainings.length === 1) setReportForm(p.form(d.trainings[0].key, setMessageKey)); 
            setTrainings(d.trainings);
        };
        fetchData();
    }, [p, setReportForm]);


    return (
        <Box component="main"  sx={{ margin: { xs: 1, sm: 3 } }}>
            {trainings.length !== 1 && !reportForm &&
                <Box>
                    <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>Kepsim24</Typography>
                    <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>Kredi Simülasyon Uygulaması</Typography>
                    <Typography component='h4' variant='h4' padding={1} sx={{ color: '#53a57d', fontWeight: 700, textAlign: 'center' }}>{p.title}</Typography>
                    {trainings.length === 0 &&
                        <Box display='flex' justifyContent='center'>
                        <Typography component='h6' variant='h6' marginTop={3} sx={{ color: 'purple', fontWeight: 500 }}>Kayıtlı olduğunuz eğitim(ler) tamamlandığında &nbsp;</Typography>
                        <Typography component='h6' variant='h6' marginTop={3} sx={{ color: '#53a57d', fontWeight: 700, textDecoration: 'underline' }}>{p.title}</Typography>
                        <Typography component='h6' variant='h6' marginTop={3} sx={{ color: 'purple', fontWeight: 500 }}>&nbsp; raporunu burada görebilirsiniz.</Typography>
                        </Box>
                    }
                    <Box display='flex' justifyContent='space-evenly' marginTop={3} width='100%' >
                        {trainings.map((t) =>
                                <Card key={t.key} sx={{ width: 200 }} elevation={12}>
                                    <CardContent>
                                        <Typography component='h6' variant='h6' marginTop={3} sx={{ color: 'purple', fontWeight: 700, textAlign: 'center' }}>
                                            {t.value}
                                        </Typography>                                
                                    </CardContent>
                                    <CardActions>
                                        <Button color='primary' onClick={() => setReportForm(p.form(t.key, setMessageKey))}>GİRİŞ</Button>
                                    </CardActions>
                                </Card> 
                        )}
                    </Box>
                </Box>
            }
            {reportForm != null && 
                <Grid component='main' maxWidth={p.maxWidth} justifyContent='flex-start' sx={{ margin: { xs: 1, sm: 3 } }}>
                    <Typography component='h5' variant='h5' sx={{ color: '#53a57d', fontWeight: 700 }}>{p.title}</Typography>
                    {reportForm}
                </Grid>
            }
            {!!(isMessageKeyValid(messageKey)) &&
                <PopupMessage messageKey={messageKey} setMessageKey={setMessageKey} />}
        </Box>
    );
};

export default ReportWelcomeUser;