import { useEffect, useRef, useState } from 'react';
import { Box, Grid } from '@mui/material';
import KPieChart from '../Components/KPieChart.jsx';
import KTable from '../Components/KTable.jsx';
import { maturityTypeToString, toCurrency } from '../Lib/Constants.js';
import { apiGetCaseFollowUp } from '../Lib/Api.js';
import processApi from '../Lib/ProcessApi.js';

const labels = ['Cevap Sayısı','Boş'];

const GameManagerCaseFollowup = (p) => {

    console.log('GameManagerCaseFollowup render: ');

    const [ userData, setUserData ] = useState(null);
    const [ teamData, setTeamData ] = useState(null);
    const [ usersChartData, setUsersChartData ] = useState(null);
    const [ teamsChartData, setTeamsChartData ] = useState(null);
    const duration = process.env.REACT_APP_MASTER_REFRESH_TIME ?? 3000;
    let refreshCounter = useRef(-1);
    let ignore = useRef(false);

    const headers = [
        {
            id: 'orderNo',
            align: 'center',
            label: 'SıraNo',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'teamId',
            align: 'center',
            label: 'Komite',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'fullName',
            align: 'left',
            label: 'Adı',
            tooltip: 'userId',
            sortable: true,
            disablePadding: false
        },
        {
            id: 'decision',
            flag: 'result',
            align: 'left',
            label: 'Cevap',
            disablePadding: false,
            sortable: true,
            convert: (d, r) => r === 0 ? 'Henüz Cevaplamadı' : d ? 'Onayladı' : 'Reddetti'
        },
        {
            id: 'result',
            align: 'left',
            label: 'Sonuç',
            disablePadding: false,
            sortable: true,
            convert: (d) => d === 0 ? 'Henüz Cevaplamadı' : d === 1 ? 'Kullandırdı' : d === 2 ? 'Kullandıramadı' : d === 3 ? 'Reddetti' : '-'
        },
        {
            id: 'limit',
            align: 'right',
            label: 'Limit',
            disablePadding: true,
            sortable: true,
            convert: (d) => toCurrency(d)
        },
        {
            id: 'maturity',
            flag: 'maturityType',
            align: 'right',
            label: 'Vade',
            disablePadding: true,
            sortable: true,
            convert: (d, t) => toCurrency(d) + maturityTypeToString(t)
        },
        {
            id: 'collateral',
            title: 'collateral',
            align: 'left',
            label: 'Teminat',
            disablePadding: false,
            sortable: true,
            convert: (d) => !d ? '' : d.length <= 24 ? d.replaceAll('\\n' , '') : (d.replaceAll('\\n' , ' ').slice(0, 24) + '..')
        }
    ];


    useEffect(() => {refreshCounter.current = -1;}, [p.cid]);

    useEffect(
        () => {
            const refresh = async () => {
                if (ignore.current) {
                    return;
                }
                ignore.current = true;
                const rtn = await processApi('GameManagerCaseFollowup refresh', p.checkApiMessage, p.setMessageKey, apiGetCaseFollowUp, { id: p.tid, caseId: p.cid, counter: refreshCounter.current });
                ignore.current = false;
                if (rtn && rtn.counter) refreshCounter.current = rtn.counter;
                if (!rtn || rtn.messageKey == null) return;
                if (rtn.messageKey !== 0 || !rtn.caseFollowupUserTable || !rtn.caseFollowupTeamTable) return;
                setUserData(rtn.caseFollowupUserTable);
                setTeamData(rtn.caseFollowupTeamTable);
                setUsersChartData([rtn.usersCount0, rtn.usersCount1 - rtn.usersCount0]);
                setTeamsChartData([rtn.teamsCount0, rtn.teamsCount1 - rtn.teamsCount0]);
            };
            let interval;
            if (p.isToRefresh) {
                refresh();
                interval = setInterval(refresh, duration);
            }
            else {                
                clearInterval(interval);
                refreshCounter.current = -1;
                refresh();
            }
            return () => clearInterval(interval);
        }, [p.isToRefresh, p.tid, p.cid, duration, p.checkApiMessage, p.setMessageKey]
    );

    return (
        <Box>
            <Grid container marginTop='0' textAlign={{xs:'left', xm:'center'}}  >
                <Grid item xs={'auto'}><KPieChart title='Kullanıcı Cevapları' labels={labels} data={usersChartData} width={400} height={170} radius={80}/></Grid>
                <Grid item xs={'auto'}><KPieChart title='Komite Cevapları' labels={labels} data={teamsChartData} width={400} height={170} radius={80}/></Grid>
            </Grid>
            <KTable headers={headers} data={userData} rowKey='fullName' marginTop={0} initialRowsPerPage={40} noSearch noPagination isDense />
            <KTable headers={headers} data={teamData} rowKey='fullName' marginTop={0} initialRowsPerPage={40} noSearch noPagination isDense />
        </Box>
    );
};


export default GameManagerCaseFollowup;