import { useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Box } from '@mui/material';
import 'dayjs/locale/tr';

const KDatePicker = (p) => {

    console.log('KDatePicker render');

    const [errorMessage, setErrorMessage] = useState(null);

    const onError = (e) => {
        switch (e) {
            case 'disablePast':
            case 'maxDate':
            case 'minDate': {
                setErrorMessage('Lütfen bugün veya ileri bir tarihi seçin');
                p.setValid(false);
                break;
            }
            case 'invalidDate': {
                setErrorMessage('Lütfen geçerli bir tarih seçin');
                p.setValid(false);
                break;
            }
            default: {
                // if (!initial) p.setValid(true); else initial = false;
                setErrorMessage('');
                p.setValid(true);
            }
        }
    };


    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='tr'>
        {/* <LocalizationProvider dateAdapter={Dayjs} adapterLocale='tr'> */}
            <Box my='4px'>
                <DateTimePicker width='100%'
                    label={p.label}
                    value={!p.value ? null : dayjs(p.value)}
                    onChange={(v) => { p.setValid(true); p.setValue(v) }}
                    disablePast={p.disablePast}
                    views={['year', 'month', 'day', 'hours', 'minutes']}
                    color='primary'
                    onError={onError}
                    slotProps={{ textField: { helperText: errorMessage } }}
                />
            </Box>
        </LocalizationProvider>
    );
};

export default KDatePicker;
