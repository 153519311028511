import { useRef, useState } from 'react';
import { Box, Button } from '@mui/material';
import DualSwitch from '../Components/DualSwitch';
import StringInput from '../Components/StringInput';


const CompanyForm = (p) => {

    console.log('CompanyForm render');

    const [ isActive, setIsActive ] = useState(!p.data || p.data.isActive);
    const [ submitEnabled, setSubmitEnabled ] = useState(!!p.data);

    const ref0Value = useRef(p.data && p.data.title);
    const handleInputChange = (i, v) => {
        ref0Value.current = v;
        setSubmitEnabled(i);
    };

    const process = (e) => {
        e.preventDefault();
        const data = {
            'id': p.data ? p.data.id : null,
            'title': ref0Value.current,
            'isActive': isActive
        };
        p.process(data);
    };

    return (
        <Box component="form" onSubmit={process} noValidate sx={{ mt: 1 }}>
            <Box>
                <StringInput label='Firma Unvanı' id='title' value={ref0Value.current} onChange={handleInputChange}/>
                { p.data && <DualSwitch value={isActive} setValue={setIsActive} labelTrue='Aktif' labelFalse='Pasif' width={240} height={40} size={24}/> }
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button type="submit" variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
        </Box>
    )
    ;
}

export default CompanyForm;