import { useState } from "react";
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from "@mui/material";

const KRadioGroup = (p) => {

    console.log('KRadioGroup render: ', p);

    const [ value, setValue ] = useState(p.value ?? p.values.find(o => o.isDefault).id);


    const handleChange = (e) => {
        setValue(e.target.value)
        p.setValue(e.target.value);
    }

    return (
        <Paper sx={{ padding: 2, height: '100%'}} elevation={8} >
            <FormControl fullWidth>
                <Typography variant='span' sx={{ fontSize: '18px', color: 'black', fontWeight: 500 }} >{p.title}</Typography>
                <RadioGroup value={value} onChange={handleChange}>
                    {p.values.map((v) =>
                        <FormControlLabel key={v.id} value={v.id} 
                            control={<Radio sx={{ padding: '4px' }} />}  
                            sx={{ alignItems: 'start',  background: p.commited === v.id && 'lavender', marginRight: '-4px' }}
                            label={<Typography sx={{ fontWeight: v.isDefault ? '700' : 'normal', whiteSpace: 'preserve-breaks', paddingTop: '4px' }}>{p.convert(v)}</Typography>} 
                        />
                    )}                
                </RadioGroup>
            </FormControl>
        </Paper>
    );
}

export default KRadioGroup