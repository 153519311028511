import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { styled } from '@mui/material/styles';
import { Box, IconButton, TextField, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: 'ghostWhite',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    if (!array)
        return null;
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const KTableHead = (props) => {
    const createSortHandler = (property) => (event) => {
        props.onRequestSort(event, property);
    };

    return (
        <TableHead sx={{ backgroundColor: 'lightgrey'}}>
            <TableRow>
                {props.headers.map((headCell) => (
                    <TableCell
                        id={headCell.id}
                        key={headCell.id}
                        align={headCell.align}
                        sx={{ fontWeight: 700, backgroundColor: 'primary.main' }}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={props.orderBy === headCell.id ? props.order : false} >
                        {headCell.sortable && 
                            <TableSortLabel
                                active={props.orderBy === headCell.id}
                                direction={props.orderBy === headCell.id ? props.order : 'asc'}
                                onClick={createSortHandler(headCell.id)} >
                                {headCell.label}
                                {props.orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {props.order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        }
                        {!headCell.sortable && <span>{headCell.label}</span>}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

KTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
};

const KRow = (p) => p.headers.map((h) => 
    <TableCell key={h.id} align={h.align} padding={h.disablePadding ? 'none' : 'normal'} sx={p.isDense && { paddingY: 0}}>
        <Tooltip title={h.constTooltip ? h.constTooltip : h.tooltip ? p.data[h.tooltip] : null}><div>{h.convert ? h.convert(p.data[h.idx || h.id], p.data[h.flag]) : p.data[h.idx || h.id]}</div></Tooltip>
    </TableCell>
);


const KTable = (p) => {
    const [order, setOrder] = useState(p.order || 'asc');
    const [orderBy, setOrderBy] = useState(p.orderBy || '');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(p.initialRowsPerPage || 500);
    const [filtered, setFiltered] = useState(p.data);

    const mtx = p.marginTop || 0;

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 500));
        setPage(0);
    };

    const filter = (f, data) => {
        const x = f ? data.filter( (o) => Object.values(o).some(str => typeof str === 'string' && str.toUpperCase().includes(f) )) : data;
        setFiltered(x);
        if (!x) return;
        const maxPageNo = Math.floor(x.length / rowsPerPage);
        if (maxPageNo < page)
            setPage(maxPageNo);
    };

    const visibleRows = useMemo(
        () => 
            stableSort(filtered, getComparator(order, orderBy))?.slice(
                page * rowsPerPage,
                Math.min(page * rowsPerPage + rowsPerPage, filtered.length)
            )
        ,
        [filtered, order, orderBy, page, rowsPerPage]
    );

    const rowKey = p.rowKey || 'id';

    useEffect( () => { 
        if (!p.data) return;
        const f = document.getElementById('searchWord')?.value?.toUpperCase();
        const x = f ? p.data.filter( (o) => Object.values(o).some(str => typeof str === 'string' && str.toUpperCase().includes(f) )) : p.data;
        setFiltered(x);
        const maxPageNo = Math.floor(x.length / rowsPerPage);
        if (maxPageNo < page)
            setPage(maxPageNo);
    }, [p.data, rowsPerPage, page]);


    return (
        <Box sx={{ width: '100%', mt: mtx }}>
            <Paper sx={{ width: '100%', mt:2, mb: 2, bgcolor: 'transparent' }}>
                { !p.noSearch && 
                    <Box display='flex' justifyContent='flex-end' boxShadow=''>
                        <TextField id='searchWord' variant='standard' label='Arama' onChange={(e) => filter(e.target.value?.toUpperCase(), p.data)}/>
                        {   p.onUploadClick &&
                            <Tooltip title={p.uploadLabel} >
                                <IconButton onClick={p.onUploadClick}>
                                    <CloudUploadOutlinedIcon fontSize='large' color='primary' />
                                </IconButton>
                            </Tooltip> }
                        {   p.onAddClick &&
                            <Tooltip title="Yeni Tanım" >
                                <IconButton onClick={p.onAddClick}>
                                    <AddCircleIcon fontSize='large' color='primary' />
                                </IconButton>
                            </Tooltip>  }
                    </Box>
                }
                <TableContainer>
                    <Table sx={{ minWidth: 'xs' }} aria-labelledby="tableTitle" size='small'>
                        <KTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            headers={p.headers} />
                        <TableBody>
                            {visibleRows && 
                                visibleRows.map((row, index) => 
                                <StyledTableRow hover role="tr" tabIndex={-1} key={row[rowKey]} >
                                    <KRow headers={p.headers} data={row} isDense={p.isDense}/>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {!p.noPagination &&
                    <TablePagination
                        rowsPerPageOptions={p.rowsPerPage || [500]}
                        component="div"
                        count={filtered ? filtered.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                }
            </Paper>
        </Box>
    );
}

export default KTable;