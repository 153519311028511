import { useRef, useState } from 'react';
import { Box, Button, IconButton, Grid, Tooltip, Typography } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import KDatePicker from '../Components/KDatePicker';
import KModal from '../Components/KModal';
import KSelect from '../Components/KSelect';
import StringInput from '../Components/StringInput';
import BankDisplay from './BankDisplay';
import CaseSelectionForm from './CaseSelectionForm';
import UserSelectionForm from './UserSelectionForm';
import { apiGetBank, apiGetAllActiveCases, apiGetAllActiveUsers, apiGetAllActiveCompanies } from '../Lib/Api';
import { toDate, toDateString } from '../Lib/Constants';
import processApi from '../Lib/ProcessApi';

const markTeamIdAndHead = (list) => {
    for(let teamId = 0; teamId < list.length; teamId++)
        for(let i = 0; i < list[teamId].length; i++) {
            const user = list[teamId][i];
            user.tid = teamId;
            user.is = i === 0;
        }
    return list;
}

const TrainingForm = (p) => {

    console.log('TrainingForm render');


    const [submitEnabled, setSubmitEnabled] = useState(!!p.data);
    const [openModal, setOpenModal] = useState(false);
    const [bankId, setBankId] = useState(p.data?.bankId ?? '');
    const [marketId, setMarketId] = useState(p.data?.marketId ?? '');
    const [userList, setUserList] = useState(p.data?.userList ? markTeamIdAndHead(p.data.userList) :  [[]])
    const [caseList, setCaseList] = useState(p.data?.caseList ? p.data.caseList :  [])


    let modal = useRef(null);
    let companyId = useRef('');
    let searchWord = useRef('');
    let searchWordCase = useRef('');
    let companyList = useRef([]);
    let fullUserList = useRef([]);
    let fullCaseList = useRef([]);

    const onUserListClick = async () => {
        if (companyList.current.length === 0) {
            companyList.current = await processApi('TrainingForm onUserListClick apiGetAllActiveCompanies', p.checkApiMessage, p.setMessageKey, apiGetAllActiveCompanies);
            if (!companyList.current) return;
            companyList.current.splice(0, 0, { key: '', value: 'Tüm Firmalar' })
        }
        if (fullUserList.current.length === 0) {
            fullUserList.current = await processApi('TrainingForm onUserListClick apiGetAllActiveUsers', p.checkApiMessage, p.setMessageKey, apiGetAllActiveUsers);
            if (!fullUserList.current) return;
        }
        const data = {
            companyId: companyId.current,
            searchWord: searchWord.current,
            companyList: companyList.current,
            fullUserList: fullUserList.current,
            setCompanyId: (c) => companyId.current = c,
            setSearchWord: (s) => searchWord.current = s,
        };
        modal.current = {
            title: 'Sınıf Listesi',
            form: <UserSelectionForm userList={userList} setUserList={handleUserListChange} handleClose={closeModal} data={data} />,
            top: '50%',
            width: { xs: '400px', sm: '650px' }
        };
        setOpenModal(true);
    }

    const onCaseListClick = async () => {
        if (fullCaseList.current.length === 0) {
            fullCaseList.current = await processApi('TrainingForm onCaseListClick', p.checkApiMessage, p.setMessageKey, apiGetAllActiveCases);
            if (!fullCaseList.current) return;
        }
        const data = {
            searchWord: searchWordCase.current,
            fullCaseList: fullCaseList.current,
            setSearchWord: (s) => searchWordCase.current = s,
            checkApiMessage: p.checkApiMessage,
            setMessageKey: p.setMessageKey
        };
        modal.current = {
            title: 'Olay Listesi',
            form: <CaseSelectionForm caseList={caseList} setCaseList={handleCaseListChange} handleClose={closeModal} data={data} />,
            top: '30%',
            width: { xs: '400px', sm: '650px' }
        };
        setOpenModal(true);
    }

    const displayBank = async (id) => {
        const bankData = await processApi('TrainingForm displayBank', p.checkApiMessage, p.setMessageKey, apiGetBank, id);
        if (!bankData) return;
        modal.current = {
            title: bankData.title,
            form: <BankDisplay data={bankData} handleClose={closeModal} />,
            top: '40%',
            width: { xs: '400px', sm: '600px' }
        };
        setOpenModal(true);
    }
    const closeModal = () => setOpenModal(false);


    let ref0IsValid = useRef(!!p.data);     // title
    let ref1IsValid = useRef(!!p.data);     // startDate
    let ref2IsValid = useRef(!!p.data);     // endDate
    let ref0Value = useRef(p.data && p.data?.title);
    let ref1Value = useRef(p.data && p.data?.startDate ? toDate(p.data.startDate) : null);
    let ref2Value = useRef(p.data && p.data?.endDate ? toDate(p.data.endDate) : null);

    const handleInputChange = (x, i, v) => {
        if (x === 0) { ref0IsValid.current = i; ref0Value.current = v; }
        else if (x === 1) { ref1Value.current = v; }
        else if (x === 2) { ref2Value.current = v; }
        decideSubmit(0);
    };
    const handleSelectInputChange = (value, setValue, otherSelectValue) => { setValue(value); decideSubmit(1, value, otherSelectValue) }
    const handleUserListChange = (value) => { setUserList(value); decideSubmit(2, value) }
    const handleCaseListChange = (value) => { setCaseList(value); decideSubmit(3, value) }
    const setDateValid = (i) => { ref1IsValid.current = i; decideSubmit(0); };
    const decideSubmit = (callType, value0, value1) => {
        if (callType === 1)
            checkSubmit(ref0IsValid, ref1IsValid, ref2IsValid, value0, value1, userList, caseList);
        else if (callType === 2)
            checkSubmit(ref0IsValid, ref1IsValid, ref2IsValid, bankId, marketId, value0, caseList);
        else if (callType === 3)
            checkSubmit(ref0IsValid, ref1IsValid, ref2IsValid, bankId, marketId, userList, value0);
        else
            checkSubmit(ref0IsValid, ref1IsValid, ref2IsValid, bankId, marketId, userList, caseList);
    };
    const checkSubmit = (r0, r1, r2, b, m, u, c) => setSubmitEnabled(r0.current && r1.current && r2.current && b !== '' && m !== '' && u.length > 0 && u.some(t => t.length > 0) && c.length > 0);

    const getUserIds = () => {
        const rtn = [];
        for(let i = 0; i < userList.length; i++) {
            const team = [];
            rtn.push(team);
            for(let j = 0; j < userList[i].length; j++)
                team.push(userList[i][j].userId);
        }
        return JSON.stringify(rtn);
    };

    const getCaseIds = () => {
        const rtn = [];
        for(let i = 0; i < caseList.length; i++) 
            rtn.push(caseList[i].key);
        return JSON.stringify(rtn);
    };

    const process = (e) => {
        e.preventDefault();
        const data = {
            id: p.data && p.data.id,
            title: ref0Value.current,
            startDate: toDateString(ref1Value.current.$d ?? ref1Value.current),
            endDate: toDateString(ref2Value.current.$d ?? ref2Value.current),
            bankId: bankId,
            marketId: marketId,
            userIds: getUserIds(),
            caseIds: getCaseIds()
        }
        p.process(data);
    };

    return (
        <Box sx={{ mt: 0 }}>
            <Grid container spacing='4px' marginTop='4px'>
                <Grid item xs={12}>
                    <StringInput
                        label='Eğitimin Adı' id='title' autoFocus
                        value={ref0Value.current}
                        onChange={(i, v) => handleInputChange(0, i, v)} />
                </Grid>
                <Grid item xs={6}>
                    <KDatePicker
                        label='Başlama Tarihi ve Saati *'
                        value={ref1Value.current}
                        setValue={(v) => handleInputChange(1, true, v)}
                        setValid={(f) => {ref1IsValid.current = f; decideSubmit(0); }} />
                </Grid>
                <Grid item xs={6}>
                    <KDatePicker
                        label='Bitiş Tarihi ve Saati *'
                        value={ref2Value.current}
                        setValue={(v) => handleInputChange(2, true, v)}
                        setValid={(f) => {ref2IsValid.current = f; decideSubmit(0); }} />
                </Grid>
                <Grid item xs={6}>
                    <KSelect
                        label='Piyasa Bilgileri *' id='marketId' marginTop='4px'
                        value={marketId}
                        data={p.markets}
                        displayUrl='api/market/Piyasa Bilgileri?id='
                        setValue={(v) => handleSelectInputChange(v, setMarketId, bankId)} />
                </Grid>
                <Grid item xs={6}>
                    <KSelect
                        label='Banka Bilgileri *' id='bankId' marginTop='4px'
                        value={bankId}
                        data={p.banks}
                        displayFn={displayBank}
                        setValue={(v) => handleSelectInputChange(v, setBankId, marketId)} />
                </Grid>
                <Grid item xs={6}>
                    <Box display='flex' bgcolor={'LightGrey'}>
                        <Typography component='h6' variant='h6' sx={{ color: '#53a57d', fontWeight: 700, paddingLeft: 1, textWrap: 'nowrap' }}>Sınıf Listesi</Typography>
                        <Box display='flex' justifyContent='flex-end' width='100%'>
                            <Tooltip title={userList && userList.length > 0 && userList.some(t => t.length > 0) ? 'Sınıf Güncelleme' : 'Yeni Sınıf Tanımı'} sx={{ alignContent: 'end' }}>
                                <IconButton onClick={onUserListClick}>
                                    {userList && userList.length > 0 && userList.some(t => t.length > 0)
                                        ? <UpdateOutlinedIcon fontSize='small' color='primary' />
                                        : <AddCircleIcon fontSize='small' color='primary' />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box display='flex' bgcolor={'LightGrey'}>
                        <Typography component='h6' variant='h6' sx={{ color: '#53a57d', fontWeight: 700, paddingLeft: 1, textWrap: 'nowrap' }}>Olay Listesi</Typography>
                        <Box display='flex' justifyContent='flex-end' width='100%'>
                            <Tooltip title={caseList.length > 0 ? 'Olay Listesi Güncelleme' : 'Yeni Olay Listesi Tanımı'} sx={{ alignContent: 'end' }}>
                                <IconButton onClick={onCaseListClick}>
                                    {caseList.length > 0
                                        ? <UpdateOutlinedIcon fontSize='small' color='primary' />
                                        : <AddCircleIcon fontSize='small' color='primary' />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 3 }} >
                <Button onClick={process} variant="contained" disabled={!submitEnabled} >{p.processButtonTitle}</Button>
                <Button variant="contained" onClick={p.handleClose}>İptal</Button>
            </Box>
            {openModal &&
                <KModal title={modal.current.title} open={openModal} handleClose={closeModal} top={modal.current.top} width={modal.current.width} >
                    {modal.current.form}
                </KModal>
            }
        </Box>
    );
};


export default TrainingForm;